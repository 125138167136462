import { isFeatureEnabled } from 'owa-feature-flags';
import type { OfflineOptions } from './OfflineOptions';

export const mailIndexToDaysMap = [7, 30, 90, 180, 365, 730, 0];

export const calIndexToDaysMap = [0, 30, 90, 180];

export const getDefaultOfflineSettings = (): OfflineOptions => ({
    offlineEnabled: true,
    mailNumSyncDaysIndex: isFeatureEnabled('tri-month-sync-window') ? 1 : 0,
    folderToSync: 'INBOX',
    calendarNumSyncDaysIndex: 1, //default value is 30 days
});
