export type PstFileLoggingEventName =
    | 'Win32GqlMutateError'
    | 'Win32GqlQueryError'
    | 'Win32GqlSubscriptionEvent'
    | 'PstFilePickerError'
    | 'MountUnmountPstFileError'
    | 'CreateApolloClientError';

export const PstFileLoggingEventType: {
    [P in PstFileLoggingEventName]: P;
} = {
    Win32GqlMutateError: 'Win32GqlMutateError',
    Win32GqlQueryError: 'Win32GqlQueryError',
    Win32GqlSubscriptionEvent: 'Win32GqlSubscriptionEvent',
    PstFilePickerError: 'PstFilePickerError',
    MountUnmountPstFileError: 'MountUnmountPstFileError',
    CreateApolloClientError: 'CreateApolloClientError',
};
