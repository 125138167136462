import type { GetUpdatedRequestSettingsCb } from 'owa-data-worker-utils';
import { getWindowData } from 'owa-window-data';
import { type WorkerWindowData } from 'owa-worker-shared';

let updateCb: GetUpdatedRequestSettingsCb;

export function setUpdateRequestSettingCallback(cb: GetUpdatedRequestSettingsCb) {
    updateCb = cb;
}

export async function updateRequestSettings() {
    if (updateCb) {
        const settings = await updateCb();
        const windowData: WorkerWindowData = getWindowData() as WorkerWindowData;
        windowData.updateRequestSettings(settings);
    }
}
