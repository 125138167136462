import { getAllCoprincipalMailboxInfos } from 'owa-account-source-list-store';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';

import type { ConnectionCreator } from '../connection/ConnectionCreator';
import type { SignalRConnection } from '../schema/SignalRConnection';
import { ConnectionType } from '../schema/ConnectionType';
import { MailboxBasedSubscriptionTracker } from '../subscription/subscriptionTracker';
import { getNotificationConnectionLogger } from '../utils/notificationConnectionLogger';
import { type SubscriptionStatus } from '../schema/SubscriptionTrackerState';
import { ConnectionState } from '../schema/ConnectionState';
import type { RetrySource } from '../schema/RetrySource';
import { getConnectionStateFromHubConnection } from '../utils/getConnectionStateFromHubConnection';

export abstract class NotificationManager {
    public static connectionInstances = new Map<string, SignalRConnection>();

    // Return value indicates whether a new connection was created (vs. already existing)
    public static async initializeSignalR(
        creator: ConnectionCreator,
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ): Promise<boolean> {
        let connection = this.getConnectionInstance(mailboxInfo, connectionType);
        if (connection) {
            if (connection.isDisconnected()) {
                connection.retryDisconnection('Initialization');
            }
            return false;
        } else {
            connection = creator.createConnection();
            this.connectionInstances.set(
                this.getConnectionInstanceKey(mailboxInfo, connectionType),
                connection
            );
            await connection.init();
            return true;
        }
    }

    public static getChannelId(mailboxInfo: MailboxInfo, connectionType: ConnectionType): string {
        return this.getConnectionInstance(mailboxInfo, connectionType)?.getChannelId() ?? '';
    }

    public static getConnectionId(
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ): string {
        return this.getConnectionInstance(mailboxInfo, connectionType)?.getConnectionId() ?? '';
    }

    public static stopAllConnections(connectionType?: ConnectionType) {
        if (connectionType) {
            for (const connection of NotificationManager.getConnectionsByType(connectionType)) {
                connection.stopConnection();
            }
        } else {
            for (const connection of NotificationManager.connectionInstances.values()) {
                connection.stopConnection();
            }
        }
    }

    public static retryAllDisconnections(
        connectionType: ConnectionType,
        retrySource?: RetrySource
    ) {
        for (const connection of this.getConnectionsByType(connectionType)) {
            connection.retryDisconnection(retrySource);
        }
    }

    public static retryAllDisconnectionsWithMinimumReconnectInterval() {
        NotificationManager.retryAllDisconnections(ConnectionType.OwaClassicSignalR);
    }

    public static *getConnectionsByType(connectionType: ConnectionType) {
        for (const connection of NotificationManager.connectionInstances.values()) {
            if (connection.connectionType === connectionType) {
                yield connection;
            }
        }
    }

    public static *getConnectionsByMailbox(mailboxInfo: MailboxInfo) {
        for (const connectionType of Object.values(ConnectionType)) {
            const connection = NotificationManager.getConnectionInstance(
                mailboxInfo,
                connectionType
            );
            if (connection) {
                yield connection;
            }
        }
    }

    public static getConnectionInstance(
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ): SignalRConnection | undefined {
        return NotificationManager.connectionInstances.get(
            NotificationManager.getConnectionInstanceKey(mailboxInfo, connectionType)
        );
    }

    public static getConnectionInstanceKey(
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ) {
        return getMailboxInfoKey(mailboxInfo) + '_' + connectionType;
    }

    public static clearConnectionsForTest() {
        this.connectionInstances.clear();
    }

    public static stopConnection(mailboxInfo: MailboxInfo) {
        for (const connection of this.getConnectionsByMailbox(mailboxInfo)) {
            connection.stopConnection();
        }
    }

    public static disableConnection(mailboxInfo: MailboxInfo) {
        for (const connection of this.getConnectionsByMailbox(mailboxInfo)) {
            connection.disableConnection();
        }
    }

    public static getConnectionState(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
        return this.getConnectionInstance(mailboxInfo, connectionType)?.getConnectionState();
    }

    public static retryDisconnection(mailboxInfo: MailboxInfo, connectionType?: ConnectionType) {
        if (connectionType) {
            NotificationManager.getConnectionInstance(
                mailboxInfo,
                connectionType
            )?.retryDisconnection('Manual');
        } else {
            for (const connection of NotificationManager.getConnectionsByMailbox(mailboxInfo)) {
                connection.retryDisconnection('Manual');
            }
        }
    }

    public static enableConnection(mailboxInfo: MailboxInfo) {
        for (const connection of this.getConnectionsByMailbox(mailboxInfo)) {
            connection.enableConnection();
        }
    }

    public static getConnectionStatusesObjectForAllAccounts() {
        type ConnectionStatusLog = {
            ChannelStatus: ConnectionState;
            ChannelSubscriptions: {
                subscriptionId: string;
                status?: SubscriptionStatus;
                isInbox?: boolean;
            }[];
        };
        const connectionStatuses: {
            Account: string;
            connectionStatus: ConnectionStatusLog;
        }[] = [];

        getAllCoprincipalMailboxInfos().forEach((mailboxInfo: MailboxInfo) => {
            const connection = NotificationManager.getConnectionInstance(
                mailboxInfo,
                ConnectionType.OwaClassicSignalR
            );

            if (connection) {
                const inboxId = connection?.getDistinguishedFolderId('inbox');
                const connectionStatus: ConnectionStatusLog = {
                    ChannelStatus:
                        getConnectionStateFromHubConnection(connection?.getConnectionState()) ??
                        ConnectionState.Disconnected,
                    ChannelSubscriptions: [],
                };
                const subscriptions = MailboxBasedSubscriptionTracker.getSubscriptions(
                    mailboxInfo,
                    ConnectionType.OwaClassicSignalR
                );
                for (const sub of subscriptions) {
                    const subscriptionState = MailboxBasedSubscriptionTracker.getSubscriptionState(
                        mailboxInfo,
                        sub.subscriptionId,
                        ConnectionType.OwaClassicSignalR
                    );
                    connectionStatus.ChannelSubscriptions.push({
                        subscriptionId: sub.subscriptionId,
                        status: subscriptionState?.status,
                        isInbox: inboxId ? sub.subscriptionId.includes(inboxId) : false,
                    });
                }

                connectionStatuses.push({
                    Account: mailboxInfo.sourceId ?? 'OWADefault',
                    connectionStatus,
                });
            }
        });

        const notificationSummary = getNotificationConnectionLogger().getDiagnosticDataSummary();

        return {
            connectionStatus: connectionStatuses,
            notificationChannelsSummary: notificationSummary,
        };
    }

    public static getConnectionStatusesForAllAccounts() {
        return JSON.stringify(NotificationManager.getConnectionStatusesObjectForAllAccounts());
    }
}

function getMailboxInfoKey(mailboxInfo: MailboxInfo) {
    return mailboxInfo ? getIndexerValueForMailboxInfo(mailboxInfo) : 'OWADefault';
}
