import { logUsage } from 'owa-analytics';
import type { CustomData } from 'owa-analytics-types';
import type { MailboxInfo } from 'owa-client-types';
import type NotificationPayloadBase from 'owa-service/lib/contract/NotificationPayloadBase';
import getNotificationEmitter from './notificationEmitter';
import NotificationEventType from '../schema/NotificationEventType';
import { MailboxBasedSubscriptionTracker } from '../subscription/subscriptionTracker';
import callAndCatch from '../utils/callAndCatch';
import { getNotificationConnectionLogger } from '../utils/notificationConnectionLogger';
import { ConnectionType } from '../schema/ConnectionType';
const RELOAD_NOTIFICATION_TYPE = 'Reload';

export function handleNotification(
    notification: NotificationPayloadBase,
    mailboxInfo: MailboxInfo,
    commonFolderIds?: Map<string, string>
): void {
    getNotificationConnectionLogger().logDeliveredNotification(notification, commonFolderIds);
    getNotificationEmitter().emit(NotificationEventType.NotificationData, notification);
    if (notification.id) {
        // ToDo: InNextIteration make this a generic handler for all connection types.
        const refs = MailboxBasedSubscriptionTracker.getRefs(
            mailboxInfo,
            notification.id,
            ConnectionType.OwaClassicSignalR
        );
        for (const ref of refs) {
            callAndCatch(() => {
                const subscriptionParameters = ref.subscription.subscriptionParameters;
                const handler = ref.callback;
                handler(notification, mailboxInfo, subscriptionParameters);
            });
        }
    }
}

function generateReloadNotification(subscriptionId: string): NotificationPayloadBase {
    return {
        id: subscriptionId,
        EventType: RELOAD_NOTIFICATION_TYPE,
    };
}

export function reloadAll(source: string, mailboxInfo: MailboxInfo, customData?: CustomData): void {
    getNotificationEmitter().emit(NotificationEventType.ChannelData, `reloadAll from ${source}`, {
        mailboxInfo,
    });
    logUsage('SignalRNotification', {
        event: 'reloadAll',
        source,
        ...customData,
    });
    // ToDo: InNextIteration make this a generic handler for all connection types.
    const subscriptions = MailboxBasedSubscriptionTracker.getSubscriptions(
        mailboxInfo,
        ConnectionType.OwaClassicSignalR
    );
    for (const subscription of subscriptions) {
        const reloadNotification = generateReloadNotification(subscription.subscriptionId);
        handleNotification(reloadNotification, mailboxInfo);
    }
}

export function subscriptionFailureReload(subscriptionId: string, mailboxInfo: MailboxInfo): void {
    const reloadNotification = generateReloadNotification(subscriptionId);
    // ToDo: InNextIteration make this a generic handler for all connection types.
    const refs = MailboxBasedSubscriptionTracker.getRefs(
        mailboxInfo,
        subscriptionId,
        ConnectionType.OwaClassicSignalR
    );
    for (const ref of refs) {
        // Don't deliver reloads to subscribers that explicitly request not to
        if (ref.subscription.noSubscriptionFailureReload !== true) {
            callAndCatch(() => {
                ref.callback(reloadNotification, mailboxInfo);
            });
        }
    }
}
