import { lazyWebResolvers } from 'outlook-resolvers-web';
import { lazyOfflineResolvers } from 'outlook-resolvers-offline';
import { lazyPstResolvers } from 'outlook-resolvers-pst';
import type { ResolverImports } from 'owa-apollo-links';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import type { Resolvers } from 'owa-graph-schema';

let offlineEnabledResolvers: ResolverImports;
let nonOfflineEnabledResolvers: ResolverImports;
let pstResolvers: Resolvers;

export async function getResolvers(
    offlineEnabled?: boolean,
    pstEnabled?: boolean
): Promise<ResolverImports> {
    let rv: ResolverImports;

    offlineEnabled = offlineEnabled == undefined ? isOfflineSyncEnabled() : offlineEnabled;

    if (offlineEnabled) {
        if (!offlineEnabledResolvers) {
            const [web, offline] = await Promise.all([
                lazyWebResolvers.import(),
                lazyOfflineResolvers.import(),
            ]);

            offlineEnabledResolvers = {
                web,
                offline,
            };
        }

        rv = offlineEnabledResolvers;
    } else {
        if (!nonOfflineEnabledResolvers) {
            nonOfflineEnabledResolvers = {
                web: await lazyWebResolvers.import(),
            };
        }

        rv = nonOfflineEnabledResolvers;
    }

    if (pstEnabled) {
        if (!pstResolvers) {
            pstResolvers = await lazyPstResolvers.import();
        }

        rv = {
            ...rv,
            pst: pstResolvers,
        };
    }

    return rv;
}
