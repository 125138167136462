import type { Table } from 'dexie';
import type { ConversationNodeItem } from 'owa-graph-schema';
import { type DexieKey, compositeDexieKey } from 'owa-database-schema';

export const MESSAGE_BODIES_TABLE_NAME = 'messageBodies';
export type FlagStatus = any;
export type Importance = any;

export type MessageBodyMetaData = {
    needsSyncImage?: number;
    needsSyncAttachment?: number;
    clientId?: string;
    // Search properties
    needsBackfill?: number;
    subjectKeywords?: string[];
    keywords?: string[];
    sortTime?: Date;
    dateTimeSent?: Date;
    from?: string[];
    to?: string[];
    hasAttachments?: number;
    isRead?: number;
    flagStatus?: FlagStatus;
    mentionedMe?: number;
    importance?: Importance;
    cc?: string[];
    parentFolderId?: string;
};

export type MessageBodiesTableType = ConversationNodeItem & {
    MetaData?: MessageBodyMetaData;
};
export type MessageBodiesTable = Table<MessageBodiesTableType, string>;

export const MESSAGE_BODIES_ID_KEY: DexieKey<ConversationNodeItem> = 'id';

export const FOLDERS_WITH_IMAGES_TO_SYNC_KEY = compositeDexieKey<MessageBodiesTableType>([
    'MetaData.needsSyncImage',
    'ParentFolderId.Id',
]);
export const FOLDERS_WITH_ATTACHMENTS_TO_SYNC_KEY = compositeDexieKey<MessageBodiesTableType>([
    'MetaData.needsSyncAttachment',
    'ParentFolderId.Id',
]);

export const MESSAGE_BODIES_CLIENTID_INDEX_KEY: DexieKey<MessageBodiesTableType> =
    'MetaData.clientId';

// Search indices
export const NEEDS_BACKFILL_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.needsBackfill';
export const SUBJECT_KEYWORDS_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.subjectKeywords';
export const KEYWORDS_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.keywords';
export const SORT_TIME_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.sortTime';
export const DATE_TIME_SENT_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.dateTimeSent';
export const FROM_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.from';
export const TO_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.to';
export const HAS_ATTACHMENTS_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.hasAttachments';
export const IS_READ_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.isRead';
export const FLAG_STATUS_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.flagStatus';
export const MENTIONED_ME_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.mentionedMe';
export const IMPORTANCE_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.importance';
export const CC_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.cc';
export const PARENT_FOLDER_ID_KEY: DexieKey<MessageBodiesTableType> = 'MetaData.parentFolderId';

export const messageBodiesSchema = {
    messageBodies: `${MESSAGE_BODIES_ID_KEY},${FOLDERS_WITH_IMAGES_TO_SYNC_KEY},${MESSAGE_BODIES_CLIENTID_INDEX_KEY},${NEEDS_BACKFILL_KEY},*${SUBJECT_KEYWORDS_KEY},*${KEYWORDS_KEY},${SORT_TIME_KEY},${DATE_TIME_SENT_KEY},*${FROM_KEY},*${TO_KEY},${HAS_ATTACHMENTS_KEY},${IS_READ_KEY},${FLAG_STATUS_KEY},${MENTIONED_ME_KEY},${IMPORTANCE_KEY},*${CC_KEY},${PARENT_FOLDER_ID_KEY},${FOLDERS_WITH_ATTACHMENTS_TO_SYNC_KEY}`,
};
