const serverTransientExceptionNames = [
    'TooManyObjectsOpenedException',
    'MapiExceptionSessionLimit',
    'OwaHipRequiredException',
    'ConnectionFailedTransientException',
    'MailboxCrossSiteFailoverException',
    'OwaLockTimeoutException',
    'OwaLockTrackableTimeoutException',
    'StorageTransientException',
    'TransientException',
    'ErrorApiQuarantinedException',
    'MailboxOfflineException',
    'MapiExceptionMdbOffline',
];

export function isTransientExceptionName(fullExceptionName: string | undefined): boolean {
    let rv = false;

    if (fullExceptionName) {
        rv = serverTransientExceptionNames.some(partialName =>
            fullExceptionName.endsWith(partialName)
        );
    }

    return rv;
}
