import { hasQueryStringParameter, getQueryStringParameter } from 'owa-querystring';
import { TraceLevel } from 'owa-trace';
import { getExtraSettings } from 'owa-config/lib/extraSettings';

let isAnyTracingEnabledValue: boolean | undefined;
export function isAnyTracingEnabled(): boolean {
    if (isAnyTracingEnabledValue == undefined) {
        isAnyTracingEnabledValue = isTracingEnabled(TraceLevel.Error);
    }

    return isAnyTracingEnabledValue;
}

export function isTracingEnabled(level: TraceLevel): boolean {
    try {
        if (hasQueryStringParameter('enableTracing')) {
            return isLevelEnabled(level, getQueryStringParameter('traceLevel'));
        }
        const extraSettingsTracing = getExtraSettings()?.clientTracing;
        if (extraSettingsTracing) {
            return isLevelEnabled(level, extraSettingsTracing);
        }
    } catch (e) {
        // no-op. If there is an error, don't fail analytics because of it.
        // This seems to mostly happen in Safari.
    }

    return false;
}

function isLevelEnabled(level: TraceLevel, testLevel: string | undefined): boolean {
    if (testLevel) {
        try {
            return level <= parseInt(testLevel);
        } catch {
            return false;
        }
    }
    return true;
}
