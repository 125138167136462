import type { Table } from 'dexie';
import type { DexieKey } from 'owa-database-schema';
import { compositeDexieKey } from 'owa-database-schema';
import type { MessageTableType } from './MessageTableType';

export const MESSAGES_TABLE_NAME = 'messages';

export type MessagesTable = Table<MessageTableType, string>;

export const MESSAGES_ID_KEY: DexieKey<MessageTableType> = 'id';
export const MESSAGES_DATE_SORT_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_UNREAD_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.isUnread',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_FLAGGED_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.isFlagged',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_TO_ME_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.toOrCCMe',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_HAS_FILES_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.hasFiles',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_MENTIONED_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.mentioned',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY =
    compositeDexieKey<MessageTableType>([
        'ParentFolderId.Id',
        'MetaData.hasCalendarInvite',
        'MetaData.sortTime',
        'DateTimeReceived',
    ]);

export const MESSAGES_INBOX_DATE_SORT_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_UNREAD_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.isUnread',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_FLAGGED_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.isFlagged',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_TO_ME_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.toOrCCMe',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_HAS_FILES_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.hasFiles',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_MENTIONED_FILTER_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.mentioned',
    'MetaData.sortTime',
    'DateTimeReceived',
]);

export const MESSAGES_INBOX_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY =
    compositeDexieKey<MessageTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.hasCalendarInvite',
        'MetaData.sortTime',
        'DateTimeReceived',
    ]);

export const MESSAGES_INSTANCE_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'InstanceKey',
]);

export const MESSAGES_TO_SYNC_KEY = compositeDexieKey<MessageTableType>([
    'MetaData.isDirty',
    'ParentFolderId.Id',
]);

export const MESSAGES_CREATION_LIE_KEY = compositeDexieKey<MessageTableType>([
    'ParentFolderId.Id',
    'MetaData.creationSource',
]);

export const messagesSchema = {
    messages: `${MESSAGES_ID_KEY},${MESSAGES_DATE_SORT_KEY},${MESSAGES_DATE_SORT_UNREAD_FILTER_KEY},${MESSAGES_DATE_SORT_FLAGGED_FILTER_KEY},${MESSAGES_DATE_SORT_TO_ME_FILTER_KEY},${MESSAGES_DATE_SORT_HAS_FILES_FILTER_KEY},${MESSAGES_DATE_SORT_MENTIONED_FILTER_KEY},${MESSAGES_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_KEY},${MESSAGES_INBOX_DATE_SORT_UNREAD_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_FLAGGED_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_TO_ME_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_HAS_FILES_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_MENTIONED_FILTER_KEY},${MESSAGES_INBOX_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY},${MESSAGES_INSTANCE_KEY},${MESSAGES_TO_SYNC_KEY},${MESSAGES_CREATION_LIE_KEY}`,
};
