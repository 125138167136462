import { type RejectedQueuedResult, type RejectCode } from 'owa-data-worker-utils';
import { getTime } from '../util/getTime';
import { assertNever } from 'owa-assert';

export type RejectionRecord = {
    code: RejectCode;
    totalCount: number;
    initialTime: number;
    latestTime: number;
};

export type RejectionLog = {
    records: {
        [key in RejectCode]?: RejectionRecord;
    };

    latest?: RejectionRecord | undefined;
};

type ThrottleStep = {
    count: number;
    delay: number;
};

type ThrottleState = 'Runnable' | 'Throttled' | 'Dead';

const TWENTY_SECONDS = 20 * 1000;
const ONE_MINUTE = 60 * 1000;
const TEN_MINUTES = 10 * ONE_MINUTE;
const THIRTY_MINUTES = 30 * ONE_MINUTE;

// Throttle things that seem related to the server (server down, too busy, etc)
const ServerTransientThrottleSteps: ThrottleStep[] = [
    { count: 10, delay: ONE_MINUTE },
    { count: 10, delay: TEN_MINUTES },
    { count: 10, delay: THIRTY_MINUTES },
];

// Throttle things that seem related to the client not meeting preconditions (auth, canary, etc)
// This delay is less than the action queue backup timer interval, so these actions will be retried
// the next time the action queue is processed on that loop.
const ClientTransientThrottleSteps: ThrottleStep[] = [
    { count: 10, delay: TWENTY_SECONDS },
    { count: 20, delay: ONE_MINUTE },
];

export function updateRejectionLog(
    log: RejectionLog | undefined,
    result: RejectedQueuedResult
): RejectionLog {
    const now = getTime('rejection_log');
    log = log || { records: {} };
    let record = log.records[result.rejectCode];

    if (record) {
        record.totalCount++;
        record.latestTime = now;
    } else {
        record = {
            code: result.rejectCode,
            totalCount: 1,
            initialTime: now,
            latestTime: now,
        };
        log.records[result.rejectCode] = record;
    }

    log.latest = record;
    return log;
}

export function getThrottleState(log: RejectionLog | undefined): ThrottleState {
    let rv: ThrottleState = 'Runnable';
    log = log || { records: {} };

    if (log.latest) {
        switch (log.latest.code) {
            case 'Offline': {
                // never give up on actions the fail because the app is offline
                rv = 'Runnable';
                break;
            }
            case 'OverBudget':
            case 'ServiceDown':
            case 'ServerTransient':
            case 'Abort': {
                rv = computeThrottleFromProfile(log.latest, ServerTransientThrottleSteps);
                break;
            }
            case 'Auth':
            case 'Canary': {
                rv = computeThrottleFromProfile(log.latest, ClientTransientThrottleSteps);
                break;
            }
            default: {
                assertNever(log.latest.code);
            }
        }
    }

    return rv;
}

// exported for unit testing
export function computeThrottleFromProfile(
    record: RejectionRecord,
    profile: ThrottleStep[]
): ThrottleState {
    const now = getTime('rejection_log');
    let stepStart = 0;
    for (const step of profile) {
        const stepEnd = stepStart + step.count;
        if (record.totalCount < stepEnd) {
            return now - record.latestTime < step.delay ? 'Throttled' : 'Runnable';
        }

        stepStart = step.count;
    }

    return 'Dead';
}
