import { type TraceErrorObject } from 'owa-trace';

const transientResponseCodes: Set<string> = new Set([
    'ErrorServiceUnavailable',
    'ErrorConnectionFailedTransientError',
    'ErrorInternalServerTransientError',
    'ErrorMailboxMoveInProgress',
    'ErrorMailboxStoreUnavailable',
    'ErrorServerBusy',
    'ErrorADTopologyEndpointNotFound',
    'ErrorDataSourceTransient',
    'ErrorDatabaseLocationUnavailable',
    'ErrorADUnavailable',
]);

export function isTransientResponseCode(error: TraceErrorObject | undefined): boolean {
    let rv = false;
    if (error?.responseCode) {
        rv = transientResponseCodes.has(error.responseCode);
    }

    return rv;
}
