import type { Table } from 'dexie';

export interface SyncSetting<TSetting> {
    name: string;
    data: TSetting;
}

export type SettingsTable = Table<SyncSetting<any>, string>;

const SETTINGS_NAME_KEY = 'name';

export const settingsSchema = {
    settings: `${SETTINGS_NAME_KEY}`,
};
