import type { LocationData } from 'owa-window-data';
import type { LocationBootstrapSettings } from 'owa-worker-types';

export class WorkerLocationData implements LocationData {
    _location: LocationBootstrapSettings;

    constructor(l: LocationBootstrapSettings) {
        this._location = l;
    }

    get search() {
        return this._location.search;
    }

    set search(_) {}

    get protocol() {
        return this._location.protocol;
    }

    get host() {
        return this._location.host;
    }

    get hostname() {
        return this._location.hostname;
    }

    get href() {
        return this._location.href;
    }

    get pathname() {
        return this._location.pathname;
    }

    get hash() {
        return this._location.hash;
    }

    get origin() {
        return this._location.origin;
    }

    get port() {
        return this._location.port;
    }
}
