import { AriaDatapoint } from './AriaDatapoint';
import type { CalculatedResourceTimings, AnalyticsSchema } from 'owa-analytics-types';
import type RequestOptions from 'owa-service/lib/RequestOptions';
import type { TraceErrorObject } from 'owa-trace';
import { addErrorToDatapoint } from '../utils/addErrorToDatapoint';
import getAbsoluteResourceTiming from '../utils/getAbsoluteResourceTiming';

type ResourceType = 'Asset' | 'ServiceAction';

export class ServiceActionDatapoint extends AriaDatapoint {
    private parsingTime: number | undefined;
    constructor(actionName: string, attemptCount?: number) {
        super(actionName);

        if (typeof attemptCount == 'number') {
            this.addData('AttemptCount', attemptCount);
        }
    }

    public addResponseDiagnostics(response: Response, options?: RequestOptions) {
        if (response) {
            if (response.headers) {
                this.addData('RequestIds', response.headers.get('request-id'));
                this.addData('AfdId', response.headers.get('x-msedge-ref'));
                this.addData('ContentEncoding', response.headers.get('content-encoding'));
                this.addData('ResponseContentLength', response.headers.get('content-length'));
                this.addData('FEServer', response.headers.get('x-feserver'));
                this.addData(
                    'CalcBEServer',
                    response.headers.get('x-calculatedbetarget')?.split('.')[0]
                );

                // Log the Message service related response headers
                this.addData(
                    'ServiceGatewayOrigin',
                    response.headers.get('x-messageservicegatewayresponseorigin')
                );
                this.addData(
                    'TrafficSplitterResponseOrigin',
                    response.headers.get('x-responseorigin')
                );

                this.addData(
                    'MessageServiceAvailability',
                    response.headers.get('x-messageserviceavailability')
                );

                this.addMessageServiceLatencyBreakdown(response.headers);

                this.logTimeDifferenceForHeaders(
                    response.headers,
                    'x-frontend-begin',
                    'x-frontend-end',
                    'FrontEndTimeElapsed'
                );
                this.logTimeDifferenceForHeaders(
                    response.headers,
                    'x-backend-begin',
                    'x-backend-end',
                    'BackEndTimeElapsed'
                );

                if (!response.ok) {
                    this.addDataWithPiiScrubbing(
                        'ErrorMessage',
                        response.headers.get('x-owaerrormessageid')
                    );
                    this.addDataWithPiiScrubbing(
                        'ErrorDetails',
                        response.headers.get('x-owa-error')
                    );
                }
            }

            this.addData('Status', response.status);
        }
        if (options) {
            if (options.headers) {
                this.addData('cV', options.headers.get('ms-cv'));
            }

            if ((options as any)?.credentials) {
                this.addData('RequestCredentialsOption', (options as any).credentials);
            }

            if (options.datapoint) {
                if (options.datapoint.mailbox) {
                    this.addData('MailboxType', options.datapoint.mailbox);
                }
                if (options.datapoint.customData) {
                    this.addCustomData(options.datapoint.customData);
                }
                if (options.datapoint.datapointOptions) {
                    this.options = options.datapoint.datapointOptions;
                }
            }
        }
        if (self.performance?.now) {
            this.parsingTime = self.performance.now();
        }
    }
    public addErrorDiagnostics(error: TraceErrorObject) {
        // if it is an error service action, let's not sample it
        this.options = this.options || {};
        addErrorToDatapoint(this, error);
    }

    public addResourceTimings(type: ResourceType, timing: CalculatedResourceTimings) {
        this.addData('Type', type);

        if (this.parsingTime) {
            this.addTiming(
                'responseRecieved',
                getAbsoluteResourceTiming(this.parsingTime, timing.ST)
            );
        }

        this.addTiming('WorkerStart', timing.WS);
        this.addTiming('RedirectStart', timing.RdS);
        this.addTiming('RedirectEnd', timing.RdE);
        this.addTiming('FetchStart', timing.FS);
        this.addTiming('DomainLookupStart', timing.DS);
        this.addTiming('DomainLookupEnd', timing.DE);
        this.addTiming('ConnectStart', timing.CS);
        this.addTiming('SecureConnectionStart', timing.SCS);
        this.addTiming('ConnectEnd', timing.CE);
        this.addTiming('RequestStart', timing.RqS);
        this.addTiming('ResponseStart', timing.RpS);
        this.addTiming('ResponseEnd', timing.RpE);
        this.addData('NextHopProtocol', timing.P);
        this.addData('Start', timing.ST);
        this.addData('DomainName', timing.domain);
    }
    private addTiming(key: AnalyticsSchema, timing: number) {
        this.addData(key, timing);
    }
    private logTimeDifferenceForHeaders(
        headers: Headers,
        startHeader: string,
        endHeader: string,
        propertyName: string
    ) {
        const startTime = headers.get(startHeader);
        const endTime = headers.get(endHeader);

        const startDate = startTime ? new Date(startTime) : undefined;
        const endDate = endTime ? new Date(endTime) : undefined;

        if (startDate && endDate) {
            const delta = Math.abs(endDate.getTime() - startDate.getTime());
            this.addCustomProperty(propertyName, delta);
        }
    }
    private addMessageServiceLatencyBreakdown(headers: Headers) {
        // Splitter to the end time
        const splitterLatency = headers.get('X-SplitterLatency');

        // Service-only latency end time
        const serviceOnlyLatency = headers.get('X-ServiceOnlyLatency');

        this.addCustomData({
            tsServiceLatencyBreak: JSON.stringify({
                SL: splitterLatency,
                SOL: serviceOnlyLatency,
            }),
        });
    }
}
