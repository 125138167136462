import sha256 from 'hash.js/lib/hash/sha/256';

// Return a hashed version of a string to log.
// This lets us avoid logging a long string while still allow same/different comparisons in the logs
export function getHashedLogString(value?: string | null) {
    if (value === undefined || value === null) {
        return 'undefined';
    } else if (value === '') {
        return 'empty';
    }
    return `hash_${sha256().update(value).digest('hex')}`;
}
