type PstOperationType =
    | 'GqlMutate'
    | 'GqlQuery'
    | 'GqlSubscribe'
    | 'FilePcker'
    | 'MountUnmountPstFile'
    | 'CreateApolloClient';

export interface PstFileError {
    operationType: PstOperationType;
    operationName: string;
    isExpected?: boolean;
    error: string;
}

export interface PstFileLoggingData {
    errors: PstFileError[];
}

export const pstFileLoggingData: PstFileLoggingData = {
    errors: [],
};

export function getPstFileLog() {
    return pstFileLoggingData;
}
