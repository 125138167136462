import type { Transaction } from 'dexie';
import type { Modifications, TableHooks } from 'owa-database-hooks';
import { getNewObjFromMods } from 'owa-database-hooks';
import type { ConversationTableType } from './ConversationTableType';
import isItemClassMeetingMessage from 'owa-meeting-message-utils/lib/utils/isItemClassMeetingMessage';

export function onConversationCreating(
    _id: [string, string],
    conv: ConversationTableType,
    _trans: Transaction
) {
    conv.MetaData = {
        ...conv.MetaData,
        isUnread: conv.UnreadCount > 0 ? 1 : undefined,
        isFlagged: conv.FlagStatus === 'Flagged' ? 1 : undefined,
        toOrCCMe: conv.ConversationCcMe || conv.ConversationToMe ? 1 : undefined,
        hasFiles: conv.HasAttachments || conv.HasProcessedSharepointLink ? 1 : undefined,
        mentioned: conv.mentionedMe ? 1 : undefined,
        hasCalendarInvite: conv.ItemClasses?.some(itemClass => isItemClassMeetingMessage(itemClass))
            ? 1
            : undefined,
    };
}

export function onConversationUpdating(
    mods: Modifications,
    _id: [string, string],
    oldConv: ConversationTableType,
    _trans: Transaction
) {
    const conversation = getNewObjFromMods(oldConv, mods);

    const updates: Modifications = {};

    const isUnread = conversation.UnreadCount > 0 ? 1 : undefined;
    if (conversation.MetaData?.isUnread !== isUnread) {
        updates['MetaData.isUnread'] = isUnread;
    }
    const isFlagged = conversation.FlagStatus === 'Flagged' ? 1 : undefined;
    if (conversation.MetaData?.isFlagged !== isFlagged) {
        updates['MetaData.isFlagged'] = isFlagged;
    }

    const toOrCCMe = conversation.ConversationToMe || conversation.ConversationCcMe ? 1 : undefined;
    if (conversation.MetaData?.toOrCCMe !== toOrCCMe) {
        updates['MetaData.toOrCCMe'] = toOrCCMe;
    }

    const hasFiles =
        conversation.HasAttachments || conversation.HasProcessedSharepointLink ? 1 : undefined;
    if (conversation.MetaData?.hasFiles !== hasFiles) {
        updates['MetaData.hasFiles'] = hasFiles;
    }

    const mentioned = conversation.mentionedMe ? 1 : undefined;
    if (conversation.MetaData?.mentioned !== mentioned) {
        updates['MetaData.mentioned'] = mentioned;
    }

    const hasCalendarInvite = conversation.ItemClasses?.some(itemClass =>
        isItemClassMeetingMessage(itemClass)
    )
        ? 1
        : undefined;
    if (conversation.MetaData?.hasCalendarInvite !== hasCalendarInvite) {
        updates['MetaData.hasCalendarInvite'] = hasCalendarInvite;
    }

    return updates;
}

export const conversationsHooks: TableHooks<ConversationTableType, [string, string]> = {
    creatingHook: onConversationCreating,
    updatingHook: onConversationUpdating,
};
