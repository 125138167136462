import type { FolderHierarchyResult } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { getDatabase } from 'owa-offline-database';
import { saveOfflineFolderHierarchy } from './saveOfflineFolderHierarchy';
import { saveOfflineFolderHierarchySyncState } from './saveOfflineFolderHierarchySyncState';

export async function saveOfflineFolderHierarchySyncLie(
    mailboxInfo: MailboxInfo,
    folderHierarchy: FolderHierarchyResult
) {
    // Write the updated sync state
    const database = await getDatabase(mailboxInfo);
    await database.transaction('rw', database.folders, database.syncState, async () => {
        // Write folders to the offline database
        await saveOfflineFolderHierarchy(mailboxInfo, folderHierarchy);

        await saveOfflineFolderHierarchySyncState(
            mailboxInfo,
            !!folderHierarchy.IncludesLastItemInRange,
            !!folderHierarchy.CustomSorted
        );
    });
}
