import { possibleTypes } from 'owa-graph-schema-possible-types';
import { createResolverClientLinks } from 'owa-apollo-links';
import {
    ApolloLink,
    ApolloClient,
    InMemoryCache,
    type NormalizedCacheObject,
} from '@apollo/client';
import { getResolvers } from './getResolvers';
import { isFeatureEnabled } from 'owa-feature-flags';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export async function getWorkerContextClient() {
    if (!apolloClient) {
        const resolvers = await getResolvers(
            undefined /*offlineEnabled*/,
            isFeatureEnabled('acct-pstFileSupport') /*pstEnabled*/
        );
        const context = {};
        const resolverContextLinks = createResolverClientLinks(context, () =>
            Promise.resolve(resolvers)
        );
        apolloClient = new ApolloClient({
            cache: new InMemoryCache({
                possibleTypes,
            }),
            link: ApolloLink.from(resolverContextLinks),
        });
    }

    return apolloClient;
}
