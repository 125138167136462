import Dexie, { type Table } from 'dexie';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logGreyError, logUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { isRunningOnWorker } from 'owa-config';
import { appAppReboot } from 'owa-data-worker-app-reboot';
import { registerTableHooks } from 'owa-database-hooks';
import { DatabaseManager } from 'owa-database-manager';
import { initializeTransactionMeasurement } from 'owa-database-utils/lib/initializeTransactionMeasurement';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioning } from 'owa-database-utils/lib/enforceVersioning';
import { TimeoutSlowOperations } from 'owa-database-utils/lib/TimeoutSlowOperations';
import type { ConversationNodeItem, ConversationNode } from 'owa-graph-schema';
import type { CalendarGroupsTable } from 'owa-offline-calendar-groups-schema';
import { calendarGroupsSchema } from 'owa-offline-calendar-groups-schema';
import type { CalendarsTable } from 'owa-offline-calendars-schema';
import { calendarsSchema } from 'owa-offline-calendars-schema';
import type { ContactsTable } from 'owa-offline-contacts-schema';
import { contactsSchema } from 'owa-offline-contacts-schema';
import type {
    ConversationNodesTable,
    ConversationNodesTableType,
} from 'owa-offline-conversation-nodes-schema';
import { conversationNodesSchema } from 'owa-offline-conversation-nodes-schema';
import type { ConversationsTable, ConversationTableType } from 'owa-offline-conversations-schema';
import { areFeatureFlagsInitialized, isFeatureEnabled } from 'owa-feature-flags';
import { conversationsSchema, conversationsHooks } from 'owa-offline-conversations-schema';
import type { CalendarEventTableType, EventsTable } from 'owa-offline-events-schema';
import { eventsSchema } from 'owa-offline-events-schema';
import type { FoldersTable } from 'owa-offline-folders-schema';
import { foldersSchema } from 'owa-offline-folders-schema';
import type { MessageBodiesTable, MessageBodiesTableType } from 'owa-offline-message-bodies-schema';
import { messageBodiesSchema, messageBodiesHooks } from 'owa-offline-message-bodies-schema';
import type { MessagesTable, MessageTableType } from 'owa-offline-messages-schema';
import { messagesSchema, messagesHooks } from 'owa-offline-messages-schema';
import { type PGALTable, pgalSchema } from 'owa-offline-pgal-schema';
import type { SettingsTable } from 'owa-offline-settings-schema';
import { settingsSchema } from 'owa-offline-settings-schema';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import type { SyncLogsTable } from 'owa-offline-sync-logging-schema';
import { syncLogsSchema } from 'owa-offline-sync-logging-schema';
import type { SyncStateTable } from 'owa-offline-sync-state-schema';
import { syncStateSchema } from 'owa-offline-sync-state-schema';
import { tombstoneSchema } from 'owa-offline-tombstone-schema';
import type { TombstoneTable } from 'owa-offline-tombstone-schema';
import { isImmutableId } from 'owa-immutable-id-store';
import {
    doesTableSupportChangeNotifications,
    initializeIdbChangeNotifications,
} from './initializeIdbChangeNotifications';
import { SuppressNullObjects } from './utils/SuppressNullObjects';
import isImageFile from 'owa-file/lib/utils/isImageFile';
import { DatabaseVersionManager } from './utils/DatabaseVersionManager';
import { getDatabaseId } from './utils/getDatabaseId';
import isItemClassMeetingMessage from 'owa-meeting-message-utils/lib/utils/isItemClassMeetingMessage';
import type AttendeeType from 'owa-service/lib/contract/AttendeeType';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
export type ChangeType = any;

export type CreateChange<RowType, KeyType> = {
    table: string;
    type: ChangeType.Create;
    key: KeyType;
    obj: RowType;
    workerTime: number;
};

export type UpdateChange<RowType, KeyType> = {
    table: string;
    type: ChangeType.Update;
    mods: {
        [keyPath: string]: any;
    };
    key: KeyType;
    obj: RowType;
    oldObj: RowType;
    workerTime: number;
};

export type DeleteChange<RowType, KeyType> = {
    table: string;
    type: ChangeType.Delete;
    key: KeyType;
    oldObj: RowType;
    workerTime: number;
};

export type Change<RowType, KeyType> =
    | CreateChange<RowType, KeyType>
    | UpdateChange<RowType, KeyType>
    | DeleteChange<RowType, KeyType>;
export type ChangeCallback<RowType, KeyType> = (change: Change<RowType, KeyType>) => void;

const DATABASE_NAME = 'owa-offline-data';

export class AppDatabase extends Dexie {
    private changeCallbacks: ChangeCallback<any, any>[] = [];
    private upgradeStartTime: number | undefined;

    public readonly mailboxInfo: MailboxInfo;

    // tables
    public readonly calendarGroups!: CalendarGroupsTable;
    public readonly calendars!: CalendarsTable;
    public readonly contacts!: ContactsTable;
    public readonly conversationNodes!: ConversationNodesTable;
    public readonly conversations!: ConversationsTable;
    public readonly events!: EventsTable;
    public readonly folders!: FoldersTable;
    public readonly messageBodies!: MessageBodiesTable;
    public readonly messages!: MessagesTable;
    public readonly pgal!: PGALTable;
    public readonly settings!: SettingsTable;
    public readonly syncLogs!: SyncLogsTable;
    public readonly syncState!: SyncStateTable;
    public readonly offlineTombstones!: TombstoneTable;

    constructor(name: string, mailboxInfo?: MailboxInfo, versionForTest?: number) {
        super(name, {
            chromeTransactionDurability: 'relaxed',
            modifyChunkSize: 10,
            autoOpen: false,
        });

        if (!mailboxInfo) {
            mailboxInfo = getGlobalSettingsAccountMailboxInfo();
            if (!mailboxInfo) {
                throw new Error('Cannot create database without mailboxInfo');
            }
        }

        this.mailboxInfo = JSON.parse(JSON.stringify(mailboxInfo));

        // Collect a callstack via grey error if we are trying to access an offline db when offline is disabled.
        // We can't check whether offline is enabled until feature flags are initialized.
        if (areFeatureFlagsInitialized() && !isOfflineSyncEnabled()) {
            logGreyError('OfflineDbOpenWhenDisabled', new Error('OfflineDbOpenWhenDisabled'));
        }

        const store = {
            ...foldersSchema,
            'sync-state': 'name',
        };

        const versionManager = new DatabaseVersionManager(this, versionForTest, this.onUpgrading);

        versionManager.version(1).stores(store);
        // version 2 no longer needed with version 32
        versionManager.version(3).stores({
            ...settingsSchema,
        });
        versionManager.version(4).stores({
            // ...calendarsSchema, no longer needed with version 27
            ...calendarGroupsSchema,
            // ...eventsSchema, no longer needed with version 16
        });
        versionManager.version(7).stores({ ...conversationNodesSchema });
        // version 8 no longer needed with versions 36 and 39
        versionManager
            .version(9)
            .stores({ ...messageBodiesSchema })
            .upgrade(async tx => {
                // Extract messages from conversation nodes and store them in separate table
                const messages: ConversationNodeItem[] = [];

                await tx
                    .table('conversationNodes')
                    .toCollection()
                    .modify((conversationNode: ConversationNodesTableType) => {
                        conversationNode?.conversationNodes.forEach((node: ConversationNode) => {
                            node.Items = node.Items.map((item: ConversationNodeItem) => {
                                messages.push(item);

                                return {
                                    id: item.id,
                                    ConversationId: item.ConversationId,
                                    ParentFolderId: item.ParentFolderId,
                                    DateTimeSent: item.DateTimeSent,
                                    DateTimeReceived: item.DateTimeReceived,
                                };
                            });
                        });
                    });

                await tx.table('messageBodies').bulkPut(messages);
            });
        versionManager.version(11).stores({
            _changes: null,
            _intercomm: null,
            _syncNodes: null,
            _uncommittedChanges: null,
        }); // explicitly remove internal tables used by dexie-observable
        versionManager.version(14).stores({ ['contacts']: null }); // removing contacts table because of primary key change
        // this.version(15).stores({ ...contactsSchema }); no longer needed with version 21

        // this.version(16)
        //     .stores({ ...eventsSchema })
        //     .upgrade(tx => {
        //         return tx
        //             .table('events')
        //             .toCollection()
        //             .modify((event: CalendarEventTableType) => {
        //                 event.MetaData = { isPartialEvent: 1 };
        //             });
        //     });
        // no longer needed with version 47

        // version 16 no longer needed with versions 36 and 39
        // version 17 no longer needed with version 25
        versionManager.version(18).stores({ ...eventsSchema }); // Adding instanceKey index
        // version 19 no longer needed with versions 36 and 39
        // version 20 no longer needed with versions 36 and 39
        versionManager.version(21).stores({ ...contactsSchema }); // Adding isContact index
        // version 22 no longer needed since we are willing to lose super old sync logs
        // version 23 no longer needed with versions 36 and 39
        versionManager
            .version(24)
            .stores({ ...conversationNodesSchema })
            .upgrade(async tx => {
                return tx
                    .table('conversationNodes')
                    .toCollection()
                    .modify((conversation: ConversationNodesTableType) => {
                        if (conversation) {
                            conversation.MetaData = { hasMessagesToSync: 0 };
                        }
                    });
            });
        // version 25 no longer needed with version 33
        // version 26 no longer needed with versions 36 and 39
        versionManager.version(27).stores({
            ...calendarsSchema,
            'sync-state': 'name',
        }); // Adding FolderId.Id (calendar) index and reloadStatus (event sync state)
        versionManager
            .version(28) // migrate data from 'sync-state' table to 'syncState'
            .stores({ ...syncStateSchema, 'sync-state': null })
            .upgrade(async tx => {
                const syncState = await tx.table('sync-state').toArray();
                await tx.table('syncState').bulkAdd(syncState);
                return tx;
            });

        versionManager
            .version(29) // change 'sync-logs' table to 'syncLogs'
            .stores({ ...syncLogsSchema, 'sync-logs': null });

        // version 31 no longer needed due to version 36

        versionManager
            .version(32) // Move "worker_leader" table to "SystemDatabase"
            .stores({ worker_leader: null });

        // this.version(33) no longer needed with versions 36 and 39 which update the schema and force resync

        versionManager
            .version(35) // Force re-sync of folders
            .upgrade(async tx => {
                await tx.table('syncState').delete('folders');
                await tx.table('folders').clear();
                return tx;
            });

        versionManager
            .version(36) // Force message list resync since we lost focused/other property
            .stores({ ...conversationsSchema, ...messagesSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('messages');
                await tx.table('syncState').delete('conversations');
                await tx.table('messages').clear();
                await tx.table('conversations').clear();
            });

        versionManager.version(39).stores({ ...conversationsSchema, ...messagesSchema }); // Adding Flagged index to conversation and message

        versionManager
            .version(40) // Migrate data from numSyncDays to mailNumSyncDaysIndex in OfflineOptionSettings
            .upgrade(tx =>
                tx
                    .table('settings')
                    .toCollection()
                    .modify(value => {
                        if (
                            value?.name === 'offlineOptionSettings' &&
                            value.data.numSyncDays !== undefined
                        ) {
                            const oldNumSyncDays: number = value.data.numSyncDays;
                            delete value.data.numSyncDays;
                            if (value.data.mailNumSyncDaysIndex === undefined) {
                                value.data = {
                                    mailNumSyncDaysIndex: oldNumSyncDays,
                                    ...value.data,
                                };
                            }
                        }
                    })
            );

        versionManager
            .version(41) // recover from incorrect node.Items values
            .upgrade(tx =>
                tx
                    .table('conversationNodes')
                    .toCollection()
                    .modify((conversationNode: ConversationNodesTableType) => {
                        conversationNode?.conversationNodes.forEach((node: ConversationNode) => {
                            if (!Array.isArray(node.Items)) {
                                node.Items = Object.values(node.Items);
                            }
                        });
                    })
            );

        versionManager
            .version(42)
            .stores({ ...messageBodiesSchema })
            .upgrade(tx => {
                return tx
                    .table('messageBodies')
                    .toCollection()
                    .modify((messageBody: MessageBodiesTableType) => {
                        if (
                            messageBody &&
                            !messageBody.MetaData &&
                            messageBody.Attachments &&
                            messageBody.Attachments.length > 0 &&
                            messageBody.Attachments.some(
                                attachment => attachment.Name && isImageFile(attachment.Name)
                            )
                        ) {
                            messageBody.MetaData = {
                                needsSyncImage: 1,
                            };
                        }
                    });
            });

        // this.version(43) no longer needed with version 44

        // this.version(44) is no longer needed with version 45

        versionManager
            .version(45) // Force refresh of folders as the folder table schema has changed
            .stores({ ...foldersSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('folders');
                await tx.table('folders').clear();
                return tx;
            });

        versionManager.version(46).stores({ ...messageBodiesSchema });

        // Adding event start & end date indexes to events table
        versionManager.version(47).stores({ ...eventsSchema });
        // Adding MetaData.clientId to the schema of MessageBodies table
        versionManager.version(48).stores({ ...messageBodiesSchema });
        // Fixed bug causing conversation list corruption, so force resync
        versionManager.version(49).upgrade(async tx => {
            await tx.table('syncState').delete('conversations');
            await tx.table('conversations').clear();
        });
        // Contact Schema Change and Update profilePicture field to photo field
        versionManager
            .version(50)
            .stores({ ...contactsSchema })
            .upgrade(async tx => {
                // force resync to upgrade all sources and ensure all data migrates to new contacts gql schema
                await tx.table('syncState').delete('contacts');
                await tx.table('contacts').clear();
                return tx;
            });
        // Adding Personal GAL Sync.
        versionManager.version(51).stores({ ...pgalSchema });
        // Adding search indices to messageBodies table
        versionManager
            .version(52)
            .stores({ ...messageBodiesSchema, ...conversationNodesSchema })
            .upgrade(async tx => {
                await tx
                    .table('messageBodies')
                    .toCollection()
                    .modify((messageBody: MessageBodiesTableType) => {
                        if (messageBody) {
                            if (!messageBody.MetaData) {
                                messageBody.MetaData = {
                                    needsBackfill: 1,
                                };
                            } else {
                                messageBody.MetaData.needsBackfill = 1;
                            }
                        }
                    });
                await tx
                    .table('conversationNodes')
                    .toCollection()
                    .modify((node: ConversationNodesTableType) => {
                        if (node) {
                            const itemIds = new Set<string>();
                            node.conversationNodes.forEach((conversationNode: ConversationNode) => {
                                conversationNode.Items.forEach((item: ConversationNodeItem) => {
                                    itemIds.add(item.id);
                                });
                            });
                            node.MetaData.itemIds = Array.from(itemIds);
                        }
                    });
            });
        // Sync Person account info.
        versionManager
            .version(53)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                // force resync to get account inf for all people
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        versionManager.version(54).upgrade(async tx => {
            // force resync of item view message list to step past piles of old changes
            await tx.table('syncState').delete('messages');
            await tx.table('messages').clear();
        });

        versionManager.version(55).upgrade(async tx => {
            // force resync of conversation nodes and message bodies to fetch CLP labels
            await tx.table('syncState').delete('conversationNodes');
            await tx.table('syncState').delete('messageBodies');
            await tx.table('conversationNodes').clear();
            await tx.table('messageBodies').clear();
        });

        // Force resync to ensure all olsPersonaId is now outlookServicePersonId
        versionManager
            .version(56)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                // force resync to align field name to outlookServicePersonaId
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Adding needsSyncAttachment property for classic attachments
        versionManager
            .version(57)
            .stores({ ...messageBodiesSchema })
            .upgrade(tx => {
                return tx
                    .table('messageBodies')
                    .toCollection()
                    .modify((messageBody: MessageBodiesTableType) => {
                        if (
                            messageBody &&
                            messageBody.Attachments &&
                            messageBody.Attachments.length > 0 &&
                            messageBody.Attachments.some(
                                attachment =>
                                    attachment.Name &&
                                    !isImageFile(attachment.Name) &&
                                    !attachment.IsInline
                            )
                        ) {
                            if (!messageBody.MetaData) {
                                messageBody.MetaData = {
                                    needsSyncAttachment: 1,
                                };
                            } else {
                                messageBody.MetaData.needsSyncAttachment = 1;
                            }
                        }
                    });
            });

        // Fixed bug causing items missing in sent items folder, so force resync
        versionManager.version(58).upgrade(async tx => {
            await tx.table('syncState').delete('conversations');
            await tx.table('conversations').clear();
            await tx.table('conversationNodes').clear();
        });

        // versionManager.version(59) no longer needed with version 74

        // this.version(63) is no longer needed with version 66

        // Rename InstanceKey to RowKey
        versionManager.version(64).stores({ tombstones: null });
        versionManager.version(65).stores({ ...tombstoneSchema });

        // Bulk delete offline contact and pgal records with immutableId as it was unintentionally rolled out
        // to monarch and owa which caused duplicate contacts to be rendered in people hub
        // experience. This handles CAPIv3 immutableIds format as well.
        versionManager.version(67).upgrade(async tx => {
            // for offline contacts
            const [contactsWithImmutableId, pgalRecordsWithImmutableId] = await Promise.all([
                tx.table('contacts').toCollection().primaryKeys(),
                tx.table('pgal').toCollection().primaryKeys(),
            ]);

            await Promise.all([
                tx.table('contacts').bulkDelete(contactsWithImmutableId.filter(isImmutableId)),
                tx.table('pgal').bulkDelete(pgalRecordsWithImmutableId.filter(isImmutableId)),
            ]);
            return tx;
        });

        // Force resync as now the id has an account node id counterpart
        versionManager
            .version(67)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Add RowKey as index
        versionManager.version(68).stores({ ...tombstoneSchema });

        // Sync new sort and search keys
        versionManager
            .version(69)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Update offline search indices (updates MetaData.from and keyword extraction)
        versionManager
            .version(70)
            .stores({ ...messageBodiesSchema, ...conversationNodesSchema })
            .upgrade(async tx => {
                await tx
                    .table('messageBodies')
                    .toCollection()
                    .modify((messageBody: MessageBodiesTableType) => {
                        if (messageBody) {
                            if (!messageBody.MetaData) {
                                messageBody.MetaData = {
                                    needsBackfill: 1,
                                };
                            } else {
                                messageBody.MetaData.needsBackfill = 1;
                            }
                        }
                    });
            });

        // Sync new sort keys used for Intl.Collator sorting
        // .version(71) no longer needed with version 72

        // Force resync of pgal table to ensure all records non-null sort keys
        // .version(72) no longer needed with version 73

        // Sync to remove deprecated fields and indexes from version 69
        versionManager
            .version(73)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Force resync of events due to bug in sync lies flagging partial events as full events
        versionManager.version(74).upgrade(async tx => {
            await tx.table('syncState').delete('events');
            await tx.table('events').clear();
        });

        // Adding ToOrCcMe index to conversation and message
        versionManager
            .version(75)
            .stores({ ...conversationsSchema, ...messagesSchema })
            .upgrade(async tx => {
                await tx
                    .table('conversations')
                    .toCollection()
                    .modify((conversation: ConversationTableType) => {
                        if (conversation) {
                            conversation.MetaData.toOrCCMe =
                                conversation.ConversationToMe || conversation.ConversationCcMe
                                    ? 1
                                    : undefined;
                        }
                    });
                await tx
                    .table('messages')
                    .toCollection()
                    .modify((message: MessageTableType) => {
                        if (message) {
                            message.MetaData.toOrCCMe =
                                message.MessageToMe || message.MessageCcMe ? 1 : undefined;
                        }
                    });
            });

        // Adding HasFiles index to conversation and message
        versionManager
            .version(76)
            .stores({ ...conversationsSchema, ...messagesSchema })
            .upgrade(async tx => {
                await tx
                    .table('conversations')
                    .toCollection()
                    .modify((conversation: ConversationTableType) => {
                        if (conversation) {
                            conversation.MetaData.hasFiles =
                                conversation.HasAttachments ||
                                conversation.HasProcessedSharepointLink
                                    ? 1
                                    : undefined;
                        }
                    });
                await tx
                    .table('messages')
                    .toCollection()
                    .modify((message: MessageTableType) => {
                        if (message) {
                            message.MetaData.hasFiles =
                                message.HasAttachments || message.HasProcessedSharepointLink
                                    ? 1
                                    : undefined;
                        }
                    });
            });

        // Adding Mentioned and Has Calendar Invite indexes to conversation and message
        versionManager
            .version(77)
            .stores({ ...conversationsSchema, ...messagesSchema })
            .upgrade(async tx => {
                await tx
                    .table('conversations')
                    .toCollection()
                    .modify((conversation: ConversationTableType) => {
                        if (conversation) {
                            conversation.MetaData.mentioned = conversation.mentionedMe
                                ? 1
                                : undefined;
                            conversation.MetaData.hasCalendarInvite =
                                conversation.ItemClasses?.some(itemClass =>
                                    isItemClassMeetingMessage(itemClass)
                                )
                                    ? 1
                                    : undefined;
                        }
                    });
                await tx
                    .table('messages')
                    .toCollection()
                    .modify((message: MessageTableType) => {
                        if (message) {
                            message.MetaData.mentioned = message.MentionedMe ? 1 : undefined;
                            message.MetaData.hasCalendarInvite = isItemClassMeetingMessage(
                                message.ItemClass ?? ''
                            )
                                ? 1
                                : undefined;
                        }
                    });
            });

        // Adding isFollowableMeeting property from calendar event
        //update the logic here when canFollow is updated
        versionManager
            .version(78)
            .stores({ ...eventsSchema })
            .upgrade(tx => {
                return tx
                    .table('events')
                    .toCollection()
                    .modify((event: CalendarEventTableType) => {
                        if (!event) {
                            return;
                        }
                        if (
                            event &&
                            event.ItemId &&
                            event.IsOrganizer &&
                            event.RequiredAttendees &&
                            event.OptionalAttendees &&
                            event.HideAttendees
                        ) {
                            const requiredAttendees = (event.RequiredAttendees ?? []).filter(
                                (attendee): attendee is AttendeeType => attendee !== null
                            );
                            const optionalAttendees = (event.OptionalAttendees ?? []).filter(
                                (attendee): attendee is AttendeeType => attendee !== null
                            );
                            const attendees = requiredAttendees.concat(optionalAttendees);
                            const attendeesCount = event.IsOrganizer
                                ? attendees.length
                                : attendees.length - 1;
                            event.IsFollowableMeeting =
                                event.HideAttendees || !(attendeesCount === 1);
                        }
                    });
            });

        // VERSION 79 WAS REVERTED ON SDF AND SHOULD NOT BE USED

        // Force resync to ensure all records get the new personSources field and the new PersonDataSource shape
        versionManager
            .version(80)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Add MetaData.sortTime index
        versionManager.version(81).stores({ ...conversationsSchema });

        // Force resync to ensure all records get directoryExtensionAttributes
        versionManager
            .version(82)
            .stores({ ...pgalSchema })
            .upgrade(async tx => {
                await tx.table('syncState').delete('pgal');
                await tx.table('pgal').clear();
                return tx;
            });

        // Add index on SeriesMasterId.Id
        versionManager.version(83).stores({ ...eventsSchema });

        this.use({
            stack: 'dbcore',
            name: TimeoutSlowOperations.name,
            create: TimeoutSlowOperations,
        });

        this.use({
            stack: 'dbcore',
            name: SuppressNullObjects.name,
            create: SuppressNullObjects,
            level: -10, // The workaround should be applied before other middleware
        });

        addInfoToErrors(this);
        enforceVersioning(this);
        initializeTransactionMeasurement(this);

        // Table hooks need to be registered before initializing change notifications
        // so that we will report changes made by the hooks.
        registerTableHooks(this.conversations, conversationsHooks);
        registerTableHooks(this.messages, messagesHooks);

        registerTableHooks(this.messageBodies, messageBodiesHooks);

        initializeIdbChangeNotifications(this, this.notifyIdbChange);

        // Send telemetry on version and close events to help understand DatabaseClosedError errors we see in telemetry.
        this.on('versionchange', event => {
            const metaData = {
                oldVersion: event.oldVersion,
                newVersion: event.newVersion || -1,
            };

            // If newVersion is present, the database is being upgraded. We should prompt the user to reboot
            // rather than allow this session to run indefinitely in a degraded state.
            // If newVersion is missing, the database is being deleted. Since we don't know why the delete is happening,
            // we don't know if a reboot would help.
            if (event.newVersion && isFeatureEnabled('fwk-offline-reboot-version2')) {
                appAppReboot('OfflineDbVersionChange', false, metaData);
            } else {
                logUsage('OfflineDbVersionChange', metaData);
            }
        });
        this.on('close', () => logUsage('OfflineDbClosed'));
        this.on('blocked', () => logUsage('OfflineDbBlocked'));

        // Start opening the db to get started as soon as possible on any require upgrade
        this.open()
            .then(() => {
                this.setIsUpgradeInProgress(false);
            })
            .catch(err => {
                emitSyncEvent('AppDatabase: open error', { message: err.message });
                logGreyError('AppDatabase: open error', err);
            });
    }

    // Return value is unsubscribe function
    public subscribeToIdbChanges = <RowType, KeyType>(
        table: Table<RowType, KeyType>,
        callback: ChangeCallback<RowType, KeyType>
    ) => {
        if (!isRunningOnWorker()) {
            throw new Error('subscribeToIdbChanges: not supported on main thread');
        }
        if (!doesTableSupportChangeNotifications(table)) {
            throw new Error('subscribeToIdbChanges: table not supported');
        }
        if (this !== table.db) {
            throw new Error('subscribeToIdbChanges: wrong db');
        }
        const handler = (change: Change<RowType, KeyType>) => {
            if (change.table === table.name) {
                callback(change);
            }
        };
        this.changeCallbacks.push(handler);
        return () => {
            this.changeCallbacks = this.changeCallbacks.filter(cb => cb !== handler);
        };
    };

    private notifyIdbChange = (syncChange: Change<any, any>) => {
        this.changeCallbacks.forEach(changeCallbacks => {
            try {
                changeCallbacks(syncChange);
            } catch (err) {
                emitSyncEvent('notifyIdbChange: error', { message: err.message, stack: err.stack });
            }
        });
    };

    public getIsUpgradeInProgress = () => this.upgradeStartTime !== undefined;

    public setIsUpgradeInProgress(value: boolean) {
        if (value && this.upgradeStartTime === undefined) {
            this.upgradeStartTime = Date.now();
        } else if (!value && this.upgradeStartTime !== undefined) {
            logUsage(
                'DatabaseUpgrade',
                { version: this.verno, duration: Date.now() - this.upgradeStartTime },
                { mailbox: this.mailboxInfo }
            );
            this.upgradeStartTime = undefined;
        }
    }

    private onUpgrading = () => {
        if (!isRunningOnWorker()) {
            throw new Error('Migration not supported on main thread');
        }
        this.setIsUpgradeInProgress(true);
    };
}

const databaseManager = new DatabaseManager(DATABASE_NAME, AppDatabase);

export async function getPersistedDatabaseIds(includeDefault: boolean = false): Promise<string[]> {
    return databaseManager.getPersistedDatabaseIds(includeDefault);
}

export function getDatabase(mailboxInfo?: MailboxInfo | null) {
    return databaseManager.getDatabase(mailboxInfo, getDatabaseId(mailboxInfo));
}

export function getDatabaseById(mailboxInfo: MailboxInfo, databaseId: string) {
    return databaseManager.getDatabase(mailboxInfo, databaseId);
}

export function deleteDatabase(persistenceId: string = '', traceMessage: string): Promise<void> {
    emitSyncEvent('Database: ' + traceMessage, { persistenceId });
    logUsage('DeleteOfflineDatabase', { persistenceId, traceMessage });
    return databaseManager.deleteDatabase(persistenceId);
}

export async function resetDatabase(mailboxInfo?: MailboxInfo | null) {
    await deleteDatabase(getDatabaseId(mailboxInfo), 'Reset database');
    return getDatabase(mailboxInfo);
}

export function registerDatabaseDeleteListener(listener: (database: AppDatabase) => void) {
    databaseManager.registerDatabaseDeleteListener(listener);
}

export function resetDatabasesForTests() {
    databaseManager.databases.clear();
}

export function getDatabasesForTests() {
    return [databaseManager.databases];
}
