import { PerformanceDatapoint } from 'owa-analytics';
import type { DatapointOptions } from 'owa-analytics-types';
import { getThreadName } from 'owa-thread-config';

// Note:  this file is used on both the worker side and the main side to track worker bootstrap performance.
// The different contexts will create *different* datapoint objects, with the worker returning its datapoint
// timings to the main side as a basic JS object return value of the initialize method, which the main side
// combines with its own datapoint information via markFromJs.
let initDp: PerformanceDatapoint | undefined;
export const DPNAME = 'DataWorker_InitV2';

export enum InitWaterfall {
    LoadFromUrl = 1,
    SD,
    ChannelId,
    Bootstrap_WS,
    Bootstrap_WE,
    Bootstrap_C,
    SyncEventListener,
    Initialize_WS,
    Initialize_W_Settings,
    Initialize_W_Loc,
    Initialize_W_MAcct,
    Initialize_WE,
    Initialize_C,
    Return,
    OfflineSync,
}

export function endDp() {
    getDp().end();
    initDp = undefined;
}

export function mark(timing: keyof typeof InitWaterfall) {
    getDp().addToCustomWaterfall(InitWaterfall[timing], timing);
}

export function addCustomData(data: Record<string, string | number>) {
    getDp().addCustomData(data);
}

export function getDp() {
    if (!initDp) {
        const options: DatapointOptions | undefined =
            getThreadName() === 'DATA_WORKER_THREAD'
                ? {
                      skipNonMetadataTasks: true,
                  }
                : undefined;
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
        initDp = new PerformanceDatapoint(DPNAME, options);
    }

    return initDp;
}
