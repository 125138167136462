import type {
    ConversationNodesTableType,
    ConversationNodeType,
} from 'owa-offline-conversation-nodes-schema';
import type {
    Conversation,
    ConversationConversationNodesEdge,
    ConversationNode,
} from 'owa-graph-schema';

import { getHashedLogString } from './getHashedLogString';
import type { LogData } from './types/LogData';

export function getConversationNodesLogData(
    conv: ConversationNodesTableType | Conversation
): LogData {
    // We try to send properties which are likely to help investigate bugs and are relative small
    // We hash the subject here as a 1-way function. If the user provides the subject of an item,
    // we can search the logs for the hash of that subject.
    let nodes = undefined;
    if (isConversationNodesTableType(conv)) {
        nodes = conv.conversationNodes?.map(getConversationNodeLogString).join(';');
    } else {
        nodes = conv.conversationNodes?.edges
            ?.map((edge: ConversationConversationNodesEdge) =>
                getConversationNodeLogString(edge.node)
            )
            .join(';');
    }

    return {
        id: conv.id,
        nodes,
        totalNodeCount: conv.totalConversationNodesCount,
        toCount: conv.toRecipients?.length,
        ccCount: conv.ccRecipients?.length,
        lastModifiedTime: conv.lastModifiedTime,
        syncState: getHashedLogString(conv.syncState),
    };
}

function getConversationNodeLogString(node: ConversationNodeType | ConversationNode) {
    if (!Array.isArray(node.Items)) {
        return `node with invalid items: "${typeof node.Items}"`;
    }
    if (node.Items.length === 0 || !node.Items[0]) {
        return 'empty node';
    }
    const item = node.Items[0];
    return `itemId: ${item.id} received: ${item.DateTimeReceived}`;
}

function isConversationNodesTableType(conv: any): conv is ConversationNodesTableType {
    return 'MetaData' in conv;
}
