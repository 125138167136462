import {
    getGlobalSettingsAccountMailboxInfo,
    getMailAccountSources,
    isGlobalSettingsMailbox,
} from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { isCapabilityEnabled } from 'owa-capabilities';
import { serverNotificationsCapability } from 'owa-capabilities-definitions/lib/serverNotificationsCapability';
import { type MailboxInfo } from 'owa-client-types';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import type NotificationType from 'owa-service/lib/contract/NotificationType';
import type SubscriptionResponseData from 'owa-service/lib/contract/SubscriptionResponseData';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import subscribeToNotificationOperation from 'owa-service/lib/operation/subscribeToNotificationOperation';
import unsubscribeToNotificationOperation from 'owa-service/lib/operation/unsubscribeToNotificationOperation';
import {
    subscribeToOnosNotification,
    unsubscribeFromOnosNotification,
} from '../subscription/subscribeToOnosNotification';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import { errorThatWillCauseAlert } from 'owa-trace';
import getNotificationEmitter from '../notification/notificationEmitter';
import { subscriptionFailureReload } from '../notification/notificationHandler';
import NotificationEventType from '../schema/NotificationEventType';
import type NotificationSubscription from '../schema/NotificationSubscription';
import type { SubscriptionState } from '../schema/SubscriptionTrackerState';
import { type SubscriptionStatus } from '../schema/SubscriptionTrackerState';
import { addToBatch, isPending, getBatch, clearBatch, setPending } from './subscriptionBatchState';
import { MailboxBasedSubscriptionTracker } from './subscriptionTracker';
import callAndCatch from '../utils/callAndCatch';
import { emitWarn } from '../utils/emitTrace';
import { getNotificationConnectionLogger } from '../utils/notificationConnectionLogger';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { addToTimingMap } from 'owa-performance/lib/utils/timingMap';
import { ConnectionType } from '../schema/ConnectionType';
import getScopedPath from 'owa-url/lib/getScopedPath';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { NotificationManager } from '../notification/notificationManager';

// How many retries to do quickly before throttling
const QUICK_RETRY_THROTTLE_THRESHOLD = 3;

// How many retries to do in total
const DELAYED_RETRY_THRESHOLD = 8;

// How long to wait before retrying the subscription once we start throttling
const RETRY_THROTTLE_TIMEOUT = 5 * 60000;

// How long to wait between the first subscription call and when to actually submit to the server
const BATCH_TIMEOUT = 500;

export function registerSubscription(
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    connectionType: ConnectionType,
    completionCallback?: () => void
) {
    if (!subscription.requiresExplicitSubscribe) {
        return;
    }

    const subscriptionState = MailboxBasedSubscriptionTracker.getSubscriptionState(
        mailboxInfo,
        subscription.subscriptionId,
        connectionType
    );
    if (subscriptionState?.status === 0) {
        // First one of this subscription we're seeing
        // Log the inbox row subscription for classic signalR
        if (connectionType == ConnectionType.OwaClassicSignalR) {
            logInboxRowSubscription(mailboxInfo, subscription, 'subscribe');
        }
        getNotificationEmitter().emit(
            NotificationEventType.SubscriptionAdded,
            subscription,
            connectionType
        );
        submitSubscribe(mailboxInfo, subscription, connectionType, completionCallback);
    } else {
        updateSubscriptionLog(subscription, subscriptionState);
        completionCallback?.();
    }
}

export async function unregisterSubscription(
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    connectionType: ConnectionType
) {
    // If a subscribe call was never made to server, don't make an unsubscribe call
    if (!subscription.requiresExplicitSubscribe) {
        return;
    }
    const hasSubscription = MailboxBasedSubscriptionTracker.hasSubscription(
        mailboxInfo,
        subscription.subscriptionId,
        connectionType
    );

    // Only unsubscribe if we've stopped tracking the subscription (no other handlers are left)
    if (!hasSubscription) {
        logInboxRowSubscription(mailboxInfo, subscription, 'unsubscribe');
        getNotificationEmitter().emit(
            NotificationEventType.SubscriptionRemoved,
            subscription,
            connectionType
        );
        // Last subscription was just unregistered
        await submitUnsubscribe(subscription, connectionType);
    }
}

export function reinitSubscriptions(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    const tracker = MailboxBasedSubscriptionTracker.getTracker(mailboxInfo);
    if (tracker) {
        tracker.getSubscriptions(connectionType).map(subscription => {
            if (subscription.requiresExplicitSubscribe) {
                submitSubscribe(mailboxInfo, subscription, connectionType);
            }
        });
    }
}

export function reinitializeMissingSubscriptions(
    mailboxInfo: MailboxInfo,
    serverSubscriptionIds: string[],
    connectionType: ConnectionType
): string[] {
    const missingEntries: string[] = [];
    if (serverSubscriptionIds != null) {
        const serverEntries: {
            [index: string]: boolean;
        } = {};
        for (let j = 0; j < serverSubscriptionIds.length; ++j) {
            if (serverSubscriptionIds[j] != null) {
                serverEntries[serverSubscriptionIds[j]] = true;
            }
        }
        const tracker = MailboxBasedSubscriptionTracker.getTracker(mailboxInfo);
        if (tracker) {
            const clientSubscriptions = tracker.getSubscriptions(connectionType);
            for (let i = 0; i < clientSubscriptions.length; ++i) {
                const subscriptionState = MailboxBasedSubscriptionTracker.getSubscriptionState(
                    mailboxInfo,
                    clientSubscriptions[i].subscriptionId,
                    connectionType
                );

                // If the previous state of notification was connected, then only try to retry missing subscription else it will be retried through its regular path
                if (
                    subscriptionState?.status == 3 &&
                    !serverEntries[clientSubscriptions[i].subscriptionId]
                ) {
                    missingEntries.push(clientSubscriptions[i].subscriptionId);
                    submitSubscribe(mailboxInfo, clientSubscriptions[i], connectionType);
                }
            }
        }
    }

    return missingEntries;
}

export function submitSubscribe(
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    connectionType: ConnectionType,
    completionCallback?: () => void
) {
    const subscriptionState = MailboxBasedSubscriptionTracker.getSubscriptionState(
        mailboxInfo,
        subscription.subscriptionId,
        connectionType
    );
    if (subscriptionState) {
        // Clear the setTimeout handle from retry if there was one
        if (subscriptionState.pendingRetryHandle !== 0) {
            self.clearTimeout(subscriptionState.pendingRetryHandle);
            subscriptionState.pendingRetryHandle = 0;
        }

        // Enter pending state until fetch resolves
        subscriptionState.status = 2;

        updateSubscriptionLog(subscription, subscriptionState);

        addToBatch(mailboxInfo, subscription, connectionType);
        if (!isPending(mailboxInfo, connectionType)) {
            setTimeout(async () => {
                addToTimingMap('setTimeout', 'subscriptionSubmitter');
                // Save the batch in this closure
                const submittedBatch = getBatch(mailboxInfo, connectionType);

                // And clear the current batch so new subscriptions can start queueing
                clearBatch(mailboxInfo, connectionType);
                setPending(mailboxInfo, connectionType, false);
                await subscribeOnServer(mailboxInfo, submittedBatch, connectionType);
                completionCallback?.();
            }, BATCH_TIMEOUT);
            setPending(mailboxInfo, connectionType, true);
        }
    }
}

function getAllAccountsMailboxInfo() {
    if (isMonarchMultipleAccountsEnabled()) {
        // Filter out all accountas that do not support server notifications
        return getMailAccountSources()
            .map(source => source.mailboxInfo)
            .filter(mailboxInfo => isCapabilityEnabled(serverNotificationsCapability, mailboxInfo));
    }

    return undefined; // fallback default value if not in monarch
}

export async function subscribeOnServer(
    mailboxInfo: MailboxInfo,
    subscriptions: NotificationSubscription[],
    connectionType: ConnectionType
) {
    try {
        if (isMonarchMultipleAccountsEnabled()) {
            const subscriptionsForAllMailboxes = subscriptions.filter(subscription => {
                if (subscription.subscribeForAllMailboxes) {
                    // Validate that if consumer is passing subscribeForAllMailboxes=true they cant be passing a specific mailbox info
                    if (subscription.mailboxInfo) {
                        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                         * The error name (message) must be a string literal (no variables in it).
                         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                        errorThatWillCauseAlert(
                            'SubscribeOnServer - When subscribing to all mailboxes no mailboxInfo or mailbox specific param can be passed. SubscriptioId: ' +
                                subscription.subscriptionId
                        );
                        return false;
                    }

                    return true;
                }

                return false;
            });
            const subscriptionsForSingleMailbox = subscriptions.filter(
                subscription => !subscription.subscribeForAllMailboxes
            );

            // We are going to subscribe for requests grouped by mailbox
            if (
                subscriptionsForAllMailboxes.length > 0 ||
                subscriptionsForSingleMailbox.length > 0
            ) {
                const accountsMailboxInfo = getAllAccountsMailboxInfo();

                // Iterate through all mailboxes
                const subscriptionForAllMailboxesPromises: Promise<void>[] = [];

                accountsMailboxInfo?.forEach(accountMailboxInfo => {
                    // MailboxSubscriptions will have all subscriptions that are for all mailboxes
                    let mailboxSubscriptions = [...subscriptionsForAllMailboxes];

                    // If we have subscriptions for single mailboxes, then check which ones belog to this mailbox we are iterating
                    if (subscriptionsForSingleMailbox.length > 0) {
                        mailboxSubscriptions = mailboxSubscriptions.concat(
                            subscriptionsForSingleMailbox.filter(subscription => {
                                // If specified a mailboxInfo from the subscription, use it - otherwise gets the passed-in mailboxinfo
                                const currentMailboxInfo = subscription.mailboxInfo ?? mailboxInfo;
                                return isSameCoprincipalAccountMailboxInfos(
                                    currentMailboxInfo,
                                    accountMailboxInfo
                                );
                            })
                        );
                    }

                    if (mailboxSubscriptions.length > 0) {
                        subscriptionForAllMailboxesPromises.push(
                            subscribeToNotification(
                                mailboxSubscriptions,
                                mailboxInfo,
                                connectionType
                            )
                        );
                    }
                });

                await Promise.all(subscriptionForAllMailboxesPromises);
            }
        } else {
            await subscribeToNotification(
                subscriptions,
                mailboxInfo /* subscriptions */,
                connectionType
            );
        }
    } catch (error) {
        forEachTrackedSubscription(
            subscriptions,
            mailboxInfo,
            connectionType,
            (subscriptionState, subscription, internalMailboxInfo, internalConnectionType) => {
                handleSubscriptionFailure(
                    subscription,
                    subscriptionState,
                    error,
                    internalMailboxInfo,
                    internalConnectionType
                );
            }
        );
    }
}

export async function subscribeToNotification(
    subscriptions: NotificationSubscription[],
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType
) {
    const notificationLogger = getNotificationConnectionLogger();
    for (const subscription of subscriptions) {
        notificationLogger.buildNotificationTypeMap(subscription);
    }

    let responses: SubscriptionResponseData[] = [];

    if (
        connectionType == ConnectionType.OwaClassicSignalR ||
        connectionType == ConnectionType.OwaNetCoreSignalR
    ) {
        const owaService =
            connectionType === ConnectionType.OwaClassicSignalR ? 'owa' : 'owanetcore';
        const subscribeEndpoint = `${getScopedPath(
            owaService
        )}/service.svc?action=SubscribeToNotification`;
        const requestOptions = { endpoint: subscribeEndpoint };
        const options = getMailboxRequestOptions(mailboxInfo, requestOptions);

        responses = await subscribeToNotificationOperation(
            {
                request: {
                    Header: getJsonRequestHeader(),
                },
                subscriptionData: subscriptions.map(subscription => ({
                    SubscriptionId: subscription.subscriptionId,
                    Parameters: subscription.subscriptionParameters,
                })),
            },
            options
        );
    } else if (connectionType == ConnectionType.ONOSSignalR) {
        let connectionId = NotificationManager.getConnectionId(mailboxInfo, connectionType);

        if (!connectionId) {
            await NotificationManager.getConnectionInstance(mailboxInfo, connectionType)
                ?.connectionPromise;
            connectionId = NotificationManager.getConnectionId(mailboxInfo, connectionType);
        }

        if (!connectionId) {
            emitWarn('ConnectionId is not available for ONOS');
            return;
        }

        responses = await subscribeToOnosNotification(connectionId, subscriptions, mailboxInfo);
    }

    forEachTrackedSubscription(
        subscriptions,
        mailboxInfo,
        connectionType,
        (subscriptionState, subscription, internalMailboxInfo, internalConnectionType) => {
            const maybeResponse = responses.filter(
                response => response.SubscriptionId === subscription.subscriptionId
            );

            if (maybeResponse.length !== 0) {
                handleSubscriptionResponse(
                    maybeResponse[0],
                    subscription,
                    subscriptionState,
                    internalMailboxInfo,
                    internalConnectionType
                );
            } else {
                handleSubscriptionFailure(
                    subscription,
                    subscriptionState,
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * Error constructor names can only be a string literals.
                     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    new Error(`${subscription.subscriptionId} not in subscription responses`),
                    internalMailboxInfo,
                    internalConnectionType
                );
            }
        }
    );
}

async function submitUnsubscribe(
    subscription: NotificationSubscription,
    connectionType: ConnectionType
) {
    try {
        if (
            connectionType == ConnectionType.OwaClassicSignalR ||
            connectionType == ConnectionType.OwaNetCoreSignalR
        ) {
            // Make a call to the server to unsubscribe
            const mailboxInfo = subscription.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
            const owaService =
                connectionType === ConnectionType.OwaClassicSignalR ? 'owa' : 'owanetcore';
            const unsubscribeEndpoint = `${getScopedPath(
                owaService
            )}/service.svc?action=UnsubscribeToNotification`;
            const requestOptions = { endpoint: unsubscribeEndpoint };
            const options = getMailboxRequestOptions(mailboxInfo, requestOptions);
            await unsubscribeToNotificationOperation(
                {
                    subscriptionData: [
                        {
                            SubscriptionId: subscription.subscriptionId,
                            Parameters: subscription.subscriptionParameters,
                        },
                    ],
                },
                options
            );
        } else if (connectionType == ConnectionType.ONOSSignalR) {
            const mailboxInfo = subscription.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
            await unsubscribeFromOnosNotification(
                NotificationManager.getConnectionId(mailboxInfo, connectionType),
                [subscription],
                mailboxInfo
            );
        }
    } catch (error) {
        emitWarn(`Subscription unsubscribe failed: ${error.message}`);
    }
}

function handleSubscriptionResponse(
    response: SubscriptionResponseData,
    subscription: NotificationSubscription,
    subscriptionState: SubscriptionState,
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType
) {
    if (!response.SuccessfullyCreated) {
        logSubscribeToNotificationFailure(
            subscription.subscriptionId,
            subscriptionState.retries,
            subscriptionState.status,
            response.ErrorInfo ?? 'No Error Info',
            connectionType,
            subscription.subscriptionParameters.NotificationType,
            response.SubscriptionExists
        );

        retrySubscription(
            subscription,
            subscriptionState,
            response.ErrorInfo || '',
            mailboxInfo,
            connectionType
        );
    } else {
        // Subscription succeeded
        if (subscriptionState.retries > 0) {
            // Subscription reconnect, call handlers if they exist
            const refs = MailboxBasedSubscriptionTracker.getRefs(
                mailboxInfo,
                subscription.subscriptionId,
                connectionType
            );
            for (let i = 0; i < refs.length; i++) {
                const ref = refs[i];
                if (ref.subscription.onReconnected) {
                    callAndCatch(ref.subscription.onReconnected);
                }
            }
        }

        subscriptionState.retries = 0;
        subscriptionState.pendingRetryHandle = 0;
        subscriptionState.status = 3;

        updateSubscriptionLog(subscription, subscriptionState, '');
    }
}

function handleSubscriptionFailure(
    subscription: NotificationSubscription,
    subscriptionState: SubscriptionState,
    error: Error,
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType
) {
    logSubscribeToNotificationFailure(
        subscription.subscriptionId,
        subscriptionState.retries,
        subscriptionState.status,
        error.message,
        connectionType,
        subscription.subscriptionParameters.NotificationType
    );
    retrySubscription(subscription, subscriptionState, error.message, mailboxInfo, connectionType);
}

function retrySubscription(
    subscription: NotificationSubscription,
    subscriptionState: SubscriptionState,
    reason: string,
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType
) {
    // Send reloads on each failure so subscribers can stay at least partially up to date
    subscriptionFailureReload(subscription.subscriptionId, mailboxInfo);

    // QuickFix after an incident in gallatin where a lot of users subscribeToNotifications were getting timed due to mapi exceptions.
    // this quick fix is in place to prevent the retry logic from retrying the subscription if the feature flag is enabled.
    if (!isFeatureEnabled('notif-blockRetrySubscription')) {
        if (subscriptionState.retries === 0) {
            // For the subscriptions that have custom logic for handling subscription disconnects
            const refs = MailboxBasedSubscriptionTracker.getRefs(
                mailboxInfo,
                subscription.subscriptionId,
                connectionType
            );
            for (let i = 0; i < refs.length; i++) {
                const ref = refs[i];
                if (ref.subscription.onDisconnected) {
                    callAndCatch(() => {
                        ref.subscription.onDisconnected?.(reason);
                    });
                }
            }
        }

        subscriptionState.retries += 1;
        subscriptionState.status = 1;
        if (subscriptionState.retries < QUICK_RETRY_THROTTLE_THRESHOLD) {
            // The first few retries will retry quickly
            submitSubscribe(mailboxInfo, subscription, connectionType);
        } else if (subscriptionState.retries < DELAYED_RETRY_THRESHOLD) {
            // Retry after a long interval so we don't spam the server
            subscriptionState.pendingRetryHandle = self.setTimeout(() => {
                subscriptionState.pendingRetryHandle = 0;
                submitSubscribe(mailboxInfo, subscription, connectionType);
            }, RETRY_THROTTLE_TIMEOUT);
        }

        updateSubscriptionLog(subscription, subscriptionState, reason);
    }
}

function forEachTrackedSubscription(
    subscriptions: NotificationSubscription[],
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType,
    callback: (
        subscriptionState: SubscriptionState,
        subscription: NotificationSubscription,
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ) => void
) {
    for (const subscription of subscriptions) {
        const subscriptionState = MailboxBasedSubscriptionTracker.getSubscriptionState(
            mailboxInfo,
            subscription.subscriptionId,
            connectionType
        );
        if (!subscriptionState) {
            // If the subscription id wasn't present in the tracker
            // This can happen if the subscribe call takes a while and the client unsubscribes in the meantime,
            // so when we try to run this callback, the subscription tracker entry is already gone.
            continue;
        }

        callback(subscriptionState, subscription, mailboxInfo, connectionType);
    }
}

function updateSubscriptionLog(
    subscription: NotificationSubscription,
    subscriptionState?: SubscriptionState,
    error?: string
) {
    getNotificationEmitter().emit(
        NotificationEventType.SubscriptionUpdated,
        subscription,
        subscriptionState,
        error
    );
}

function logSubscribeToNotificationFailure(
    subscriptionId: string,
    retries: number,
    subscriptionStatus: SubscriptionStatus,
    errorInfo: string,
    connectionType: ConnectionType,
    notificationType?: NotificationType,
    doesSubscriptionExist?: boolean
) {
    logUsage('SubscribeToNotificationFailure', {
        subscriptionId,
        retries,
        subscriptionStatus,
        errorInfo,
        notificationType,
        subscriptionExist: doesSubscriptionExist,
        connectionType,
    });
}

function logInboxRowSubscription(
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    action: 'subscribe' | 'unsubscribe'
) {
    if (
        subscription.subscriptionParameters.NotificationType === 'RowNotification' &&
        isGlobalSettingsMailbox(mailboxInfo)
    ) {
        const inboxId = folderNameToId('inbox', mailboxInfo);
        if (subscription.subscriptionId.includes(inboxId)) {
            logUsage('InboxRowSubscription', {
                action,
                id: subscription.subscriptionId,
            });
        }
    }
}
