import type { AppDatabase } from 'owa-offline-database';

export async function loadSetting<T>(database: AppDatabase, name: string): Promise<T | undefined> {
    const setting = await database.settings.get(name);
    return setting?.data;
}

export async function loadSettings<T>(
    database: AppDatabase,
    names: string[]
): Promise<T[] | undefined> {
    const settings = await database.settings.bulkGet(names);
    return settings?.map(setting => setting?.data);
}
