import Dexie from 'dexie';
import type { Modifications } from './TableHooks';

export function getNewObjFromMods<T extends object>(oldObj: T, mods: Modifications): T {
    const newObj = Dexie.deepClone(oldObj);
    for (const propPath of Object.keys(mods)) {
        var mod = mods[propPath];
        if (typeof mod === 'undefined') {
            Dexie.delByKeyPath(newObj, propPath);
        } else {
            Dexie.setByKeyPath(newObj, propPath, mod);
        }
    }

    return newObj;
}
