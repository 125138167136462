import { doesSystemDatabaseExist, getSystemDatabase } from 'owa-offline-system-database';
import type { WorkerLeaderTable } from 'owa-offline-worker-leader-schema';
import { WORKER_LEADER_TABLE_NAME } from 'owa-offline-worker-leader-schema';
import { Dexie } from 'dexie';
import { elect } from './elect';

export default async function depose(id: string) {
    if (await doesSystemDatabaseExist()) {
        const systemDatabase = await getSystemDatabase();
        return systemDatabase
            .transaction('rw', WORKER_LEADER_TABLE_NAME, async tx => {
                const workerLeaderTable: WorkerLeaderTable = tx.table(WORKER_LEADER_TABLE_NAME);
                const worker = await workerLeaderTable.get(id);

                if (worker?.isLeader) {
                    await workerLeaderTable.delete(id);
                    // do the election in the same transaction so the one deposed can't steal the election back
                    await elect(tx);
                }
            })
            .catch(e => {
                if (e.name !== Dexie.errnames.DatabaseClosed) {
                    throw e;
                }
            });
    }
}
