import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OfflineSyncSettingsStore" */ './lazyIndex')
);

export const lazyLoadOfflineSettings = new LazyAction(lazyModule, m => m.loadOfflineSettings);
export const lazySaveOfflineSettings = new LazyAction(lazyModule, m => m.saveOfflineSettings);

export { OFFLINE_OPTION_SETTINGS } from './constants';
