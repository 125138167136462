import EventEmitter from 'owa-event-emitter';
import { registerEventListener } from './registerEventListener';

let emitter: EventEmitter;

export function getPstLoggingEventEmitter(): EventEmitter {
    if (!emitter) {
        emitter = new EventEmitter();
        registerEventListener(emitter);
    }
    return emitter;
}

export function setPstLoggingEventEmitter(newEmitter: EventEmitter) {
    emitter = newEmitter;
}
