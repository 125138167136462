import type { Table } from 'dexie';
import type { CalendarEntry } from 'owa-graph-schema';
import type { DexieKey } from 'owa-database-schema';

export const CALENDARS_TABLE_NAME = 'calendars';
export type CalendarsTable = Table<CalendarEntry, string>;

export const CALENDARS_ID_KEY: DexieKey<CalendarEntry> = 'calendarId.id';
export const CALENDAR_FOLDER_ID_KEY: DexieKey<CalendarEntry> = 'FolderId.Id';

export const calendarsSchema = {
    calendars: `${CALENDARS_ID_KEY}, ${CALENDAR_FOLDER_ID_KEY}`,
};
