import type { WindowBootstrapSettings } from 'owa-worker-types';
import { unblockLazyLoadCallbacks } from 'owa-bundling-light';
import { setWindowData } from 'owa-window-data';
import { WorkerWindowData } from 'owa-worker-shared';
import { overrideGetMetatags } from 'owa-metatags';
import { initTimings } from 'owa-data-worker-utils';
import { patchComlink } from 'owa-comlink-patch';
import { overrideSessionId } from 'owa-config/lib/getSessionId';
import { setThreadName } from 'owa-thread-config';
import { setWorkerScriptLoadTimings } from './setWorkerScriptLoadTimings';

// bootstrap is the first thing called by the client -- it should be as lightweight as possible and require as few synchronous imports as possible
export const bootstrap = (rawData: WindowBootstrapSettings, sessionid = '') => {
    // the window data needs to be set ASAP in boot. don't even call initTimings. really.
    const windowData = new WorkerWindowData(rawData);
    setWindowData(windowData);
    overrideGetMetatags(windowData.metatags);

    // Set the thread name for the current thread
    setThreadName('DATA_WORKER_THREAD');

    // this needs to run after window data is set
    initTimings.mark('Bootstrap_WS');

    patchComlink();

    if (sessionid) {
        overrideSessionId(sessionid);
    }

    // on main, we block lazy loading until render completes.  there's no render on the worker, so do it now
    unblockLazyLoadCallbacks();

    // Record telemetry for the evaluation timings of worker script load.
    setWorkerScriptLoadTimings();

    initTimings.mark('Bootstrap_WE');
};
