import { logUsage } from 'owa-analytics';
import { isPermanentDbError } from 'owa-offline-errors';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import { timeout } from 'owa-sleep/lib/timeout';

let queueDbFault: Error | undefined;
let consecutiveTimeouts = 0;
export const MAX_CONSECUTIVE_TIMEOUTS = 5;
const QUEUE_TIMEOUT_MESSAGE = 'ActionQueue DB Timeout';
const QUEUE_TIMEOUT_MS = 20000;

export async function trackQueueDbHealth<T>(code: () => Promise<T>): Promise<T> {
    try {
        const rv = await timeout(code(), QUEUE_TIMEOUT_MS, QUEUE_TIMEOUT_MESSAGE);

        // Reset consecutive timeout errors if we succeed (the await on the race will throw on exception, including timeouts)
        consecutiveTimeouts = 0;
        if (isQueueTimeoutError(queueDbFault)) {
            logUsage('actionqueue_db_timeout_resolved');
            emitSyncEvent('actionqueue_db_timeout_resolved', {});
            queueDbFault = undefined;
        }

        return rv;
    } catch (e) {
        if (isQueueTimeoutError(e)) {
            consecutiveTimeouts++;
            if (consecutiveTimeouts == MAX_CONSECUTIVE_TIMEOUTS) {
                queueDbFault = e;
                logUsage('actionqueue_db_timeout');
            }

            emitSyncEvent('actionqueue_db_timeout', { consecutiveTimeouts });
        } else if (isPermanentDbError(e)) {
            emitSyncEvent('actionqueue_db_permanent_error', { error: e });
            queueDbFault = e;
        }

        throw e;
    }
}

export function isQueueDbUnhealthy(): boolean {
    return !!queueDbFault;
}

export function isQueueTimeoutError(error: Error | undefined): boolean {
    return error?.message === QUEUE_TIMEOUT_MESSAGE;
}

export function testResetQueueHealth() {
    queueDbFault = undefined;
    consecutiveTimeouts = 0;
}
