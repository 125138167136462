import type { DataWorkerBroadcast } from 'owa-data-worker-utils';
import { DataWorkerBroadcastChannelName } from 'owa-data-worker-utils';
import { depose } from 'owa-offline-worker-leader';
import { handleQueueChanged } from 'owa-queued-actions';
import { wrap, expose } from 'comlink';
import { errorThatWillCauseAlertAndThrow } from 'owa-trace';
import {
    lazyOnManualSyncMail,
    lazyOnManualSyncFolderHierarchy,
    lazyOnManualSyncCalendar,
    lazyOnManualSyncPGAL,
} from 'owa-offline-sync-manager';
import { syncAccountSourceListStore } from '../util/syncAccountSourceListStore';
import { setOptionsStoreData } from '../util/setOptionsStoreData';
import { updateUserConfiguration } from '../util/updateUserConfiguration';
import { setDiagnosticPanelLoggingEnabled } from '../util/setDiagnosticPanelLoggingEnabled';
import { setAccountTimeZone } from '../actions/setAccountTimezone';

let sender: DataWorkerBroadcast;

const noop = () => {};

// broadcast sender/receivers for worker thread
export function getBroadcast(): DataWorkerBroadcast {
    if (!sender) {
        if (self.BroadcastChannel) {
            const channel = new BroadcastChannel(DataWorkerBroadcastChannelName);
            sender = wrap<DataWorkerBroadcast>(channel);

            let listener: DataWorkerBroadcast = {
                unloading: depose,
                actionQueueChanged: handleQueueChanged,
                queuedActionAccepted: <any>noop,
                accountsChanged: syncAccountSourceListStore,
                optionsChanged: setOptionsStoreData,
                onManualSyncMail: lazyOnManualSyncMail.importAndExecute,
                userConfigurationChanged: updateUserConfiguration,
                setDiagnosticPanelLoggingEnabled,
                scheduleHierarchySync: lazyOnManualSyncFolderHierarchy.importAndExecute,
                onManualSyncPGAL: lazyOnManualSyncPGAL.importAndExecute,
                onManualSyncCalendar: lazyOnManualSyncCalendar.importAndExecute,
                setAccountTimeZone,
                onWorkerError: <any>noop,
            };

            listener = new Proxy(listener, {
                // Avoid error when multiple tabs have different versions of listener contract
                get: (
                    target: DataWorkerBroadcast,
                    property: keyof Omit<DataWorkerBroadcast, 'name'>
                ) => {
                    return target[property] ?? <any>noop;
                },
            });
            expose(listener, channel);
        } else {
            // broadcast channel not supported.  it's an optimization for unloading/election, but required for queued actions to work
            sender = {
                unloading: <any>noop,
                actionQueueChanged: <any>noop,
                queuedActionAccepted: () => {
                    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                     * The error name (message) must be a string literal (no variables in it).
                     *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                    errorThatWillCauseAlertAndThrow(
                        new Error('broadcast channel is required for queued actions!')
                    );
                },
                accountsChanged: <any>noop,
                optionsChanged: <any>noop,
                onManualSyncMail: <any>noop,
                userConfigurationChanged: <any>noop,
                setDiagnosticPanelLoggingEnabled: <any>noop,
                scheduleHierarchySync: <any>noop,
                onManualSyncPGAL: <any>noop,
                onManualSyncCalendar: <any>noop,
                setAccountTimeZone: <any>noop,
                onWorkerError: <any>noop,
            };
        }
    }

    return sender;
}
