import { ConnectionState } from '../schema/ConnectionState';

export const getConnectionStateFromHubConnection = (
    hubConnectionState: string
): ConnectionState => {
    switch (hubConnectionState) {
        case 'Disconnected':
            return ConnectionState.Disconnected;
        case 'Connecting':
            return ConnectionState.Connecting;
        case 'Connected':
            return ConnectionState.Connected;
        case 'Disconnecting':
            return ConnectionState.Disconnected;
        case 'Reconnecting':
            return ConnectionState.Reconnecting;
        default:
            return ConnectionState.Disconnected;
    }
};
