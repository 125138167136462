import type { ResolverContext } from 'owa-graph-schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { ShouldRetry } from 'owa-service/lib/RequestOptions';
import type { BasicRequestOptions } from './BasicRequestOptions';
import { fromBasicOptions, toBasicOptions } from './BasicRequestOptions';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';
import { PerformanceDatapoint } from 'owa-analytics';
import type { PerformanceDatapointObjectType } from 'owa-analytics-types';

type FullContext = ResolverContext & {
    cache: any;
    getCacheKey: any;
};

type ComplexContext = Required<
    Pick<
        FullContext,
        'cache' | 'client' | 'dataLoaders' | 'isFeatureEnabled' | 'requestOptions' | 'getCacheKey'
    >
>;
export type BasicContext = Omit<FullContext, keyof ComplexContext> & {
    requestOptions: BasicRequestOptions;
};

const complexKeys: {
    [T in keyof ComplexContext]: 1;
} = {
    cache: 1,
    client: 1,
    dataLoaders: 1,
    isFeatureEnabled: 1,
    requestOptions: 1,
    getCacheKey: 1,
};

export function toBasicContext(complex: Record<string, any>): BasicContext {
    complex = complex || {};
    const basic: Partial<BasicContext> = {};
    Object.keys(complex).forEach(k => {
        if (!complexKeys[k as keyof ComplexContext]) {
            if (typeof complex[k] !== 'function') {
                basic[k as keyof BasicContext] = complex[k] as never;
            } else {
                debugErrorThatWillShowErrorPopupOnly(`context has a non-serializable key ${k}`);
            }
        }
    });

    basic.requestOptions = toBasicOptions(complex.requestOptions);
    basic.perfDatapoint = complex.perfDatapoint?.toJSObject?.();
    return basic as BasicContext;
}

export function fromBasicContext(
    basic: Partial<BasicContext>,
    maybeShouldRetry?: ShouldRetry
): FullContext {
    basic = basic || {};

    const requestOptions = fromBasicOptions(basic.requestOptions || {}, maybeShouldRetry);
    const complex: Partial<FullContext> = {
        ...basic,
        requestOptions,
        isFeatureEnabled,
        perfDatapoint: basic.perfDatapoint
            ? PerformanceDatapoint.fromPerfDpJSObject(
                  basic.perfDatapoint as unknown as PerformanceDatapointObjectType
              )
            : undefined,
    };

    return complex as FullContext;
}
