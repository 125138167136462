import { isFeatureEnabled } from 'owa-feature-flags';
import { registerTracerListener, TraceLevel } from 'owa-trace';
import { getBroadcast } from '../broadcast/getBroadcast';

export function initializeErrorListener() {
    if (
        process.env.NODE_ENV === 'dev' ||
        (isFeatureEnabled('fwk-devTools') && isFeatureEnabled('fwk-errorpopup'))
    ) {
        registerTracerListener(
            'UIExceptionHandler',
            (message, traceLevel, _component, details) => {
                if (traceLevel === TraceLevel.Error || traceLevel == TraceLevel.DebugError) {
                    getBroadcast().onWorkerError(message, details);
                }
            },
            true /* listenToErrorsOnly */
        );
    }
}
