import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import type { QueuedAction, QueuedOperation } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import type { MailboxInfo } from 'owa-client-types';
import { logGreyError } from 'owa-analytics';
import { scrubForPii } from 'owa-config';
import { type TombstoneReasonType } from 'owa-offline-tombstone-schema';
import { logComposeOfflineData } from 'owa-offline-compose-logging';

// export for testing only
export const updateIDBItemId = async (
    clientId: string,
    itemId: string,
    changeKey: string | undefined,
    isSmartResponse: boolean,
    mailboxInfo: MailboxInfo,
    editorId: string
) => {
    const database = await getDatabase(mailboxInfo);
    await database.transaction(
        'rw',
        database.messages,
        database.messageBodies,
        database.offlineTombstones,
        async transaction => {
            // Update Indexed DB with the service id
            setTransactionSource(transaction, 'localLie');
            logComposeOfflineData({
                step: isSmartResponse
                    ? 'createItemProcessor-UpdateIDBItemIdForSmartResponse'
                    : 'createItemProcessor-UpdateIDBItemIdForDraft',
                editorId,
                clientId,
                itemId,
            });
            await database.messages.delete(itemId);
            await database.messageBodies.delete(itemId);

            const updates: {
                id: string;
                ItemId: {
                    Id: string;
                    ChangeKey?: string;
                };
            } = {
                id: itemId,
                ItemId: { Id: itemId },
            };
            if (changeKey) {
                updates.ItemId.ChangeKey = changeKey;
            }

            return Promise.all([
                database.messages.update(clientId, updates),
                database.messageBodies.update(clientId, updates),
                database.offlineTombstones
                    .where('RowKey')
                    .equals(clientId)
                    .filter(tombstone => tombstone.TombstoneReason == 5)
                    .modify({ RowKey: itemId }),
            ]);
        }
    );
};

export async function createItemProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        const isSmartResponse: boolean = action.operation.operationName == 'CreateSmartResponse';
        const ID_CHANGES: Map<string, string> = new Map();
        const clientId: string = action.operation.variables.clientItemId;
        const clientChangeKey: string = action.operation.variables.clientChangeKey;
        const fetchResultData = result.fetchResult?.data;
        const editorId: string =
            (action.operation as QueuedOperation).context.editorId || 'EditorIdNotFound';

        if (fetchResultData) {
            const serverItemId = isSmartResponse
                ? fetchResultData.createSmartResponse?.draft?.ItemId
                : fetchResultData.createDraft?.draft?.ItemId;
            const itemId: string | undefined = serverItemId?.Id;
            const changeKey: string | undefined = serverItemId?.ChangeKey;

            if (itemId) {
                const mailBoxInfo = action.operation.variables.draft.mailboxInfo;
                try {
                    await updateIDBItemId(
                        clientId,
                        itemId,
                        changeKey,
                        isSmartResponse,
                        mailBoxInfo,
                        editorId
                    );

                    const database = await getDatabase(mailBoxInfo);
                    const clientMessageBody = await database.messageBodies.get(clientId);
                    const serviceMessageBody = await database.messageBodies.get(itemId);
                    logComposeOfflineData({
                        step: 'createItemProcessor-MessageBody',
                        editorId,
                        clientId,
                        clientChangeKey,
                        itemId,
                        changeKey,
                        clientMessageBodyFound: !!clientMessageBody,
                        serviceMessageBodyFound: !!serviceMessageBody,
                    });
                } catch (err) {
                    const errorData = {
                        step: 'createItemProcessor-Exception',
                        editorId,
                        clientId,
                        clientChangeKey,
                        itemId,
                        changeKey,
                    };
                    logGreyError('MailComposeOfflineAction', err, errorData);
                    logComposeOfflineData({
                        ...errorData,
                        errorMessage: scrubForPii(err?.message),
                    });
                }

                ID_CHANGES.set(clientId, itemId);
            }

            if (changeKey) {
                ID_CHANGES.set(clientChangeKey, changeKey);
            }
        } else {
            // If the error is accepted, it means we won't retry the action, so we should log it as a save error
            const error = result.fetchResult?.errors?.[0];
            logComposeOfflineData({
                step: 'createItemProcessor-ErrorResult',
                editorId,
                clientId,
                clientChangeKey,
                isSmartResponse,
                errorMessage: scrubForPii(error?.message),
                errorStack: scrubForPii(error?.stack),
            });
        }

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: ID_CHANGES,
        };
    }

    return Promise.resolve(rv);
}
