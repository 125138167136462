import type Dexie from 'dexie';
import { logStartGreyError } from 'owa-analytics-start';
import { enforceVersioningInternal } from './enforceVersioningInternal';

/**
 * Newer versions of Dexie no longer throw a VersionError when the client tries to open a database
 * with a newer version than the one it knows about. Instead it opens anyway and adjusts tables and indexes
 * to satisfy the older schema. That behavior breaks several critical scenarios for us, for example
 * using a db version to drop bad data or add a new MetaData property.
 * To block this behavior, we override open and check the version before allowing Dexie to try to open the db.
 */
export const enforceVersioning = (database: Dexie) =>
    enforceVersioningInternal(database, logStartGreyError);
