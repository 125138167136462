import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { type QueuedAction } from '../types/QueuedAction';
import { findRejectedResult, isResponseCodeError } from './defaultResultProcessor';

export const NO_ID_CHANGES: ReadonlyMap<string, string> = new Map();

export async function removeFavoriteResultProcessor(
    _action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        // The service can 404 on a remove favorite if the favorite was already removed,
        // in which case, we don't want to retry the action. Remove favorites post processing already handles this case.
        if (isResponseCodeError(rejectedResult.rejectError, 404)) {
            rv = {
                fetchResult: result.fetchResult,
                fetchError: result.fetchError,
                idChanges: NO_ID_CHANGES,
            };
        } else {
            rv = rejectedResult;
        }
    } else {
        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: NO_ID_CHANGES,
        };
    }

    return Promise.resolve(rv);
}
