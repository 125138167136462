import type { MessageTableType } from 'owa-offline-messages-schema';
import type { ItemRow } from 'owa-graph-schema';

import { getHashedLogString } from './getHashedLogString';
import type { LogData } from './types/LogData';

export function getMessageLogData(msg: MessageTableType | ItemRow): LogData {
    // We try to send properties which are likely to help investigate bugs and are relative small
    // We hash the subject here as a 1-way function. If the user provides the subject of an item,
    // we can search the logs for the hash of that subject.
    let sortTime;
    let isDirty;
    let creationSource;
    if (isMessageTableType(msg)) {
        sortTime = msg.MetaData.sortTime;
        isDirty = msg.MetaData.isDirty;
        creationSource = msg.MetaData.creationSource;
    }

    return {
        id: msg.id || msg.ItemId?.Id,
        instanceKey: msg.InstanceKey,
        sent: msg.DateTimeSent,
        received: msg.DateTimeReceived,
        receivedOrRenew: msg.ReceivedOrRenewTime,
        isRead: msg.IsRead,
        isDraft: msg.IsDraft,
        flag: msg.Flag?.FlagStatus,
        importance: msg.Importance,
        likes: 0,
        hasAttachments: msg.HasAttachments,
        inference: msg.InferenceClassification,
        mentioned: msg.MentionedMe,
        sensitivity: msg.Sensitivity,
        subject: getHashedLogString(msg.Subject),
        folder: getHashedLogString(msg.ParentFolderId?.Id),
        sortTime,
        isDirty,
        creationSource,
    };
}

function isMessageTableType(msg: any): msg is MessageTableType {
    return 'MetaData' in msg;
}
