import { type SubmitProcessorName } from '../types/QueuedActionOptions';

type SubmitDependency = {
    name: SubmitProcessorName;
    key?: string;
};

type DependencyType = 'BlockingKey' | SubmitProcessorName;

type DependencyKeyMap = {
    [K in DependencyType]?: Map<string, number[]>;
};

export type DependentAction = {
    id: number;
    blockingKeys: readonly string[];
    opName: string;
    submitProcessor?: SubmitDependency;
};

type BlockingKeysType = DependentAction['blockingKeys'][number];

export function createDependencyMap() {
    // a map from dependency type/key to the list of actions that have that dependency
    let dependencyKeyMap: DependencyKeyMap = {};

    function add(action: DependentAction) {
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        action.blockingKeys.forEach(blockingKey => {
            mapUpsert('BlockingKey', blockingKey, action.id);
        });

        mapUpsert(action.submitProcessor?.name, action.submitProcessor?.key, action.id);
    }

    function remove(action: DependentAction) {
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        action.blockingKeys.forEach(blockingKey => {
            mapRemove('BlockingKey', blockingKey, action.id);
        });

        mapRemove(action.submitProcessor?.name, action.submitProcessor?.key, action.id);
    }

    // an action is blocked if there are actions in the map with smaller ids (i.e., they queued earlier)
    function isBlockedByEarlierAction(action: DependentAction) {
        return action.blockingKeys.some(d => {
            const map = dependencyKeyMap['BlockingKey'];
            return map?.get(d)?.find(id => id < action.id);
        });
    }

    // actions share the submitProcessor/key of the action
    function getSubmitKeyActions(action: DependentAction) {
        const rv = [];
        const name = action.submitProcessor?.name;
        const key = action.submitProcessor?.key;

        if (!!name && !!key) {
            const map = dependencyKeyMap[name];
            if (map) {
                const ids = map.get(key);
                if (ids) {
                    rv.push(...ids);
                }
            }
        }

        return rv;
    }

    function getAllBlockingKeys() {
        const blockingKeysMap = dependencyKeyMap['BlockingKey'];
        return blockingKeysMap?.keys() || [];
    }

    function clear() {
        dependencyKeyMap = {};
    }

    function update(changes: ReadonlyMap<BlockingKeysType, BlockingKeysType>) {
        for (const change of changes) {
            const blockBefore = change[0];
            const blockAfter = change[1];

            // all actions that used to be blocked on the before key are now blocked on the after key
            const blockingKeysMap = dependencyKeyMap['BlockingKey'];
            if (blockingKeysMap) {
                const blockingActions = blockingKeysMap.get(blockBefore);
                if (blockingActions) {
                    blockingKeysMap.delete(blockBefore);
                    blockingKeysMap.set(blockAfter, blockingActions);
                }
            }
        }
    }

    function mapUpsert(
        dependency: DependencyType | undefined,
        key: string | undefined,
        id: number
    ) {
        if (!!dependency && !!key) {
            let map = dependencyKeyMap[dependency];
            if (!map) {
                map = new Map();
                dependencyKeyMap[dependency] = map;
            }

            let ids = map.get(key);
            if (!ids) {
                ids = [];
                map.set(key, ids);
            }

            ids.push(id);
        }
    }

    function mapRemove(
        dependency: DependencyType | undefined,
        key: string | undefined,
        id: number
    ) {
        if (!!dependency && !!key) {
            const map = dependencyKeyMap[dependency];

            if (map) {
                const ids = map.get(key);
                if (ids) {
                    const keepIds = ids.filter(i => i !== id);
                    if (keepIds.length === 0) {
                        map.delete(key);
                    } else {
                        map.set(key, keepIds);
                    }
                }
            }
        }
    }

    return {
        add,
        remove,
        isBlockedByEarlierAction,
        getAllBlockingKeys,
        clear,
        update,
        getSubmitKeyActions,
    };
}

export type GetSubmitKeyActions = ReturnType<typeof createDependencyMap>['getSubmitKeyActions'];
