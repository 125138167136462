import { setTransactionSource } from 'owa-offline-database';
import type { TransactionSource, AppDatabase } from 'owa-offline-database';
import type { Table } from 'dexie';
import type { SyncModuleState } from 'owa-offline-sync-state-schema';

// Save sync module changes and sync state in a single transaction
export function saveChangesAndSyncState(
    database: AppDatabase,
    saveChanges: () => Promise<unknown>,
    syncState: SyncModuleState<any>,
    moduleTables: Array<Table>,
    transactionSource: TransactionSource
) {
    const tables = [database.syncState, ...moduleTables];
    return database.transaction('rw', tables, async transaction => {
        setTransactionSource(transaction, transactionSource);
        return Promise.all([database.syncState.put(syncState), saveChanges()]);
    });
}
