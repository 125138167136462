import { makePostRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import type { NotificationSubscriptionData } from '../schema/NotificationSubscription';
import { getRoutingHeaders } from '../HubConnection/utils/RequestHelpers';
import { ConnectionType } from '../schema/ConnectionType';

export async function subscribeToOnosNotification(
    connectionId: string,
    subscriptions: NotificationSubscriptionData[],
    mailboxInfo: MailboxInfo
) {
    return makeSubscriptionsCall(
        'OnosSubscribeToNotification',
        {
            ConnectionId: connectionId,
            Subscriptions: convertSubscriptionPayload(subscriptions),
        },
        mailboxInfo
    );
}

export async function unsubscribeFromOnosNotification(
    connectionId: string,
    subscriptions: NotificationSubscriptionData[],
    mailboxInfo: MailboxInfo
) {
    return makeSubscriptionsCall(
        'OnosUnsubscribeFromNotification',
        {
            ConnectionId: connectionId,
            UnSubscriptions: convertSubscriptionPayload(subscriptions),
        },
        mailboxInfo
    );
}

type SubscriptionPayload = {
    SubscriptionId: NotificationSubscriptionData['subscriptionId'];
    Parameters: NotificationSubscriptionData['subscriptionParameters'];
};

type SubscribePayload = {
    ConnectionId: string;
    Subscriptions: SubscriptionPayload[];
};

type UnsubscribePayload = {
    ConnectionId: string;
    UnSubscriptions: SubscriptionPayload[];
};

function convertSubscriptionPayload(
    subscriptions: NotificationSubscriptionData[]
): SubscriptionPayload[] {
    return subscriptions.map(subscription => ({
        SubscriptionId: subscription.subscriptionId,
        Parameters: subscription.subscriptionParameters,
    }));
}

const SUBSCRIBE_NOTIFICATION_URL = 'onos/v1.0/Subscriptions/syncnotifications';

function makeSubscriptionsCall(
    actionName: string,
    payload: SubscribePayload | UnsubscribePayload,
    mailboxInfo: MailboxInfo
) {
    return makePostRequest(
        SUBSCRIBE_NOTIFICATION_URL,
        payload,
        undefined /* correlationId */,
        false /* returnFullResponse */,
        getRoutingHeaders(ConnectionType.ONOSSignalR, mailboxInfo),
        undefined /* throwServiceError */,
        true /* sendPayloadAsBody */,
        undefined /* includeCredentials */,
        actionName /* actionName */,
        undefined /* datapoint */,
        mailboxInfo /* mailboxInfo */
    );
}
