import type { MailboxInfo } from 'owa-client-types';
import { lazyGetOfflineTimeZoneOffsets, lazyGetOfflineTimeZones } from 'owa-data-worker';

export function getOfflineTimeZones(mailboxInfo: MailboxInfo, databaseId: string) {
    return lazyGetOfflineTimeZones.importAndExecute(mailboxInfo, databaseId);
}

export function getOfflineTimeZoneOffsets(mailboxInfo: MailboxInfo, databaseId: string) {
    return lazyGetOfflineTimeZoneOffsets.importAndExecute(mailboxInfo, databaseId);
}
