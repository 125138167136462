import { registerAnalyticsAddon } from 'owa-analytics-addons';
import {
    captureAssetsOptics,
    setShouldCaptureAssets,
    captureServiceActionOptics,
    registerOwsCallbacks,
    registerCustomOwsCallbacks,
} from 'owa-analytics-addon-capture-optics';
import { toggleUnsampledLoggingAddon } from 'owa-analytics-addons-unsampled-data-toggle';
import { analyticsSettingsFlightsAndEcsSettingsAddon } from 'owa-analytics-addon-analytics-flights-and-ecs-settings';
import { trace } from 'owa-trace';

/**
 * Register all the analytics addons needed on the main thread
 */
export function registerAnalyticsDataWorkerAddons() {
    trace.info('[Addon] Registering Analytics Data Worker Addons', 'analytics');

    registerAnalyticsAddon(
        'AnalyticsFlightsAndEcsSettings',
        analyticsSettingsFlightsAndEcsSettingsAddon
    );

    registerAnalyticsAddon('UnsampledDataLogging', toggleUnsampledLoggingAddon);

    // Register OWS Callbacks
    registerAnalyticsAddon('CaptureAssetsOptics', captureAssetsOptics);
    registerAnalyticsAddon('SetShouldCaptureAssets', setShouldCaptureAssets);
    registerAnalyticsAddon('CaptureServiceActionOptics', captureServiceActionOptics);
    registerAnalyticsAddon('RegisterOwsCallbacks', registerOwsCallbacks);
    registerAnalyticsAddon('RegisterCustomOwsCallbacks', registerCustomOwsCallbacks);
}
