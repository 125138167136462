import type NotificationCallback from '../schema/NotificationCallback';
import type NotificationSubscription from '../schema/NotificationSubscription';
import {
    initialSubscriptionTrackerState,
    type SubscriptionStatus,
} from '../schema/SubscriptionTrackerState';
import { emitWarn } from '../utils/emitTrace';
import type {
    SubscriptionReference,
    SubscriptionState,
    SubscriptionTrackerState,
} from '../schema/SubscriptionTrackerState';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { ConnectionType } from '../schema/ConnectionType';

export function getSubscriptionIdStateKey(
    subscriptionId: string,
    connectionType: ConnectionType
): string {
    return subscriptionId + '_' + connectionType;
}

export abstract class MailboxBasedSubscriptionTracker {
    public static mailboxBasedSubscriptions: Map<string, SubscriptionTracker> = new Map<
        string,
        SubscriptionTracker
    >();

    public static getSubscriptions(
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ): NotificationSubscription[] {
        return MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).getSubscriptions(
            connectionType
        );
    }

    public static getSubscription(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationSubscription | undefined {
        return MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).getSubscription(
            subscriptionId,
            connectionType
        );
    }

    public static getHandlers(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationCallback[] {
        return MailboxBasedSubscriptionTracker.getRefs(
            mailboxInfo,
            subscriptionId,
            connectionType
        ).map(ref => ref.callback);
    }

    public static getRefs(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionReference[] {
        return MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).getRefs(
            subscriptionId,
            connectionType
        );
    }

    public static add(
        mailboxInfo: MailboxInfo,
        subscription: NotificationSubscription,
        callback: NotificationCallback,
        connectionType: ConnectionType
    ): void {
        MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).add(
            subscription,
            connectionType,
            callback
        );
    }

    public static remove(
        mailboxInfo: MailboxInfo,
        subscription: NotificationSubscription,
        callback: NotificationCallback,
        connectionType: ConnectionType
    ): void {
        MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).remove(
            subscription,
            connectionType,
            callback
        );
    }

    public static getSubscriptionState(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionState | undefined {
        return MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).getSubscriptionState(
            subscriptionId,
            connectionType
        );
    }

    public static hasSubscription(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): boolean {
        return MailboxBasedSubscriptionTracker.getTracker(mailboxInfo).hasSubscription(
            subscriptionId,
            connectionType
        );
    }

    public static createTracker(mailboxInfo: MailboxInfo): SubscriptionTracker {
        const tracker = new SubscriptionTracker();
        MailboxBasedSubscriptionTracker.mailboxBasedSubscriptions.set(
            getIndexerValueForMailboxInfo(mailboxInfo),
            tracker
        );
        return tracker;
    }

    public static getTracker(mailboxInfo: MailboxInfo): SubscriptionTracker {
        let tracker = MailboxBasedSubscriptionTracker.mailboxBasedSubscriptions.get(
            getIndexerValueForMailboxInfo(mailboxInfo)
        );
        if (!tracker) {
            tracker = MailboxBasedSubscriptionTracker.createTracker(mailboxInfo);
        }
        return tracker;
    }
}

export class SubscriptionTracker {
    public initialized: boolean;
    public state: SubscriptionTrackerState;

    public constructor() {
        this.state = initialSubscriptionTrackerState();
        this.initialized = true;
    }

    public getSubscriptions(connectionType: ConnectionType): NotificationSubscription[] {
        return Object.values<SubscriptionState>(this.state).reduce((arr, subState) => {
            if (subState.source === connectionType && subState.refs[0]) {
                arr.push(subState.refs[0].subscription);
            }
            return arr;
        }, [] as NotificationSubscription[]);
    }

    public getSubscription(
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationSubscription | undefined {
        return this.getRefs(subscriptionId, connectionType)[0]?.subscription;
    }

    public getHandlers(
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationCallback[] {
        return this.getRefs(subscriptionId, connectionType).map(ref => ref.callback);
    }

    public getRefs(
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionReference[] {
        return this.getSubscriptionState(subscriptionId, connectionType)?.refs ?? [];
    }

    public add(
        subscription: NotificationSubscription,
        connectionType: ConnectionType,
        callback: NotificationCallback
    ): void {
        const subscriptionIdStateKey = getSubscriptionIdStateKey(
            subscription.subscriptionId,
            connectionType
        );
        if (!this.hasSubscription(subscription.subscriptionId, connectionType)) {
            // First time we're seeing this subscription ID
            this.state[subscriptionIdStateKey] = {
                refs: [],
                status: 0,
                retries: 0,
                pendingRetryHandle: 0,
                source: connectionType,
            };
        }

        // Add this ref to the cache
        this.state[subscriptionIdStateKey].refs.push({
            subscription,
            callback,
        });
    }

    public remove(
        subscription: NotificationSubscription,
        connectionType: ConnectionType,
        callback: NotificationCallback
    ): void {
        if (!this.hasSubscription(subscription.subscriptionId, connectionType)) {
            // Called remove on an untracked subscription
            emitWarn(
                `Tried to remove ${subscription.subscriptionId} from the subscription tracker when it didn't exist`
            );

            return;
        }

        const subscriptionIdStateKey = getSubscriptionIdStateKey(
            subscription.subscriptionId,
            connectionType
        );

        const refs = this.state[subscriptionIdStateKey]?.refs;

        if (!refs) {
            // Called remove on an untracked subscription
            emitWarn(
                `Tried to remove ${subscription.subscriptionId} from the subscription tracker when it didn't exist`
            );

            return;
        }

        for (let i = 0; i < refs.length; i++) {
            if (refs[i].callback === callback) {
                refs.splice(i, 1);
                break;
            }
        }

        if (refs.length === 0) {
            // If there was a pending retry, cancel it
            if (this.state[subscriptionIdStateKey]?.pendingRetryHandle !== 0) {
                self.clearTimeout(this.state[subscriptionIdStateKey]?.pendingRetryHandle);
            }

            // That was the last callback for that id, stop tracking the subscription
            delete this.state[subscriptionIdStateKey];
        }
    }

    public getSubscriptionState(
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionState | undefined {
        return this.state[getSubscriptionIdStateKey(subscriptionId, connectionType)];
    }

    public hasSubscription(subscriptionId: string, connectionType: ConnectionType): boolean {
        return getSubscriptionIdStateKey(subscriptionId, connectionType) in this.state;
    }
}
