import {
    ClientIdPrefix,
    ParentFolderIdPrefix,
    DisplayNamePrefix,
    FavoriteTypePrefix,
} from 'owa-identifiers';

const favoritePrefix = `${ClientIdPrefix}${FavoriteTypePrefix}`;

export function createFolderId(parentFolderId: string, displayName: string) {
    return `${ClientIdPrefix}${ParentFolderIdPrefix}${parentFolderId}${DisplayNamePrefix}${displayName}`;
}

export function createFavoriteId(type: string, displayName: string) {
    return `${favoritePrefix}${type}${DisplayNamePrefix}${displayName}`;
}

export function isFavoriteId(id?: string | null): boolean {
    return id ? id.startsWith(favoritePrefix) : false;
}
