import type { Transaction } from 'dexie';
import type { Modifications, TableHooks } from 'owa-database-hooks';
import { getNewObjFromMods } from 'owa-database-hooks';
import type { MessageTableType } from './MessageTableType';
import isItemClassMeetingMessage from 'owa-meeting-message-utils/lib/utils/isItemClassMeetingMessage';

export function onMessageCreating(_id: string, message: MessageTableType, _trans: Transaction) {
    message.MetaData = {
        ...message.MetaData,
        isUnread: message.IsRead ? undefined : 1,
        isFlagged: message.Flag?.FlagStatus === 'Flagged' ? 1 : undefined,
        toOrCCMe: message.MessageToMe || message.MessageCcMe ? 1 : undefined,
        hasFiles: message.HasAttachments || message.HasProcessedSharepointLink ? 1 : undefined,
        mentioned: message.MentionedMe ? 1 : undefined,
        hasCalendarInvite: isItemClassMeetingMessage(message.ItemClass ?? '') ? 1 : undefined,
    };
}

export function onMessageUpdating(
    mods: Modifications,
    _id: string,
    oldMessage: MessageTableType,
    _trans: Transaction
) {
    const message = getNewObjFromMods(oldMessage, mods);

    const updates: Modifications = {};

    const isRead = message.IsRead ? undefined : 1;
    if (message.MetaData?.isUnread !== isRead) {
        updates['MetaData.isUnread'] = isRead;
    }

    const isFlagged = message.Flag?.FlagStatus === 'Flagged' ? 1 : undefined;
    if (message.MetaData?.isFlagged !== isFlagged) {
        updates['MetaData.isFlagged'] = isFlagged;
    }

    const toOrCCMe = message.MessageToMe || message.MessageCcMe ? 1 : undefined;
    if (message.MetaData?.toOrCCMe !== toOrCCMe) {
        updates['MetaData.toOrCCMe'] = toOrCCMe;
    }

    const hasFiles = message.HasAttachments || message.HasProcessedSharepointLink ? 1 : undefined;
    if (message.MetaData?.hasFiles !== hasFiles) {
        updates['MetaData.hasFiles'] = hasFiles;
    }

    const mentioned = message.MentionedMe ? 1 : undefined;
    if (message.MetaData?.mentioned !== mentioned) {
        updates['MetaData.mentioned'] = mentioned;
    }

    const hasCalendarInvite = isItemClassMeetingMessage(message.ItemClass ?? '') ? 1 : undefined;
    if (message.MetaData?.hasCalendarInvite !== hasCalendarInvite) {
        updates['MetaData.hasCalendarInvite'] = hasCalendarInvite;
    }

    return updates;
}

export const messagesHooks: TableHooks<MessageTableType, string> = {
    creatingHook: onMessageCreating,
    updatingHook: onMessageUpdating,
};
