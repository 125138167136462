let backfilledErrors: any[] = (<any>self).owaBackfilledErrors;
type ErrorCb = (
    message: string,
    file?: string,
    line?: number,
    col?: number,
    error?: any,
    stack?: string
) => void;
export function setErrorHandler(handler: ErrorCb) {
    for (var ii = 0; backfilledErrors && ii < backfilledErrors.length; ii++) {
        handler.apply(null, backfilledErrors[ii]);
    }
    (<any>self).owaErrorHandler = handler;
    backfilledErrors = [];
}
