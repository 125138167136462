import type { Table } from 'dexie';
import { compositeDexieKey } from 'owa-database-schema';
import type { DexieKey } from 'owa-database-schema';
export type TombstoneReasonType = any;

export type TombstoneTableType = {
    FolderId: string;
    // RowKey should be the unique key of the scenario that uses tombstones
    // e.g. itemId, conversationId, instanceKey etc.
    RowKey: string;
    ActionQueueId: string;
    TombstoneReason: TombstoneReasonType;
};

export const TOMBSTONE_TABLE_NAME = 'offlineTombstones';
export type TombstoneTable = Table<TombstoneTableType, [string, string, string]>;

export const TOMBSTONE_FOLDER_ID_INSTANCE_KEY_ACTION_QUEUE_ID =
    compositeDexieKey<TombstoneTableType>(['ActionQueueId', 'FolderId', 'RowKey']);

export const TOMBSTONE_ACTION_ID_KEY: DexieKey<TombstoneTableType> = 'ActionQueueId';
const TOMBSTONE_ROW_KEY: DexieKey<TombstoneTableType> = 'RowKey';

export const tombstoneSchema = {
    [TOMBSTONE_TABLE_NAME]: `${TOMBSTONE_FOLDER_ID_INSTANCE_KEY_ACTION_QUEUE_ID},${TOMBSTONE_ACTION_ID_KEY},${TOMBSTONE_ROW_KEY}`,
};
