import type NotificationSubscription from '../schema/NotificationSubscription';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { ConnectionType } from '../schema/ConnectionType';

type BatchState = {
    subscriptions: NotificationSubscription[];
    pendingSubmission: boolean;
};

export function getBatchKeyForMailbox(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    return getIndexerValueForMailboxInfo(mailboxInfo) + '_' + connectionType;
}

const batchStates: Map<string, BatchState> = new Map();

function getBatchState(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    const batchKey = getBatchKeyForMailbox(mailboxInfo, connectionType);
    let batchState = batchStates.get(batchKey);
    if (!batchState) {
        batchState = {
            subscriptions: [],
            pendingSubmission: false,
        };
        batchStates.set(batchKey, batchState);
    }

    return batchState;
}

export function getBatch(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    return getBatchState(mailboxInfo, connectionType).subscriptions;
}

export function addToBatch(
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    connectionType: ConnectionType
) {
    return getBatch(mailboxInfo, connectionType).push(subscription);
}

export function clearBatch(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    const batchState = getBatchState(mailboxInfo, connectionType);
    batchState.subscriptions = [];
}

export function setPending(
    mailboxInfo: MailboxInfo,
    connectionType: ConnectionType,
    pending: boolean
) {
    const batchState = getBatchState(mailboxInfo, connectionType);
    batchState.pendingSubmission = pending;
}

export function isPending(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    return getBatchState(mailboxInfo, connectionType).pendingSubmission;
}

export function clearStateForTests() {
    batchStates.clear();
}
