import type { AppDatabase } from 'owa-offline-database';
import type { TombstoneTableType } from 'owa-offline-tombstone-schema';
import { isRunningOnWorker } from 'owa-config';
import Dexie from 'dexie';

type TombstoneCache = {
    tombstonesPromise: Promise<TombstoneTableType[]>;
    data: TombstoneTableType[] | null;
};

const tombstonesCacheMap: Map<string, TombstoneCache> = new Map();
const subscribedToIdbChanges = new Set<string>();

function invalidateTombstonesCache(database: AppDatabase) {
    tombstonesCacheMap.delete(database.name);
}

export async function loadTombstones(database: AppDatabase) {
    return (await database.offlineTombstones.toArray()) || [];
}

async function loadAndCacheTombstones(database: AppDatabase) {
    const tombstonesPromise = loadTombstones(database);
    tombstonesCacheMap.set(database.name, {
        tombstonesPromise,
        data: null,
    });

    tombstonesPromise.then(data => {
        const cache = tombstonesCacheMap.get(database.name);
        if (cache?.tombstonesPromise === tombstonesPromise) {
            cache.data = data;
        }
    });

    return tombstonesPromise;
}

export async function loadTombstonesCached(database: AppDatabase): Promise<TombstoneTableType[]> {
    const cache = tombstonesCacheMap.get(database.name);

    if (cache) {
        if (cache.data) {
            return Promise.resolve(cache.data);
        }

        if (Dexie.currentTransaction) {
            // If we are inside a transaction, we can't wait on a promise from some other transaction.
            // So just load directly
            return loadTombstones(database);
        } else {
            return cache.tombstonesPromise;
        }
    }

    if (isRunningOnWorker() && !subscribedToIdbChanges.has(database.name)) {
        subscribedToIdbChanges.add(database.name);
        database.subscribeToIdbChanges(database.offlineTombstones, _change => {
            invalidateTombstonesCache(database);
        });
    }

    return loadAndCacheTombstones(database);
}
