import type { ApolloLink } from '@apollo/client';
import { onErrorLink } from '../links/onErrorLink';
import { ThrottleOperationLink } from '../links/ThrottleOperationLink';
import type { ResolverImports } from './createGraphServerLink';
import { createGraphServerLink } from './createGraphServerLink';
import { remoteGraphLink } from '../links/remoteGraphLink';

/**
 * This might be a little confusing.
 *
 * These links are used by local native/web resolvers that expect there to be a client on the graphql context (context.client).  There are only
 * a couple resolvers doing that, but we need to continue supporting it for them.
 *
 * That client will look a lot like the client initialized on the main thread, but we're skipping the link that proxies it over to the worker :-).
 * For now, also skipping the policy loader link because at the time of this, no resolver is using context.client w/ lazy policies.
 */
export function createResolverClientLinks(
    context: Record<string, any>,
    resolverImports: () => Promise<ResolverImports>
): Array<ApolloLink> {
    return [
        // Error link
        onErrorLink,
        // All operations marked throttled will execute one-by-one
        new ThrottleOperationLink(),
        createGraphServerLink(context, resolverImports, remoteGraphLink()),
    ];
}
