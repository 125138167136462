import { isFeatureEnabled } from 'owa-feature-flags';
import type { AppDatabase } from 'owa-offline-database';
import { loadSetting } from 'owa-offline-settings';
import type { OfflineOptions, SyncChoice } from 'owa-offline-sync-settings';
import { getDefaultOfflineSettings } from 'owa-offline-sync-settings';
import { OFFLINE_OPTION_SETTINGS } from './constants';

export async function loadOfflineSettingsFromDatabase(
    database: AppDatabase
): Promise<OfflineOptions> {
    const savedSettings = await loadSetting<OfflineOptions>(database, OFFLINE_OPTION_SETTINGS);
    //we need to update the folder sync settings here due to flight initialization
    const favs: SyncChoice = 'FAVS';
    const inbox: SyncChoice = 'INBOX';
    const defaultSettings = {
        ...getDefaultOfflineSettings(),
        folderToSync: isFeatureEnabled('fp-sync-offline-folders-default') ? favs : inbox,
    };
    return { ...defaultSettings, ...savedSettings };
}
