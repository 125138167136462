import type { DBCore } from 'dexie';
import { logUsage } from 'owa-analytics';

/**
 * DBCore middleware to workaround issue https://github.com/dexie/Dexie.js/issues/1734 in Dexie hooks.
 * We are somehow ending up with null objects in our tables. It's not clear how that happens, and may be due to a bug in Dexie.
 * Once an object is null it can't be updated due to a second issue in Dexie where the hooks middleware hits a null dereference.
 * The fix here is to notice when we are reading a null and instead return undefined as if the object does not exist.
 */
export function SuppressNullObjects(db: DBCore): DBCore {
    return {
        ...db,
        table(name: string) {
            const innerTable = db.table(name);
            return {
                ...innerTable,
                getMany: req => {
                    return innerTable.getMany(req).then(result =>
                        result.map((value, index) => {
                            if (value === null) {
                                reportNullObject(name, req.keys[index]);
                                return undefined;
                            }
                            return value;
                        })
                    );
                },
                get: req => {
                    return innerTable.get(req).then(value => {
                        if (value === null) {
                            reportNullObject(name, req.key);
                            return undefined;
                        }
                        return value;
                    });
                },
            };
        },
    };
}

const MAX_NULL_REPORTS = 10;
const nullsReported: Set<string> = new Set();

// Avoid sending telemetry on the same value over and over
function reportNullObject(table: string, id: any) {
    try {
        const idString = JSON.stringify(id);
        const tableWithId = `${table}:${idString}`;
        if (nullsReported.size < MAX_NULL_REPORTS && !nullsReported.has(tableWithId)) {
            nullsReported.add(tableWithId);
            logUsage('SuppressNullObjects', { table, id: idString });
        }
    } catch {}
}
