import type { LogData } from 'owa-logging-utils';
import { logUsage } from 'owa-analytics';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';

export function logComposeOfflineData(logData: LogData, shouldSyncLog: boolean = true) {
    logUsage('MailComposeOfflineAction', logData);
    if (shouldSyncLog) {
        emitSyncEvent('MailComposeOfflineAction', logData);
    }
}
