import type { WorkerHostObjectCb } from '../types/WorkerHostObjectCb';

let hostObjectCbInstance: WorkerHostObjectCb | undefined = undefined;

export function setWorkerHostObjectCb(hostObjectCb: WorkerHostObjectCb) {
    hostObjectCbInstance = hostObjectCb;
}

export function getWorkerHostObjectCb(): WorkerHostObjectCb | undefined {
    return hostObjectCbInstance;
}
