import Dexie, { type PromiseExtended } from 'dexie';
import { logUsage } from 'owa-analytics';
import { deleteDatabase } from 'owa-database-manager/lib/utils/deleteDatabase';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioning } from 'owa-database-utils/lib/enforceVersioning';
import { initializeTransactionMeasurement } from 'owa-database-utils/lib/initializeTransactionMeasurement';
import { TimeoutSlowOperations } from 'owa-database-utils/lib/TimeoutSlowOperations';
import { isFeatureEnabled } from 'owa-feature-flags';
import { syncLogsSchema, type SyncLogsTable } from 'owa-offline-sync-logging-schema';
import { workerLeaderSchema, type WorkerLeaderTable } from 'owa-offline-worker-leader-schema';
import { trace } from 'owa-trace';

const DATABASE_NAME = 'owa-offline-system-data';

export class SystemDatabase extends Dexie {
    public readonly workerLeader!: WorkerLeaderTable;
    public readonly syncLogs!: SyncLogsTable;

    constructor(name: string) {
        super(name, {
            chromeTransactionDurability: 'relaxed',
        });

        const store = {
            ...workerLeaderSchema,
        };
        this.version(1).stores(store);
        this.version(2).stores({ ...syncLogsSchema });

        addInfoToErrors(this);
        enforceVersioning(this);
        initializeTransactionMeasurement(this);

        if (isFeatureEnabled('fwk-idb-timeout3')) {
            this.use({
                stack: 'dbcore',
                name: TimeoutSlowOperations.name,
                create: TimeoutSlowOperations,
            });
        }
    }
}

let database: PromiseExtended<SystemDatabase> | null = null;
export function initializeSystemDatabase() {
    database = new SystemDatabase(DATABASE_NAME).open() as PromiseExtended<SystemDatabase>;
    return database;
}

export function getSystemDatabase(): Promise<SystemDatabase> {
    if (!database) {
        logUsage('SystemDatabaseIsNotInitializedYet');
        return initializeSystemDatabase();
    }

    return database;
}

export function deleteSystemDatabase(traceMessage: string): Promise<void> {
    trace.info(`System Database: ${traceMessage}`);
    return deleteDatabase(DATABASE_NAME);
}

export function doesSystemDatabaseExist(): Promise<boolean> {
    return Dexie.exists(DATABASE_NAME);
}
