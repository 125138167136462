import {
    type FetchResult,
    type Operation,
    type GraphQLRequest,
    ApolloLink,
    Observable,
} from '@apollo/client';
import { createOperation } from '@apollo/client/link/utils';
import type { QueuedActionContext } from '../types/QueuedActionContext';
import type { RemotePendingResult } from '../types/RemotePendingResult';
import { getOperationAST, type GraphQLError } from 'graphql';
import { trace, errorThatWillCauseAlertAndThrow } from 'owa-trace';
import { enqueue } from '../queue/actionQueue';
import { isQueueDbUnhealthy } from '../util/trackQueueHealth';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getIsOfflineDataFaulted } from 'owa-config';
import { getMailboxInfoFromOperation } from 'owa-request-options';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import type { ResolverContext } from 'owa-graph-schema';
import type { Subscription } from 'zen-observable-ts';
import { logGreyError } from 'owa-analytics';

// returned when the online part of a the resolution (local or remote)
// has been queued for online replay but not completed.
const remotePendingResult: RemotePendingResult = {
    extensions: { remotePending: true },
};

// dummy remote link for pending queued actions.  it hardcodes a response indicating the
// is in a pending/queued state.  typically, this isn't going
// to be observed by any code because in most cases the caller request is completed by an offline resolver
// committing local lies and returning a local result, but if there's isn't such a resolver, the caller
// will get this response as a fallback.
//
// if we're executing an offline action there's no enabled 'web' resolver, then the 'remote link'
// will be called directly by the localRemoteRouter just as if we were trying to execute against the gateway
//
// if we're executing an offline only action there's is enabled 'web' resolver, the offlineResolverSync code
// that merges the idb/web resolvers will see it's an offline only action and 'fallback' to the remote link
export function remotePendingLink() {
    const link = new ApolloLink(() => {
        return new Observable(observer => {
            observer?.next(remotePendingResult);
            observer?.complete();
        });
    });

    return link;
}

export function queuedActionLink() {
    const link = new ApolloLink((operation, forward) => {
        const opNode = getOperationAST(operation.query);
        const ctx = operation.getContext() as Partial<QueuedActionContext>;

        if (ctx.queuedAction) {
            // only expected queue directives on mutations
            if (opNode?.operation !== 'mutation') {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlertAndThrow(
                    `operation ${operation.operationName} attempted to queue non-mutation ${opNode?.operation}`
                );
            }

            const mailboxInfo = getMailboxInfoFromOperation(operation);
            const featureFlight = ctx.queuedAction.flight;
            const queueStatus = getIsOfflineDataFaulted()
                ? 'LeaderFaulted'
                : isQueueDbUnhealthy()
                ? 'QueueFaulted'
                : !isOfflineSyncEnabled(mailboxInfo)
                ? 'SyncDisabled'
                : featureFlight && !isFeatureEnabled(featureFlight)
                ? 'FlightDisabled'
                : !isFeatureEnabled('fwk-offline-mail') && !isFeatureEnabled('fwk-offline-calendar')
                ? 'OfflineNotEnabled'
                : 'Ready';

            if (queueStatus !== 'Ready') {
                // feature submitted a queued action, can't honor it.  ignore the queue directives.
                operation = disableQueueDirective(ctx, operation, queueStatus);
            }
        }

        const localSub = forward(operation);

        if (!isQueuable(operation)) {
            // if it's a non queued operation, just return the observable directly
            return localSub;
        } else {
            // if it's a queued operation, grab the local result, stuff it on the context, queue for online execution, and complete the outer sub
            const queuedActionContext: QueuedActionContext = ctx as QueuedActionContext;

            // but, if there's an idb error, then fallback immediately to the online resolver as a purely online operation (non-queued).  only do this
            // if the operation errors out directly or the error happens on the first call to next because otherwise the result would mingle
            // idb and web results
            let isFirstResult: boolean = true;
            let fallbackSubscription: Subscription | undefined = undefined;

            return new Observable(outer => {
                const sub = localSub.subscribe({
                    next: localResult => {
                        if (!fallbackSubscription) {
                            if (fallbackOnIdbError(localResult, isFirstResult)) {
                                operation = disableQueueDirective(
                                    ctx,
                                    operation,
                                    'IdbErrorFallback'
                                );
                                fallbackSubscription = forward(operation).subscribe(outer);
                            } else {
                                localResult = stampRemotePending(localResult);
                                queuedActionContext.queuedAction.localResult = localResult;
                                operation.setContext(queuedActionContext);
                                outer?.next(localResult);
                            }
                        }

                        isFirstResult = false;
                    },
                    error: localErr => {
                        if (!fallbackSubscription) {
                            if (fallbackOnIdbError({ errors: [localErr] }, isFirstResult)) {
                                operation = disableQueueDirective(
                                    ctx,
                                    operation,
                                    'IdbErrorFallback'
                                );
                                fallbackSubscription = forward(operation).subscribe(outer);
                            } else {
                                localErr = stampRemotePending(localErr);
                                queuedActionContext.queuedAction.localErr = localErr;
                                operation.setContext(queuedActionContext);
                                enqueue(operation, forward);
                                outer?.error(localErr);
                            }
                        }

                        isFirstResult = false;
                    },
                    complete: () => {
                        if (!fallbackSubscription) {
                            // if there was no fallback, then complete normally
                            enqueue(operation, forward);
                            outer?.complete();
                        } else {
                            // if there was a fallback, then that subscription owns the communication with the outer observer.
                            // in that case, the callback executing here is from the original request that faulted and its registration to outer
                            // is obsolete, so do nothing
                        }
                    },
                });

                return () => {
                    sub.unsubscribe();
                    fallbackSubscription?.unsubscribe();
                };
            });
        }
    });

    return link;
}

function disableQueueDirective(
    ctx: Partial<QueuedActionContext>,
    operation: Operation,
    reason:
        | 'SyncDisabled'
        | 'FlightDisabled'
        | 'LeaderFaulted'
        | 'QueueFaulted'
        | 'IdbErrorFallback'
        | 'OfflineNotEnabled'
) {
    const flightName = ctx?.queuedAction?.flight || 'undefined flight';
    const opName = operation.operationName;

    ctx.queuedAction = undefined;
    ctx.queueDisabledReason = reason;
    operation.setContext(ctx);

    var message = `Queue directives ignored: ${reason}`;
    trace.warn(message, 'actionQueue');
    emitSyncEvent(message, { flightName, opName, reason });

    const request: GraphQLRequest = {
        query: operation.query,
        variables: operation.variables,
        operationName: operation.operationName,
        extensions: operation.extensions,
    };

    return createOperation(ctx, request);
}

export function isQueuable(op: Operation): boolean {
    const ctx = op.getContext() as ResolverContext;
    const qaCtx = ctx as any as QueuedActionContext;
    return qaCtx?.queuedAction?.state == 'OfflineExecution' && ctx.resolverPolicy !== 'localOnly';
}

export function stampRemotePending(result: any): RemotePendingResult {
    if (result?.extensions) {
        result.extensions.remotePending = true;
    } else if (result) {
        result.extensions = { remotePending: true };
    } else {
        result = remotePendingResult;
    }

    if (result.errors) {
        result.errors.forEach(stampRemotePending);
    }

    return result;
}

function fallbackOnIdbError(
    result: FetchResult<Record<string, any>, Record<string, any>, Record<string, any>>,
    fallbackAllowed: boolean
): boolean {
    if (!fallbackAllowed) {
        // fallback not allowed, probably because we already started returning the local result
        return false;
    } else if (result.errors === undefined || result.errors.length === 0) {
        // no errors, no need to fallback
        return false;
    } else {
        const error: GraphQLError = result.errors[0];
        logGreyError('queuedActionIdbFallback', error);
        return true;
    }
}
