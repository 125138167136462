import { type HtmlToTextOptions, compile, type compiledFunction } from 'html-to-text';

const options: HtmlToTextOptions = {
    wordwrap: false,
    selectors: [
        { selector: 'a', options: { ignoreHref: true } },
        { selector: 'img', format: 'skip' },
        { selector: 'div[id*=Rply]', format: 'skip' }, // reply
        { selector: 'div[id*=Fwd]', format: 'skip' }, // forward
        { selector: 'hr', format: 'skip' }, // ------ break lines between messages
        { selector: 'div[id*=QuotedText]', format: 'skip' }, // quoted text
        { selector: 'div[id*=OriginalMessage]', format: 'skip' }, // original message
        { selector: 'meta', format: 'skip' }, // OWA reply
    ],
};

// Cached function to convert html to text
let compiledConvert: compiledFunction | null = null;

export function parseHtml(html: string): string {
    if (!compiledConvert) {
        compiledConvert = compile(options);
    }

    return compiledConvert(html);
}
