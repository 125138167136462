import type { HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';

export type BasicHeader = {
    key: string;
    value: string;
};

export function toBasicHeaders(headers: Headers | Record<string, string>): BasicHeader[] {
    const rv: BasicHeader[] = [];
    if (headers) {
        if (isHeaders(headers)) {
            for (const h of headers) {
                rv.push({ key: h[0], value: h[1] });
            }
        } else {
            Object.keys(headers).forEach(key => rv.push({ key, value: headers[key] }));
        }
    }

    return rv;
}

export function fromBasicHeaders(headers: BasicHeader[] | undefined): HeadersWithoutIterator {
    const rv = new Headers();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    headers?.forEach(h => {
        rv.set(h.key, h.value);
    });

    return rv;
}

function isHeaders(headers: Headers | Record<string, string>): headers is Headers {
    return !!headers.forEach;
}
