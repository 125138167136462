import type NotificationType from 'owa-service/lib/contract/NotificationType';
import { ConnectionType } from '../schema/ConnectionType';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo } from 'owa-client-types';

function isOnosSupportedNotificationType(
    notificationType: NotificationType,
    mailboxInfo: MailboxInfo
): boolean {
    return isFeatureEnabled('fwk-onossignalr', mailboxInfo)
        ? notificationType === 'NewMailNotification' ||
              notificationType === 'CalendarItemNotification'
        : false;
}

export function getSupportedConnectionTypesForNotification(
    mailboxInfo: MailboxInfo,
    notificationType?: NotificationType
): ConnectionType[] {
    const defaultSupportedConnectionTypes = [ConnectionType.OwaClassicSignalR];
    if (isFeatureEnabled('fwk-owanetcoreSignalr', mailboxInfo)) {
        defaultSupportedConnectionTypes.push(ConnectionType.OwaNetCoreSignalR);
    }
    if (notificationType) {
        if (isOnosSupportedNotificationType(notificationType, mailboxInfo)) {
            defaultSupportedConnectionTypes.push(ConnectionType.ONOSSignalR);
        }
    } else {
        logUsage('SignalRStats Undefined notificationType');
    }
    return defaultSupportedConnectionTypes;
}
