const QuotaExceededError = 'QuotaExceededError';
const InternalErrorOpeningBackingStore = 'Internal error opening backing store for indexedDB.open';
export function isQuotaExceededError(e: Error): boolean {
    // The web suggests the second error is likely due to lack of disk space.
    return (
        e.name == QuotaExceededError ||
        !!e.message?.includes(QuotaExceededError) ||
        !!e.message?.includes(InternalErrorOpeningBackingStore) ||
        !!e.stack?.includes(QuotaExceededError) ||
        !!e.stack?.includes(InternalErrorOpeningBackingStore)
    );
}
