import { logDatapoint } from 'owa-analytics';
import { ServiceActionDatapoint } from 'owa-analytics/lib/datapoints/ServiceActionDatapoint';
import type { CalculatedResourceTimings } from 'owa-analytics-types';

let shouldCaptureAssets: boolean = false;

export function captureAssetsOptics(timings: (CalculatedResourceTimings | undefined)[]) {
    if (shouldCaptureAssets) {
        for (const timing of timings) {
            if (timing && (timing.name.endsWith('.js') || timing.name.endsWith('.json'))) {
                const nameParts = timing.name.split('/');
                const resourceName = nameParts[nameParts.length - 1];
                const datapoint = new ServiceActionDatapoint(resourceName);
                datapoint.addResourceTimings('Asset', timing);
                logDatapoint(datapoint, 'client_network_request');
            }
        }
    }
}

export function setShouldCaptureAssets(value: boolean) {
    shouldCaptureAssets = value;
}
