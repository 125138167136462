import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { MailboxInfo } from 'owa-client-types';

export const undefinedSymbol = Symbol();

export enum ServiceConfigCacheBehavior {
    cacheOnly,
    cacheAndProxy,
}

export type ServiceConfigDataCacheKey = {
    mailboxInfo: MailboxInfo;
    resource?: string;
    scope?: string;
};

export type ServiceConfigData = {
    authToken?: string | typeof undefinedSymbol;
    resourceToken?: string | typeof undefinedSymbol;
    userToken?: string | typeof undefinedSymbol;
    msalToken?: TokenResponse | typeof undefinedSymbol;
    anchorMailbox?: string | typeof undefinedSymbol;
    explicitLogon?: string | typeof undefinedSymbol;
    webSessionType?: string | typeof undefinedSymbol;
    mailboxRequestType?: string | typeof undefinedSymbol;
};

const userIdData: Map<string, ServiceConfigData> = new Map();

export function updateServiceConfigData<T extends ServiceConfigData>(
    cacheKey: ServiceConfigDataCacheKey,
    data: T,
    updates: (keyof T)[]
) {
    const indexer = getIndexer(cacheKey);
    const cachedData = { ...userIdData.get(indexer), ...data };
    userIdData.set(indexer, cachedData);

    // store undefined values as a symbol, so that we can distinguish a value computed to be undefined
    // vs a value that hasn't been computed/cached yet
    for (const k of updates) {
        if (cachedData[k] === undefined) {
            cachedData[k] = <any>undefinedSymbol;
        }
    }
}

export function getServiceConfigData(cacheKey: ServiceConfigDataCacheKey) {
    const indexer = getIndexer(cacheKey);
    return userIdData.get(indexer);
}

export function clearServiceConfigData() {
    userIdData.clear();
}

function getIndexer(cacheKey: ServiceConfigDataCacheKey): string {
    const { userIdentity, mailboxSmtpAddress, type } = cacheKey.mailboxInfo;
    let indexer: string = `${userIdentity ?? ''}_${mailboxSmtpAddress ?? ''}_${type ?? ''}`;

    if (cacheKey.resource) {
        indexer = indexer + '|' + cacheKey.resource;
    }

    if (cacheKey.scope) {
        indexer = indexer + '|' + cacheKey.scope;
    }

    return indexer;
}
