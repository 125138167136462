import Dexie, { type EntityTable, type PromiseExtended } from 'dexie';
import { deleteDatabase } from 'owa-database-manager/lib/utils/deleteDatabase';
import { addInfoToErrors } from 'owa-database-utils/lib/addInfoToErrors';
import { enforceVersioning } from 'owa-database-utils/lib/enforceVersioning';
import { initializeTransactionMeasurement } from 'owa-database-utils/lib/initializeTransactionMeasurement';
import { TimeoutSlowOperations } from 'owa-database-utils/lib/TimeoutSlowOperations';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { QueuedAction } from '../types/QueuedAction';

// id field of the queued action
export const QueuedActionId: keyof QueuedAction = 'id';

const DATABASE_NAME = 'owa-offline-actions';

// The queued action db must have a flat, 100% backwards compatible schema separate from the main db.
class ActionQueueDb extends Dexie {
    actions!: EntityTable<QueuedAction, 'id'>;
    constructor() {
        super(DATABASE_NAME);
        this.version(1).stores({
            actions: `++${QueuedActionId}`,
        });

        addInfoToErrors(this);
        enforceVersioning(this);
        initializeTransactionMeasurement(this);

        if (isFeatureEnabled('fwk-idb-timeout3')) {
            this.use({
                stack: 'dbcore',
                name: TimeoutSlowOperations.name,
                create: TimeoutSlowOperations,
            });
        }
    }
}

let db: PromiseExtended<ActionQueueDb>;

export function getQueuedActionDb(): Promise<ActionQueueDb> {
    if (!db) {
        const database = new ActionQueueDb();
        db = database.open() as PromiseExtended<ActionQueueDb>;
    }

    return db;
}

export function deleteQueuedActionDb() {
    return deleteDatabase(DATABASE_NAME);
}
