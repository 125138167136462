import { getItem, setItem } from 'owa-local-storage';
import type { OfflineSettingDisabledReason } from 'owa-offline-sync-feature-flags';
import { logUsage } from 'owa-analytics';

import { OFFLINE_DISABLED_REASON_KEY } from './constants';

// Track the reason we are disabling offline (e.g. out of disk space) so we can
// communicate the reason to the user.
export function setOfflineDisabledReason(reason: OfflineSettingDisabledReason) {
    const previous = getOfflineDisabledReason();
    if (previous !== reason) {
        logUsage('offlineDisabledReason', { reason });
        setItem(self, OFFLINE_DISABLED_REASON_KEY, reason);
    }
}

export function getOfflineDisabledReason(): OfflineSettingDisabledReason | undefined {
    const reason = getItem(self, OFFLINE_DISABLED_REASON_KEY);
    return reason ? (reason as OfflineSettingDisabledReason) : undefined;
}
