import type { MailFolder, FolderHierarchyResult } from 'owa-graph-schema';

import {
    type GroupFolderWellKnownName,
    PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { tryGetDistinguishedFolder } from './tryGetDistinguishedFolder';
import type { AppDatabase } from 'owa-offline-database';
import { FOLDERS_SORT_INDEX_KEY } from 'owa-offline-folders-schema';

export async function tryGetOfflineFolders(
    database: AppDatabase,
    shouldShowNotesFolder = false,
    shouldShowConversationHistoryFolder = false,
    shouldShowOutboxFolder = false
): Promise<MailFolder[]> {
    const folders = await database.folders.orderBy(FOLDERS_SORT_INDEX_KEY).toArray();

    if (!folders.length) {
        return [];
    }

    return folders.filter(folder =>
        shouldAddFolderToResult(
            folder,
            shouldShowNotesFolder,
            shouldShowConversationHistoryFolder,
            shouldShowOutboxFolder
        )
    );
}

export async function tryGetOfflineFolderHierarchy(
    database: AppDatabase,
    shouldShowNotesFolder = true,
    shouldShowConversationHistoryFolder = true,
    shouldShowOutboxFolder = false
): Promise<FolderHierarchyResult | undefined> {
    const [folders, rootFolder] = await Promise.all([
        tryGetOfflineFolders(
            database,
            shouldShowNotesFolder,
            shouldShowConversationHistoryFolder,
            shouldShowOutboxFolder
        ),
        tryGetDistinguishedFolder(database, PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID),
    ]);

    if (!rootFolder) {
        return undefined;
    }

    return {
        __typename: 'FolderHierarchyResult',
        Folders: folders,
        RootFolder: rootFolder,
        offset: folders.length,
        TotalItemsInView: folders.length,
        IncludesLastItemInRange: true,
    };
}

function shouldAddFolderToResult(
    folder: MailFolder,
    shouldShowNotesFolder: boolean,
    shouldShowConversationHistoryFolder: boolean,
    shouldShowOutboxFolder: boolean
): boolean {
    // Always remove root folder from the list
    if (folder.distinguishedFolderType === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID) {
        return false;
    }

    if (folder.distinguishedFolderType === 'notes') {
        return !!shouldShowNotesFolder;
    }

    if (folder.distinguishedFolderType === 'conversationhistory') {
        return !!shouldShowConversationHistoryFolder;
    }

    if (folder.distinguishedFolderType === 'outbox') {
        return !!shouldShowOutboxFolder;
    }

    return true;
}
