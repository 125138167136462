export class SyncModuleRunStatus {
    public code: number;
    public time: number;

    constructor() {
        this.code = 1;
        this.time = Date.now();
    }

    progress() {
        this.code += 1;
        this.time = Date.now();
    }
}
