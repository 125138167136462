import type { BaseRequestOptions } from 'owa-analytics-types/lib/types/BaseRequestOptions';
import { logDatapoint } from 'owa-analytics';
import { ServiceActionDatapoint } from 'owa-analytics/lib/datapoints/ServiceActionDatapoint';
import { lazyGetResourceTimingForUrl } from 'owa-analytics/lib/lazyFunctions';
import { checkMailboxInfoForDiagnosticData } from 'owa-service/lib/utils/checkMailboxInfoForDiagnosticData';
import sleep from 'owa-sleep';
import { isMsalFlowEnabled } from 'owa-msaljs/lib/isMsalFlowEnabled';

export default function captureServiceActionOptics(
    responsePromise: Promise<Response>,
    actionName: string,
    url: string,
    attemptCount: number,
    optionsPromise: Promise<BaseRequestOptions>
) {
    const datapoint = new ServiceActionDatapoint(actionName, attemptCount);

    const callerStack = new Error('Caller Stack').stack;
    return Promise.all([optionsPromise, responsePromise])
        .then(([options, response]) => {
            checkMailboxInfoForDiagnosticData(options?.mailboxInfo, callerStack);
            datapoint.addResponseDiagnostics(response, options);
            if (options?.datapoint) {
                if (options.datapoint.headersCustomData && response) {
                    datapoint.addCustomData(options.datapoint.headersCustomData(response.headers));
                }
                if (
                    (options.datapoint.jsonCustomData || options?.datapoint?.isGraphRequest) &&
                    response.ok
                ) {
                    return response
                        .clone()
                        .json()
                        .catch(e => {
                            datapoint.addCustomData({ jcde: `${e}` });
                        })
                        .then(json => {
                            if (options?.datapoint?.jsonCustomData) {
                                datapoint.addCustomData(options.datapoint.jsonCustomData(json));
                            }

                            if (options?.datapoint?.isGraphRequest) {
                                // batchLink returns an array while httpLink returns an object
                                const extensions =
                                    (json as any)?.[0]?.extensions || (json as any)?.extensions;
                                const useOwsFallBack =
                                    extensions['GraphBridge-FallbackService'] != null;

                                // 1st entry is the total absolute latency for the given microservice(s).
                                const backendTotalLatencyObj = Object.entries(
                                    extensions?.['X-BackendServiceLatencies'] || {}
                                )[0];

                                // X-Total-Latency: Gateway's perceived latency of the microservice call.
                                // Gw: Gateway B2 service
                                // Ms: B2 microservice
                                // Ows: Classic Ows service (used in fallback)
                                // In regular path, the request will flow to Gateway--> Microservice
                                // In the fallback path, the request will flow to Gateway-->Microservice-->Gateway-->Ows
                                const backendTotalLatency =
                                    backendTotalLatencyObj?.['X-Total-Latency'];

                                // X-BackendLatency: microservice self-measured latency.
                                const backendMicroserviceLatency =
                                    backendTotalLatencyObj?.['X-BackendLatency'];

                                const gatewayLatency = response.headers.get('X-Gateway-Latency');

                                const backendOwsProxyDuration = useOwsFallBack
                                    ? extensions?.['X-BackendServiceLatencies']?.[
                                          'OwsProxyService'
                                      ]?.['X-Total-Latency']
                                    : undefined;

                                datapoint.addCustomData({
                                    gwUseOwsCallBack: useOwsFallBack ? '1' : '0',
                                });
                                datapoint.addCustomData({
                                    gwServiceLatencyBreak: JSON.stringify({
                                        GL: gatewayLatency,
                                        TL: backendTotalLatency,
                                        BL: backendMicroserviceLatency,
                                        OwsTL: backendOwsProxyDuration,
                                    }),
                                });
                            }
                        });
                }
            }

            const isUserIdentitySet = options?.datapoint?.mailbox != undefined;
            datapoint.addCustomData({ isUserIdentitySet, isMSALEnabled: isMsalFlowEnabled() });

            return Promise.resolve();
        })
        .catch(error => {
            datapoint.addErrorDiagnostics(error);
        })
        .then(() => {
            return Promise.race([sleep(3000), lazyGetResourceTimingForUrl.importAndExecute(url)]);
        })
        .then(timing => {
            if (timing) {
                datapoint.addResourceTimings('ServiceAction', timing);
            }
            logDatapoint(datapoint, 'client_network_request');
        });
}
