import { setItem } from 'owa-local-storage';

import {
    OFFLINE_ENABLED_KEY,
    type OfflineSettingDisabledReason,
} from 'owa-offline-sync-feature-flags';
import { setOfflineDisabledReason } from './setOfflineDisabledReason';

export function toggleOffline(isOfflineEnabled: boolean, reason: OfflineSettingDisabledReason) {
    setItem(self, OFFLINE_ENABLED_KEY, isOfflineEnabled ? 'true' : 'false');
    setOfflineDisabledReason(reason);
}
