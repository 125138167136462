import { isCoprincipalGlobalSettingsMailbox } from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import { setLocalTimeZone } from 'owa-datetime-store';
import { updateServiceConfig } from 'owa-service/lib/config';
import { updateAccountScopeUserSettings } from 'owa-session-store';

export function setAccountTimeZone(newTimeZone: string | undefined, mailboxInfo: MailboxInfo) {
    if (isCoprincipalGlobalSettingsMailbox(mailboxInfo)) {
        updateServiceConfig({ timezone: newTimeZone });
        setLocalTimeZone(newTimeZone);
    }
    updateAccountScopeUserSettings(mailboxInfo, userConfig => {
        if (userConfig.UserOptions) {
            userConfig.UserOptions.TimeZone = newTimeZone;
        }
    });
}
