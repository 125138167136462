import { type GetSubmitKeyActions } from '../queue/dependencyMap';
import {
    type QueuedActionSubmitProcessor,
    type RestrictedQueuedAction,
} from '../types/QueuedActionSubmitProcessor';

export const replaceProcessor: QueuedActionSubmitProcessor = (
    action: RestrictedQueuedAction,
    _queue: ReadonlyArray<RestrictedQueuedAction>,
    getDeps: GetSubmitKeyActions,
    _isRunning: (id: number) => boolean
) => {
    // this action replaces all actions with the same replace key that have smaller ids (were submitted earlier)
    const replaceableIds = getDeps(action)?.filter(id => id < action.id);
    return replaceableIds.map(id => ({ kind: 'Delete', id }));
};
