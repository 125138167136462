import type { MailboxInfo } from 'owa-client-types';
import type { ClientCalendarEvent, DateRange } from 'owa-graph-schema';
import { lazyApplyCalendarEventsSyncLieForRange } from 'owa-offline-sync-manager';

export const applyCalendarEventsSyncLieForRange = (
    mailboxInfo: MailboxInfo,
    events: ClientCalendarEvent[],
    dateRange: DateRange,
    userTimeZone: string,
    folderId: string,
    source: string,
    syncLieInvokationTime?: number
) =>
    lazyApplyCalendarEventsSyncLieForRange.importAndExecute(
        mailboxInfo,
        events,
        dateRange,
        userTimeZone,
        folderId,
        source,
        syncLieInvokationTime
    );
