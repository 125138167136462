import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { type QueuedAction } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import type { AppDatabase } from 'owa-offline-database';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import { type ClientIdItemContext } from '../types/ClientIdItemContext';
import { extractRealIdFromClientId } from '../util/clientItemConversationUtils';
import type ItemId from 'owa-service/lib/contract/ItemId';
import type { DeleteItemIdResult } from 'owa-graph-schema';

async function DeleteMessagesIDB(dataBase: AppDatabase, itemIds: string[]) {
    await dataBase.transaction(
        'rw',
        dataBase.messages,
        dataBase.messageBodies,
        async transaction => {
            // Delete the messages that got created on the folder
            // Since this is not visible Notification and Sync will have the data when online
            setTransactionSource(transaction, 'localLie');
            await dataBase.messages.bulkDelete(itemIds);
            await dataBase.messageBodies.bulkDelete(itemIds);
            emitSyncEvent('copyMoveItemOrConversationResultProcessor:DeletedMessagesIds', {
                ids: itemIds.join(';'),
            });
        }
    );
}

async function DeleteConversationsIDB(
    dataBase: AppDatabase,
    folderIdToConversationIdMap: {
        [key: string]: string[];
    }
) {
    const folderIdKeys = Object.keys(folderIdToConversationIdMap);
    if (folderIdKeys.length == 0) {
        return;
    }

    const destinationFolderConversationKeys: [string, string][] = [];
    for (let i = 0; i < folderIdKeys.length; i++) {
        const folderId = folderIdKeys[i];
        for (let j = 0; j < folderIdToConversationIdMap[folderId].length; j++) {
            destinationFolderConversationKeys.push([
                folderId,
                folderIdToConversationIdMap[folderId][j],
            ]);
        }
    }

    await dataBase.transaction('rw', dataBase.conversations, async transaction => {
        // Delete the conversations that got created on the folder
        // Since this is not visible Notification and Sync will have the data when online
        setTransactionSource(transaction, 'localLie');
        await dataBase.conversations.bulkDelete(destinationFolderConversationKeys);
        emitSyncEvent('copyMoveItemOrConversationResultProcessor:DeletedConversationIds', {
            folderIdsConversationIds: destinationFolderConversationKeys.join(';'),
        });
    });
}

export async function copyMoveItemOrConversationResultProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;
    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        const mailboxInfo = action.operation.variables?.mailboxInfo;
        const database = await getDatabase(mailboxInfo);
        let inputItemIds: string[] = [];
        let serverReturnedIds: string[] = [];
        const clientTemporaryIds: string[] = (
            action.operation.context as any as ClientIdItemContext
        ).temporaryClientIds;

        if (!clientTemporaryIds || clientTemporaryIds.length == 0) {
            return Promise.resolve({
                fetchResult: result.fetchResult,
                fetchError: result.fetchError,
                idChanges: new Map(),
            });
        }

        if (action.opName == 'CopyConversation') {
            serverReturnedIds = (
                result.fetchResult?.data?.copyConversation?.copiedItemIds as ItemId[]
            ).map(itemId => itemId.Id);
        }

        if (action.opName === 'CopyItem') {
            serverReturnedIds = (
                result.fetchResult?.data?.copyItem?.copiedItemIdsResults?.[0]?.itemIds as ItemId[]
            ).map(itemId => itemId.Id);
        }

        if (action.opName === 'ScheduleItem') {
            inputItemIds = action.operation.variables?.itemIds;
            serverReturnedIds = result.fetchResult?.data?.scheduleItem?.scheduledItemsIds;
        }

        // Once ImmutableIds flight is 100%, no need to read input or serverReturnedIds,
        // since the immutableId can be extracted from clientTemporaryIds
        if (action.opName === 'MoveItem' || action.opName === 'UndoMoveItem') {
            inputItemIds = (action.operation.variables?.itemIds as ItemId[]).map(
                itemIds => itemIds.Id
            );

            // UndoMoveItem does not return moved ids, so use the ones from input
            serverReturnedIds =
                action.opName === 'MoveItem'
                    ? (
                          result.fetchResult?.data?.moveItem?.movedItemIdsResults?.[0]
                              ?.itemIds as ItemId[]
                      ).map(itemId => itemId.Id)
                    : [...inputItemIds];
        }

        if (action.opName === 'DeleteItem' || action.opName === 'UndoDeleteItem') {
            inputItemIds = (action.operation.variables?.itemIds as ItemId[]).map(
                itemIds => itemIds.Id
            );

            // UndoDeleteItem does not return moved ids, so use the ones from input
            serverReturnedIds =
                action.opName === 'DeleteItem'
                    ? ((
                          result.fetchResult?.data?.deleteItem
                              ?.deleteItemIdsResults as DeleteItemIdResult[]
                      )
                          .map(deleteItemResult => deleteItemResult.itemId?.Id)
                          .filter(id => id) as string[])
                    : [...inputItemIds];
        }

        if (action.opName === 'MarkItemAsPhishing') {
            serverReturnedIds = result.fetchResult?.data?.markItemAsPhishing?.movedItemIds;
        }

        if (action.opName === 'MarkItemAsJunk' || action.opName === 'UndoMarkItemAsJunk') {
            inputItemIds = action.operation.variables?.ids;
            serverReturnedIds =
                action.opName === 'MarkItemAsJunk'
                    ? result.fetchResult?.data?.markItemAsJunk?.movedItemIds
                    : result.fetchResult?.data?.undoMarkItemAsJunk?.movedItemIds;
        }

        // Calculate all the temporary ids independent of the resolver
        // this way we dont have to check input or other params of a specific resolver
        // in order to find if these ids are either conversation or item ids
        const folderIdToConversationMap: {
            [key: string]: string[];
        } = {};
        const temporaryItemIds: string[] = [];
        const idChanges: Map<string, string> = new Map();
        for (let i = 0; i < clientTemporaryIds.length; i++) {
            const clientTemporaryId = clientTemporaryIds[i];
            const extractedRealIdResult = extractRealIdFromClientId(clientTemporaryId);
            if (!extractedRealIdResult) {
                continue;
            }

            if (extractedRealIdResult.isConversation) {
                // Conversations are not returned from the server fetchResult and they are immutable
                // So, just extract from the temporary id. Creating a temporary id here to avoid any kind of
                // race condition and we don't endup deleting real server data when calling DeleteConversationsIDB
                idChanges.set(clientTemporaryId, extractedRealIdResult.extractedId);
                if (extractedRealIdResult.parentFolderId) {
                    if (folderIdToConversationMap[extractedRealIdResult.parentFolderId]) {
                        folderIdToConversationMap[extractedRealIdResult.parentFolderId].push(
                            clientTemporaryId
                        );
                    } else {
                        folderIdToConversationMap[extractedRealIdResult.parentFolderId] = [
                            clientTemporaryId,
                        ];
                    }
                }
            } else {
                // For item, we have to extract the newly created value to find
                // its position on the input array, which is the same position on the
                // response array from the server
                temporaryItemIds.push(clientTemporaryId);

                // For copy conversation, the input ids are not the item ids we need to map
                // So the scenario is handled differently
                const idIndex =
                    action.opName === 'CopyConversation'
                        ? i
                        : inputItemIds.indexOf(extractedRealIdResult.extractedId);

                // If server had a problem, just fallback to the input item id
                // which will be fine after ImmutableId flight is 100% WW
                idChanges.set(
                    clientTemporaryId,
                    serverReturnedIds?.[idIndex] ?? extractedRealIdResult.extractedId
                );
            }
        }

        if (Object.keys(folderIdToConversationMap).length > 0) {
            await DeleteConversationsIDB(database, folderIdToConversationMap);
        }

        // Delete the offline messages that were created for the copied or moved items
        if (temporaryItemIds.length > 0) {
            await DeleteMessagesIDB(database, clientTemporaryIds);
        }

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges,
        };
    }

    return Promise.resolve(rv);
}
