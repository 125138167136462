import type { MailboxInfo } from 'owa-client-types';
import type { ConversationType, ItemRow } from 'owa-graph-schema';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';

import { lazyOnTableReloaded } from 'owa-offline-sync-manager';

export const onTableReloaded = (
    mailboxInfo: MailboxInfo,
    folderId: string,
    reloadedRows: ConversationType[] | ItemRow[],
    viewType: ReactListViewType,
    focusedViewFilter: FocusedViewFilter,
    viewFilter: ViewFilter
) =>
    lazyOnTableReloaded.importAndExecute(
        mailboxInfo,
        folderId,
        reloadedRows,
        viewType,
        focusedViewFilter,
        viewFilter
    );
