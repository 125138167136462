import {
    type QueuedActionSubmitProcessor,
    type RestrictedQueuedAction,
    type ProcessSubmitResult,
} from '../types/QueuedActionSubmitProcessor';
import { type GetSubmitKeyActions } from '../queue/dependencyMap';
import { assertNever } from 'owa-assert';
import { replaceProcessor } from './replaceProcessor';
import { cancelProcessor } from './cancelProcessor';

export const processSubmit: QueuedActionSubmitProcessor = (
    action: RestrictedQueuedAction,
    queue: ReadonlyArray<RestrictedQueuedAction>,
    getDeps: GetSubmitKeyActions,
    isRunning: (id: number) => boolean
) => {
    let rv: ProcessSubmitResult = [];
    const processor = action.submitProcessor?.name;

    if (processor) {
        switch (processor) {
            case 'Cancel':
                rv = cancelProcessor(action, queue, getDeps, isRunning);
                break;
            case 'Replace':
                rv = replaceProcessor(action, queue, getDeps, isRunning);
                break;
            default:
                assertNever(processor);
        }
    }

    return rv;
};
