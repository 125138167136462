import type { DBCore } from 'dexie';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { timeout } from 'owa-sleep/lib/timeout';

const IDB_OPERATION_TIMEOUT = 5 * 60 * 1000; // 5 minutes
const TIMEOUT_ERROR_MESSAGE = 'Idb operation timed out';

function wrapOperationWithTimeout<Request, Result>(
    operation: (req: Request) => Promise<Result>,
    dbName: string
) {
    return (req: Request) => {
        if (!isFeatureEnabled('fwk-idb-timeout3', undefined, true)) {
            return operation(req);
        }
        return timeout(operation(req), IDB_OPERATION_TIMEOUT, TIMEOUT_ERROR_MESSAGE).catch(
            error => {
                if (error.message === TIMEOUT_ERROR_MESSAGE) {
                    logUsage('IdbOperationTimeout', { operation: operation.name, dbName });
                }
                throw error;
            }
        );
    };
}

/**
 * We are seeing the app sometimes get into a state where idb operations never complete.
 * We protect against this by adding a long timeout. The telemetry added here will also help us
 * track how widespread the problem is.
 */
export function TimeoutSlowOperations(db: DBCore): DBCore {
    const dbName = db.schema.name;
    return {
        ...db,
        table(name: string) {
            const innerTable = db.table(name);
            return {
                ...innerTable,
                mutate: wrapOperationWithTimeout(innerTable.mutate, dbName),
                getMany: wrapOperationWithTimeout(innerTable.getMany, dbName),
                get: wrapOperationWithTimeout(innerTable.get, dbName),
                query: wrapOperationWithTimeout(innerTable.query, dbName),
                openCursor: wrapOperationWithTimeout(innerTable.openCursor, dbName),
                count: wrapOperationWithTimeout(innerTable.count, dbName),
            };
        },
    };
}
