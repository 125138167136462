import { SyncModule } from './SyncModule';
import type { MailboxInfo } from 'owa-client-types';

export const CLEANUP_INTERVAL_MS = 24 * 60 * 60 * 1000; // 1 day

// Provides a simplified SyncModule to carry out database cleanup tasks
export abstract class CleanupModule extends SyncModule<void, void> {
    protected getEmptySyncState = () => {};
    protected syncChangesFromServer = () => Promise.resolve();
    protected getSyncStateFromResponse = () => {};

    protected abstract isCleanupComplete: () => boolean;

    private shouldRun = true;

    constructor(mailboxInfo: MailboxInfo) {
        super(mailboxInfo);
        this.isCleanupModule = true;
    }

    public async initialize() {
        await super.initialize();
        this.shouldRun = this.lastSuccessfulSyncTime + CLEANUP_INTERVAL_MS < Date.now();
    }

    public shouldLogPerformanceDatapoint() {
        return this.shouldRun;
    }

    public isSyncComplete = () => !this.shouldRun || this.isCleanupComplete();
}
