import type { MailFolder } from 'owa-graph-schema';
import { FOLDERS_DISTINGUISHED_ID_KEY } from 'owa-offline-folders-schema';
import type { AppDatabase } from 'owa-offline-database';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';

export function tryGetDistinguishedFolder(
    database: AppDatabase,
    distinguishedFolderId: DistinguishedFolderIdName
): Promise<MailFolder | undefined> {
    return database.folders
        .where(FOLDERS_DISTINGUISHED_ID_KEY)
        .equals(distinguishedFolderId)
        .first();
}

export async function tryGetDistinguishedFolderId(
    database: AppDatabase,
    distinguishedFolderId: DistinguishedFolderIdName
): Promise<string | undefined> {
    const ids = await database.folders
        .where(FOLDERS_DISTINGUISHED_ID_KEY)
        .equals(distinguishedFolderId)
        .primaryKeys();

    return ids[0];
}
