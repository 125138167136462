import { addSyncEventListener } from 'owa-offline-sync-diagnostics';
import type { SyncEvent, SyncEventType, SyncEventsListener } from 'owa-offline-sync-diagnostics';

const NO_TIMER = -1;
const FLUSH_DELAY = 1000; // 1 second

let pendingSyncEvents: SyncEvent[] = [];
let flushTimer = NO_TIMER;

export function intializeSyncEventsListener(syncEventsListener: SyncEventsListener) {
    // To reduce chattiness across the thread boundary we gather events and send them once in awhile
    const flushEvents = () => {
        syncEventsListener(getEventsToSend());
        pendingSyncEvents = [];
        flushTimer = NO_TIMER;
    };

    const listener = (type: SyncEventType, ...data: any[]) => {
        pendingSyncEvents.push({ type, data: JSON.parse(JSON.stringify(data)) });
        if (flushTimer === NO_TIMER) {
            flushTimer = self.setTimeout(flushEvents, FLUSH_DELAY);
        }
    };

    addSyncEventListener(listener);
}

// Sending too many sync events can choke the diagnostics UI, plus it wouldn't show them all anyway.
const getEventsToSend = () => {
    if (pendingSyncEvents.length < 500) {
        return pendingSyncEvents;
    }

    const eventsToSend: SyncEvent[] = pendingSyncEvents.filter(ev => ev.type !== 'Info');
    eventsToSend.push({
        type: 'Info',
        data: [Date.now(), 'Suppressed events'],
    });
    return eventsToSend;
};
