import type { QueuedAction } from '../types/QueuedAction';

// update actions in the queue with obsolete ids and return the actions that
// were updated
export function updateIds(
    queue: QueuedAction[],
    changes: ReadonlyMap<string, string>
): QueuedAction[] {
    let rv: QueuedAction[] = [];
    if (changes.size > 0) {
        const updated: Set<QueuedAction> = new Set();
        for (let i = 0; i < queue.length; ++i) {
            let update: QueuedAction | undefined;
            const action = queue[i];

            for (const change of changes) {
                const before = change[0];
                const after = change[1];

                // update blocking keys
                const idx = action.blockingKeys.indexOf(before);
                if (idx >= 0) {
                    action.blockingKeys.splice(idx, 1, after);
                    update = action;
                }

                // update processor keys
                if (action.submitProcessor?.key === before) {
                    action.submitProcessor.key = after;
                    update = action;
                }

                // update variables
                if (deepReplace(action.operation.variables, before, after)) {
                    update = action;
                }
            }

            if (update) {
                updated.add(update);
            }
        }

        rv = Array.from(updated);
    }

    return rv;
}

function deepReplace(record: Record<string, any> | null, before: string, after: string): boolean {
    let replaced = false;

    if (record) {
        Object.entries(record).forEach(([key, value]: [string, any]) => {
            if (value === before) {
                record[key] = after;
                replaced = true;
            }

            if (value && typeof value === 'object') {
                replaced = deepReplace(value, before, after) || replaced;
            }
        });
    }

    return replaced;
}
