import { mutatorAction } from 'satcheljs';

import getAccountSourceListStore from '../store/accountSourceListStore';
import { accountSourceDataTypeChecker } from '../store/schema/AccountSourceList';
import type {
    AccountSource,
    AccountSourceListData,
    AccountAndCoprincipalSourceId,
    M365UserMailboxAccountSource,
} from '../store/schema/AccountSourceList';

/**
 * This action is called to set the store data. It is indented for use on the worker
 * thread where a copy of the account source list store is made.
 */
const action = mutatorAction(
    'SET_STORE_DATA',
    function (
        storeData: AccountSourceListData,
        sourcesBySourceId: Map<string, AccountAndCoprincipalSourceId>
    ): void {
        const store = getAccountSourceListStore();
        store.globalSettingsSourceId = storeData.globalSettingsSourceId;
        store.sources = storeData.sources;
        store.sourcesBySourceId = sourcesBySourceId;
        store.removedAccounts = storeData.removedAccounts;
    }
);

/**
 * The Map object does not handle being passed between the the main and worker so
 * we rebuild the map when setting the data
 * @param storeData
 * @returns
 */
function buildSourcesBySourceIdMap(
    storeData: AccountSourceListData
): Map<string, AccountAndCoprincipalSourceId> {
    const sourcesBySourceId = new Map<string, AccountAndCoprincipalSourceId>();

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    storeData.sources.forEach((coprincipalAccount: AccountSource) => {
        sourcesBySourceId.set(coprincipalAccount.sourceId, { source: coprincipalAccount });

        if (accountSourceDataTypeChecker.isM365UserMailbox(coprincipalAccount)) {
            const userMailbox = coprincipalAccount as M365UserMailboxAccountSource;
            const resourceAccounts: AccountSource[] = [
                ...userMailbox.sharedSources.sources,
                ...userMailbox.groupSources.sources,
                ...userMailbox.archiveSources.sources,
                ...userMailbox.publicFolderSources.sources,
                ...userMailbox.teamsSources.sources,
            ];

            resourceAccounts.forEach(resource =>
                sourcesBySourceId.set(resource.sourceId, {
                    source: resource,
                    coprincipalSourceId: coprincipalAccount.sourceId,
                })
            );
        }
    });

    return sourcesBySourceId;
}

export default function setStoreData(storeData: AccountSourceListData): void {
    const sourcesBySourceId = buildSourcesBySourceIdMap(storeData);
    action(storeData, sourcesBySourceId);
}
