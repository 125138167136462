import { type RejectCode } from 'owa-data-worker-utils';
import { type QueuedAction } from '../types/QueuedAction';
import { logUsage } from 'owa-analytics';
import { type RejectionRecord } from './rejectionLog';
import { isLeader } from 'owa-offline-worker-leader';

type ReportStatus = {
    waxed?: boolean;
    waned?: boolean;
};

const ReportIntervals = new Map<number, ReportStatus>([
    [1, {}],
    [8, {}],
    [16, {}],
    [64, {}],
    [256, {}],
    [512, {}],
]);

// send a peroidic report every 6 hours
const PERIODIC_REPORT_INTERVAL = 1000 * 60 * 60 * 6;
let lastPeriodicReport = 0;

// reset the report intervals every 24 hours
const INTERVAL_RESET_INTERVAL = 1000 * 60 * 60 * 24;
let lastIntervalReset = 0;

// keep track of queue changes
let totalWaxed = 0;
let totalWaned = 0;

export enum ReportType {
    Waxing,
    Waning,
    Periodic,
}

export function reportQueueStatus(
    queue: ReadonlyArray<QueuedAction>,
    reportType: ReportType,
    change: number
): void {
    if (isLeader()) {
        // track the number of times the queue has waxed and waned
        if (reportType === ReportType.Waxing) {
            totalWaxed += change;
        } else if (reportType === ReportType.Waning) {
            totalWaned += change;
        }

        // send a waxing report when the queue reaches a report interval size
        const waxingReport = ReportIntervals.get(queue.length);
        if (waxingReport && reportType === ReportType.Waxing && !waxingReport.waxed) {
            buildAndSendReport(queue, reportType);
            waxingReport.waxed = true;

            // reset the waning status of the empty queue so we know if/when this falls to zero
            const emptyQueueReport = ReportIntervals.get(1);
            if (emptyQueueReport) {
                emptyQueueReport.waned = false;
            }
        }

        // send a waning report when the queue falls below a report interval size
        const waningReport = ReportIntervals.get(queue.length + 1);
        if (waningReport && reportType === ReportType.Waning && !waningReport.waned) {
            buildAndSendReport(queue, reportType);
            waningReport.waned = true;
        }

        // report a periodic report when a certain amount of time has passed
        const now = Date.now();
        if (
            reportType === ReportType.Periodic &&
            now - lastPeriodicReport > PERIODIC_REPORT_INTERVAL
        ) {
            buildAndSendReport(queue, ReportType.Periodic);
            lastPeriodicReport = now;
        }

        // reset the report intervals when a certain amount of time has passed
        if (now - lastIntervalReset > INTERVAL_RESET_INTERVAL) {
            ReportIntervals.forEach(interval => {
                interval.waxed = false;
                interval.waned = false;
            });
            lastIntervalReset = now;
        }
    }
}

export function buildAndSendReport(queue: ReadonlyArray<QueuedAction>, reportType: ReportType) {
    const opNameStatus: Record<string, number> = {};
    const rejectStatus: Record<string, number> = {};

    for (const action of queue) {
        // keep track of how many operations of each name are in the queue
        const opName = `op_${action.opName}`;
        if (!opNameStatus[opName]) {
            opNameStatus[opName] = 0;
        }
        opNameStatus[opName]++;

        // keep track of how many of each rejection code are in the queue
        const rejectionRecords = Object.entries(
            action?.rejectionLog?.records || {}
        ) as ReadonlyArray<[RejectCode, RejectionRecord]>;
        for (const [code, record] of rejectionRecords) {
            const errCode = `err_${code}`;
            if (!rejectStatus[errCode]) {
                rejectStatus[errCode] = 0;
            }

            rejectStatus[errCode] += record.totalCount;
        }
    }

    const report = {
        size: queue.length,
        reportType: ReportType[reportType],
        ...opNameStatus,
        ...rejectStatus,
        totalWaxed,
        totalWaned,
    };

    logUsage('actionqueue_status_report', report);
    return report;
}

export function resetStateForTests() {
    ReportIntervals.forEach(interval => {
        interval.waxed = false;
        interval.waned = false;
    });

    totalWaxed = 0;
    totalWaned = 0;
    lastIntervalReset = 0;
    lastPeriodicReport = 0;
}
