// orphaned actions are actions that the queue will never attempt to run again for one of several reasons.
// the threshold here is the amount of time we wait for the server to respond to an action before the queue
// ignores it and moves on.  this is a bit of a safety valve to prevent the queue from getting stuck on
// actions that will never return.
export const ORPHANED_ACTION_THRESHOLD_MS = 60000 * 5;

export const CREATE_DRAFT: string = 'CreateDraft:';
export const SEND_OR_SAVE: string = 'SendOrSave:';
export const SEND_NEW_DRAFT: string = 'SendNewDraft:';
export const CREATE_REPLY: string = 'CreateReply:';
export const CREATE_REPLY_All: string = 'CreateReplyAll:';
export const CREATE_FORWARD: string = 'CreateForward:';
export const COMPOSE_ACTION_PREFIX: string = 'mail-compose-action:';
