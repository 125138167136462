import type { Table } from 'dexie';
import { compositeDexieKey } from 'owa-database-schema';

// We store the logs in batches for indexeddb efficiency.
export type SyncLogBatch = {
    time: string;
    size: number;
    logs: string[];
};

export const SYNC_LOGS_TABLE_NAME = 'syncLogs';
export type SyncLogsTable = Table<SyncLogBatch, [string, number]>;

// We
export const SYNC_LOGS_KEY = compositeDexieKey<SyncLogBatch>(['time', 'size']);

export const syncLogsSchema = {
    [SYNC_LOGS_TABLE_NAME]: `${SYNC_LOGS_KEY}`,
};
