import { setWin32GqlHostObjectCb } from 'native-win32gql-service';
import type { PstSupportCb } from 'owa-data-worker-utils';
import EventEmitter from 'owa-event-emitter';
import { PstFileLoggingEventType, setPstLoggingEventEmitter } from 'owa-pst-file-logging';

export function setPstSupportCallback(pstSupportCb: PstSupportCb) {
    // set win32gql host object proxy (web workers don't yet support host objects)
    setWin32GqlHostObjectCb(pstSupportCb);

    // set PST logging emitter to be a 'gateway' emitter, that proxies events to the mainthread PST logging emitter
    const mainthreadGatewayEmitter = new EventEmitter();
    for (const eventName of Object.keys(PstFileLoggingEventType)) {
        mainthreadGatewayEmitter.on(eventName, (...data: any[]) => {
            pstSupportCb.emitPstEvent(eventName, ...data);
        });
    }

    setPstLoggingEventEmitter(mainthreadGatewayEmitter);
}
