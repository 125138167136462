import { getGuid } from 'owa-guid';

// Global notification state with side effects
let channelId: string;
let channelReady: Promise<void>;
let resolveReady: () => void;

export const getChannelId: () => string = () => {
    if (!channelId) {
        channelId = getGuid();
    }

    return channelId;
};

export const setChannelId = (value: string) => {
    channelId = value;
};

export const ensureChannelReadyInitialized = () => {
    if (channelReady === undefined) {
        channelReady = new Promise(res => {
            resolveReady = res;
        });
    }
};

let getChannelReadyCb: typeof getChannelReady | undefined;
export const setGetChannelReadyCb = (callback: typeof getChannelReady) =>
    (getChannelReadyCb = callback);
export const getChannelReady: () => Promise<void> = () => {
    if (getChannelReadyCb) {
        return getChannelReadyCb();
    } else {
        ensureChannelReadyInitialized();
        return channelReady;
    }
};

export const resolveChannelReady = () => {
    ensureChannelReadyInitialized();
    resolveReady();
};

// Testing only
export const resetGlobals = () => {
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    channelId = undefined!;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    channelReady = undefined!;
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    resolveReady = undefined!;
};
