import type { Table } from 'dexie';
import type { WorkerLeaderType } from './WorkerLeaderType';
import type { DexieKey } from 'owa-database-schema';

export const WORKER_LEADER_TABLE_NAME = 'workerLeader';
export type WorkerLeaderTable = Table<WorkerLeaderType, string>;

const WORKER_ID_KEY: DexieKey<WorkerLeaderType> = 'uuid';
const IS_LEADER_KEY: DexieKey<WorkerLeaderType> = 'isLeader';

const keys = `${WORKER_ID_KEY},${IS_LEADER_KEY}`;
export const workerLeaderSchema = {
    [WORKER_LEADER_TABLE_NAME]: keys,
};

export type { WorkerLeaderType } from './WorkerLeaderType';
