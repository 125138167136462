import { LazyAction, LazyModule } from 'owa-bundling-light';

export type { Subscription } from './types/Subscription';
export type { WorkerHostObjectCb as Win32GqlHostObjectCb } from './types/WorkerHostObjectCb';

// Synchronous export for data worker init to inject host object callbacks to the main thread
// (synchronous to avoid blocking init on loading the Win32GqlService bundle)
export { setWorkerHostObjectCb as setWin32GqlHostObjectCb } from './web-worker-support/workerHostObjectCbInstance';

export { isMountedPstFile, isPstError, isRequestError } from './utils/typePredicates';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "Win32GqlService" */ './lazyIndex')
);

export const lazyInitiatePstFileRepair = new LazyAction(lazyModule, m => m.initiatePstFileRepair);
export const lazyMountPstFile = new LazyAction(lazyModule, m => m.mountPstFile);
export const lazyUnmountPstFile = new LazyAction(lazyModule, m => m.unmountPstFile);

export const lazyWin32GqlMutate = new LazyAction(lazyModule, m => m.win32GqlMutate);
export const lazyWin32GqlQuery = new LazyAction(lazyModule, m => m.win32GqlQuery);
export const lazyWin32GqlSubscribe = new LazyAction(lazyModule, m => m.win32GqlSubscribe);

export const lazyCreateSubscriptionFactoryToMapWin32GqlSubscription = new LazyAction(
    lazyModule,
    m => m.createSubscriptionFactoryToMapWin32GqlSubscription
);

export const lazyInvokeWin32GqlRequestCallback = new LazyAction(
    lazyModule,
    m => m.invokeWin32GqlRequestCallback
);

export const lazyCheckPrerequisites = new LazyAction(lazyModule, m => m.checkPrerequisites);

export type {
    MountPstOutcome,
    MountedPstFile,
    PstError,
    RequestError,
} from './types/MountPstOutcome';
