import { trace } from 'owa-trace';
import type { LogData } from 'owa-logging-utils';
import type { MailboxInfo } from 'owa-client-types';

export type SyncEventType =
    | 'Info'
    | 'EngineStateChanged'
    | 'QueueChanged'
    | 'ModuleCompleted'
    | 'ActionQueueChanged';
export type SyncEvent = {
    type: SyncEventType;
    data: any[];
};

export type SyncEventListener = (type: SyncEventType, ...data: any[]) => void;
export type SyncEventsListener = (events: SyncEvent[]) => void;

export type SyncEngineStatus = 'Unknown' | 'NotLeader' | '';

export type SyncQueueEntry = {
    type: string;
    description: string;
};

export type SyncQueueSummary = {
    id?: string | null;
    queue: SyncQueueEntry[];
};

export type SyncModuleSummary = {
    type: string;
    description: string;
    queueTime: number;
    startTime?: number;
    durationMs?: number;
    error?: string;
};

export type ActionQueueEntry = {
    id: number;
    opName: string;
    attempts: number;
    lastAttempt: string;
    isBlocked: boolean;
    lastError?: string;
    lastErrorCount?: number;
    blockingKeys?: string[];
    variables?: Record<string, any>;
    uuid: string;
};

export type ActionQueueSummary = {
    isStarted: boolean;
    isLeader: boolean;
    queue: ActionQueueEntry[];
    running: number[];
};

const listeners: SyncEventListener[] = [];

export function addSyncEventListener(value: SyncEventListener) {
    listeners.push(value);
}

/**
 * Creates a sync log.
 *
 * Structure the "message" param roughly like the following template for consistency across sync logs:
 * "[Module Name]: [Verb] [Noun] [Additional Info]"
 */
export function emitSyncEvent(message: string, data: LogData, mailboxInfo?: MailboxInfo | null) {
    trace.info(`${message} ${data}`, 'offline');
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    listeners.forEach(listener => listener('Info', Date.now(), message, data, mailboxInfo));
}

export function onSyncEngineStateChanged(state: string) {
    emitSyncEvent('SyncDiagnostics: engine state changed', { state });
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    listeners.forEach(listener => listener('EngineStateChanged', state));
}

export function onSyncQueueChanged(
    getQueueSummary: () => SyncQueueSummary,
    mailboxInfo: MailboxInfo
) {
    // We avoid wasting time examing the queue if there is no listener
    const summary = getQueueSummary();

    if (listeners.length > 0) {
        listeners.forEach(listener => listener('QueueChanged', summary, mailboxInfo));
    }
}

export function onActionQueueChanged(getActionQueueSummary: () => ActionQueueSummary) {
    if (listeners.length > 0) {
        listeners.forEach(listener => listener('ActionQueueChanged', getActionQueueSummary()));
    }
}

export function onSyncModuleCompleted(summary: SyncModuleSummary, mailboxInfo: MailboxInfo) {
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    listeners.forEach(listener => listener('ModuleCompleted', summary, mailboxInfo));
}

export type {
    onManualSyncCalendarActionSource,
    onManualSyncCalendarAction,
} from './types/onManualSyncCalendarActionSource';
