import type {
    Message,
    Item,
    MeetingCancellationMessage,
    MeetingResponseMessage,
    MeetingRequestMessage,
    ConversationNodeItem,
} from 'owa-graph-schema';
import { getHashedLogString } from './getHashedLogString';
import type { LogData } from './types/LogData';

export function getItemLogData(
    item:
        | Message
        | Item
        | MeetingCancellationMessage
        | MeetingResponseMessage
        | MeetingRequestMessage
        | ConversationNodeItem
): LogData {
    let reactions;
    let responseType;
    let start;
    let end;
    let toRecipients;
    let ccRecipients;
    let bccRecipients;
    let safetyLevel;
    let safetyReason;
    switch (item.__typename) {
        case 'Message':
            reactions = item.Reactions?.length;
            toRecipients = item.ToRecipients?.length;
            ccRecipients = item.CcRecipients?.length;
            bccRecipients = item.BccRecipients?.length;
            safetyLevel = item.MessageSafety?.MessageSafetyLevel;
            safetyReason = item.MessageSafety?.MessageSafetyReason;
            break;
        case 'MeetingCancellationMessage':
        case 'MeetingRequestMessage':
        case 'MeetingResponseMessage':
            start = item.Start;
            end = item.End;
            responseType = item.ResponseType;
            safetyLevel = item.MessageSafety?.MessageSafetyLevel;
            safetyReason = item.MessageSafety?.MessageSafetyReason;
            break;
    }

    // We try to send properties which are likely to help investigate bugs and are relative small
    // We hash the subject here as a 1-way function. If the user provides the subject of an item,
    // we can search the logs for the hash of that subject.
    // We use lengths as a cheap way to detect when the body has (probably) changed.
    // Hashing bodies would be too slow.
    return {
        id: item.id,
        sent: item.DateTimeSent,
        received: item.DateTimeReceived,
        receivedOrRenew: item.ReceivedOrRenewTime,
        flag: item.Flag?.FlagStatus,
        attachments: item.Attachments?.length || 0,
        hasBlockedImages: item.HasBlockedImages,
        hasQuotedText: item.HasQuotedText,
        importance: item.Importance,
        isDraft: item.IsDraft,
        mentionedMe: item.MentionedMe,
        sensitivity: item.Sensitivity,
        itemClass: item.ItemClass,
        reactions,
        start,
        end,
        responseType,
        body: item.Body?.Value?.length,
        firstBody: item.FirstBody?.Value?.length,
        normalizedBody: item.NormalizedBody?.Value?.length,
        uniqueBody: item.UniqueBody?.Value?.length,
        quotedText: item.TrimmedQuotedText?.length,
        subject: getHashedLogString(item.Subject),
        toRecipients,
        ccRecipients,
        bccRecipients,
        convId: item.ConversationId?.Id,
        safetyLevel,
        safetyReason,
        folderId: getHashedLogString(item.ParentFolderId?.Id),
    };
}
