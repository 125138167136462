import type { Maybe } from 'owa-graph-schema';
import type { AppDatabase } from 'owa-offline-database';
import type { FolderTableType } from 'owa-offline-folders-schema';
import { FOLDERS_SORT_INDEX_KEY } from 'owa-offline-folders-schema';
import Dexie from 'dexie';
import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import { getFoldersToShowFirst } from 'owa-folders-data-utils';

export async function getNewSortId(
    database: AppDatabase,
    oldDisplayName: string | undefined,
    newDisplayName: Maybe<string> | undefined,
    parentFolder: FolderTableType,
    oldSortIndex: number | undefined
): Promise<number> {
    // Get the max index, + 10, in case we need to add to the end of the list
    const lastFolder = await database.folders
        .orderBy(FOLDERS_SORT_INDEX_KEY)
        .reverse()
        .limit(1)
        .toArray();
    const nextIndex = (lastFolder[0]?.MetaData.sortIndex ?? 0) + 10;

    // If there is already a folder and the name is the same, return the old sort index, nothing has changed index wise
    if (oldDisplayName && oldDisplayName == newDisplayName) {
        return oldSortIndex ?? nextIndex;
    }

    // If there is no new display name, we don't know where to put the folder, so return nextIndex
    if (!newDisplayName) {
        return nextIndex;
    }

    // we don't care about casing
    const lowerCaseDisplayName = newDisplayName.toLowerCase();

    // Get all the children of the parent folder
    const parentsChildren: FolderTableType[] = await database.folders
        .where(FOLDERS_SORT_INDEX_KEY)
        .above(Dexie.minKey)
        .and(folder => folder.parentFolderId == parentFolder.id)
        .toArray();

    // If the parent has no children, have the sort index be after the parent
    if (parentsChildren.length == 0) {
        if (parentFolder) {
            return parentFolder.MetaData.sortIndex + 1;
        } else {
            return nextIndex;
        }
    }

    // Loop through the children to determine where to put the folder
    let previousChildFolder: FolderTableType | undefined = undefined;
    let i = 0;
    // If this is the root folder, we want to skip the folders to show first
    if (parentFolder?.distinguishedFolderType === PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID) {
        const foldersToShowFirst = getFoldersToShowFirst(parentFolder.mailboxInfo);
        i = foldersToShowFirst.length;
    }
    for (; i < parentsChildren.length; i++) {
        const childFolder = parentsChildren[i];
        const childFolderName = childFolder.displayName.toLowerCase();
        // If we found a child folder with a name after than the new display name, the sortIndex should be an average of that folder and the previous
        if (childFolderName > lowerCaseDisplayName) {
            const previousIndex =
                previousChildFolder?.MetaData.sortIndex ?? parentFolder.MetaData.sortIndex;
            const currentIndex = childFolder.MetaData.sortIndex;
            return (previousIndex + currentIndex) / 2;
        }
        previousChildFolder = childFolder;
    }

    // Else, we've hit the end of the list, so put it after the last child folder
    return previousChildFolder ? previousChildFolder?.MetaData.sortIndex + 10 : nextIndex;
}
