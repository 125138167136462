import type { Transaction } from 'dexie';
import type { Change, ChangeCallback } from './database';

/**
 * We want to delay propagating changes until the 'complete' event so that we don't notify if the transaction fails.
 * However, Dexie reverses the ordering of callbacks in transaction eventing. And ordering matters - for example in
 * case we delete and reinsert an object in the same transaction. So we track pending changes on our side.
 */
export class PendingIdbChanges {
    private pendingChanges: Map<Transaction, Change<any, any>[]> = new Map();
    private callback: ChangeCallback<any, any>;

    constructor(callback: ChangeCallback<any, any>) {
        this.callback = callback;
    }

    public addPendingChange = (transaction: Transaction, change: Change<any, any>) => {
        const rootTransaction = getRootTransaction(transaction);
        const changes = this.pendingChanges.get(rootTransaction);
        if (changes) {
            changes.push(change);
        } else {
            this.pendingChanges.set(rootTransaction, [change]);
            rootTransaction
                .on('complete')
                .subscribe(() => this.onTransactionComplete(rootTransaction));
            rootTransaction
                .on('abort')
                .subscribe(() => this.onTransactionAbortOrError(rootTransaction));
            rootTransaction
                .on('error')
                .subscribe(() => this.onTransactionAbortOrError(rootTransaction));
        }
    };

    private onTransactionComplete = (transaction: Transaction) => {
        const changes = this.pendingChanges.get(transaction);
        changes?.forEach(change => this.callback(change));
        this.pendingChanges.delete(transaction);
    };

    private onTransactionAbortOrError = (transaction: Transaction) => {
        this.pendingChanges.delete(transaction);
    };
}

function getRootTransaction(transaction: Transaction) {
    while (!!transaction.parent) {
        transaction = transaction.parent;
    }
    return transaction;
}
