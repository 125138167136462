import { errorThatWillCauseAlertAndThrow } from 'owa-trace';
import { type LocalStorageKeys } from '../util/LocalStorageKeys';

export type LocalStorageSetItemCb = (key: LocalStorageKeys, value: string) => void;
export type LocalStorageRemoveItemCb = (key: LocalStorageKeys) => void;

let localStorageSetItemCb: LocalStorageSetItemCb;
let localStorageRemoveItemCb: LocalStorageRemoveItemCb;

// Store local storage callbacks from main thread in memory on Web Worker thread for future use
export function setLocalStorageCallbacks(
    setItemC: LocalStorageSetItemCb,
    removeItemC: LocalStorageRemoveItemCb
) {
    localStorageSetItemCb = setItemC;
    localStorageRemoveItemCb = removeItemC;
}

// Web Worker doesn't have access to LocalStorage, so we mock it with WorkerStorageData
// which uses this function to set items in local storage
export function setLocalStorageItem(key: LocalStorageKeys, value: string) {
    if (!localStorageSetItemCb) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlertAndThrow('NoLocalStorageSetCallbackDefinedInWorker ' + key);
    }

    localStorageSetItemCb(key, value);
}

// Web Worker doesn't have access to LocalStorage, so we mock it with WorkerStorageData
// which uses this function to remove items from local storage
export function removeLocalStorageItem(key: LocalStorageKeys) {
    if (!localStorageRemoveItemCb) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlertAndThrow('NoLocalStorageRemoveCallbackDefinedInWorker ' + key);
    }

    localStorageRemoveItemCb(key);
}
