import type { GraphQLWorkerRequestCallbacks } from 'owa-data-worker-utils';

let graphqlCb: GraphQLWorkerRequestCallbacks;

export function setGraphQlCallbacks(cb: GraphQLWorkerRequestCallbacks) {
    graphqlCb = cb;
}

export function getGraphQlCallbacks() {
    return graphqlCb;
}
