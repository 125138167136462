import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';

export async function isOverBudgetResponse(
    error: TraceErrorObject | undefined
): Promise<Error | undefined> {
    let overBudgetError: Error | undefined = undefined;

    if (error?.xowaerror?.endsWith('OwaServiceFaultException')) {
        try {
            const clone = error.response?.clone();
            const json = await clone?.json();
            if (json?.Body?.ExceptionName?.endsWith('OverBudgetException')) {
                overBudgetError = error;
            }
        } catch (e) {
            errorThatWillCauseAlert('Failed to parse OwaServiceFaultException', e);
        }
    }

    return overBudgetError;
}
