import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ImageDatabaseCleanup"*/ './lazyIndex')
);

export const lazyPurgeImagesFromDatabase = new LazyAction(
    lazyModule,
    m => m.purgeImagesFromDatabase
);
