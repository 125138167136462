import type { SessionData } from 'owa-service/lib/types/SessionData';
import { parseExtraSettings } from 'owa-config/lib/extraSettings';
import { extractFlightsFromSettings, initializeFeatureFlags } from 'owa-feature-flags';

export function initFeatures(sessionData: SessionData, logicalOverrides: Record<string, boolean>) {
    const extraSettings = parseExtraSettings(sessionData.extraSettings);
    const featureOverrides: string[] = extraSettings?.featureOverrides || [];
    const featureFlags = (sessionData.features || []).concat(featureOverrides);

    const flightsFromSettings = extractFlightsFromSettings(
        sessionData.applicationSettings?.settings
    );
    initializeFeatureFlags([...featureFlags, ...flightsFromSettings], logicalOverrides);
}
