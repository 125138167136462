const LARGE_READ_ERROR_MESSAGE = 'Failed to read large IndexedDB value';
const MISSING_FILE_ERROR_MESSAGE =
    'Data lost due to missing file. Affected record should be considered irrecoverable';
export function isLargeReadError(e: Error): boolean {
    return (
        !!e.message?.includes(LARGE_READ_ERROR_MESSAGE) ||
        !!e.stack?.includes(LARGE_READ_ERROR_MESSAGE) ||
        !!e.message?.includes(MISSING_FILE_ERROR_MESSAGE) ||
        !!e.stack?.includes(MISSING_FILE_ERROR_MESSAGE)
    );
}
