import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import getAccountDiagnosticDataForMailboxInfo from 'owa-account-source-list-store/lib/utils/getAccountDiagnosticDataForMailboxInfo';
import { getCoprincipalAccountPersistenceId } from 'owa-account-source-list/lib/utils/pluggableHost';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { removedAccountError } from 'owa-offline-errors';
import type { TraceErrorObject } from 'owa-trace';

export function getDatabaseId(mailboxInfo?: MailboxInfo | null): string {
    if (!mailboxInfo) {
        mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    }

    const persistenceId = getCoprincipalAccountPersistenceId(
        mailboxInfo,
        true /*noAlertOnFailure*/
    );

    if (persistenceId) {
        return persistenceId;
    } else {
        const diagnostics = getAccountDiagnosticDataForMailboxInfo(mailboxInfo);
        const error: TraceErrorObject = diagnostics.isRemovedAccount
            ? removedAccountError()
            : new Error('getDatabaseId: Can not get a persistenceId to resolve database');
        error.additionalInfo = diagnostics;
        logStartGreyError('MissingPersistenceId', error);
        throw error;
    }
}
