import type { AppDatabase } from 'owa-offline-database';

export const removeDeletedFoldersFromDb = (
    database: AppDatabase,
    oldFolderIds: string[],
    incomingFolderIds: string[]
) => {
    return database.transaction('rw', database.folders, async () => {
        const deletedFolderIds = oldFolderIds.filter(id => !incomingFolderIds.includes(id));
        if (deletedFolderIds.length > 0) {
            await database.folders.bulkDelete(deletedFolderIds);
        }
    });
};
