import type { Table } from 'dexie';
import type { CalendarGroup } from 'owa-graph-schema';
import type { DexieKey } from 'owa-database-schema';

export type CalendarGroupsTable = Table<CalendarGroup, string>;

const CALENDAR_GROUPS_ID_KEY: DexieKey<CalendarGroup> = 'calendarGroupId.id';

export const calendarGroupsSchema = {
    calendarGroups: `${CALENDAR_GROUPS_ID_KEY}`,
};
