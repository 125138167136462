import { ObservableMap } from 'mobx';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { mutatorAction } from 'satcheljs';
import { getStore } from '../store/store';
import { makeAdditionalAccountConfiguration } from '../utils/makeAdditionalAccountConfiguration';
import { userConfigurationSet } from './userConfigurationSet';

const setUserConfigurationInternal = mutatorAction(
    'setUserConfigurationInternal',
    (userConfiguration: OwaUserConfiguration, mailboxId?: string) => {
        const store = getStore();
        if (!mailboxId) {
            store.userConfiguration = {
                ...userConfiguration,
                ...makeAdditionalAccountConfiguration(userConfiguration),
            };
        } else {
            if (!store.connectedAccountsUserConfigurationMap) {
                store.connectedAccountsUserConfigurationMap = new ObservableMap({});
            }
            store.connectedAccountsUserConfigurationMap.set(mailboxId, userConfiguration);
        }
    }
);

export default (userConfiguration: OwaUserConfiguration, mailboxId?: string) => {
    setUserConfigurationInternal(userConfiguration, mailboxId);

    if (!mailboxId) {
        userConfigurationSet(userConfiguration);
    } else {
        userConfigurationSet(userConfiguration, mailboxId);
    }
};
