import { getItem, setItem } from 'owa-local-storage';

const OFFLINE_DATABASE_CORRUPTION_COUNT_KEY = 'OfflineDatabaseCorruptionCount';

// Track the number of times we have tried to recover from a corrupt offline database.
export function incrementDatabaseCorruptionCount() {
    let count = Number(getItem(self, OFFLINE_DATABASE_CORRUPTION_COUNT_KEY)) || 0;
    count++;
    setItem(self, OFFLINE_DATABASE_CORRUPTION_COUNT_KEY, count.toString());
    return count;
}

export function resetDatabaseCorruptionCountForTest() {
    setItem(self, OFFLINE_DATABASE_CORRUPTION_COUNT_KEY, '0');
}
