import type { TraceErrorObject } from 'owa-trace';
import type {
    Handlers,
    WorkerConfigSyncBCName,
    WorkerConfigSyncEvent,
} from 'owa-worker-config-sync-types';
import type { UnsampleDataToggleAddon } from 'owa-analytics-types';
import type { ThreadName } from 'owa-thread-config';
import { getAnalyticsAddon } from 'owa-analytics-addons';
import { trace } from 'owa-trace';
import { getThreadName } from 'owa-thread-config';

let bc: BroadcastChannel | null = null;

// Set all handlers
const handlers: {
    [key in Handlers]: (value: any) => any;
} = {
    unsampledLogging: handleUnsampledLogging,
};

// Receive config on worker
export function receiveConfigOnWorker() {
    const threadName = getThreadName() as ThreadName | null;

    if (!threadName) {
        throw new Error('ThreadNameNotSet_ReceiveConfigInWorker');
    }

    bc = new BroadcastChannel('worker-config-sync' as WorkerConfigSyncBCName);
    bc.onmessage = event => {
        const message = event.data as WorkerConfigSyncEvent;
        if (message.executeOn === 'all' || message.executeOn.includes?.(threadName)) {
            const { handler, value } = message;

            trace.info(`[config-sync] Received "${handler}" config in worker`, 'analytics');

            const handlerFunction = handlers[handler];

            if (handlerFunction) {
                handlerFunction(value);
            } else {
                const err: TraceErrorObject = new Error("Worker Function Handler doesn't exist");
                err.additionalInfo = { handler, value };
                throw err;
            }
        }
    };

    trace.info(`[config-sync] This worker is now listening to config sync events`, 'analytics');
}

/**
 * Handler for unsampledLogging
 */
function handleUnsampledLogging(value: boolean | null) {
    trace.info(`[config-sync] Received 'unsamplingLogging' event. Value: ${value}`, 'analytics');

    const addon = getAnalyticsAddon<UnsampleDataToggleAddon>(
        'UnsampledDataLogging'
    )?.executeNow() as UnsampleDataToggleAddon | undefined;
    addon?.overrideUnsampledLogging(value ?? false);
}
