import setStoreData from 'owa-account-source-list-store/lib/actions/setStoreData';
import type { AccountSourceListData } from 'owa-account-source-list-store';
import { trace } from 'owa-trace';

/**
 * Syncs the account source list store data onto the worker thread
 * @param storeData The account source list store
 */
export function setAccountSourceListStoreData(storeData: AccountSourceListData) {
    trace.info(
        `[account-sync] set account source list store data on worker thread. ${storeData.sources.length} account source(s) in data`
    );

    setStoreData(storeData);
}
