import type {
    MountPstOutcome,
    MountedPstFile,
    PstError,
    RequestError,
} from '../types/MountPstOutcome';

export function isMountedPstFile(outcome: MountPstOutcome): outcome is MountedPstFile {
    return outcome.outcomeType === 'MountedPstFile';
}

export function isPstError(outcome: MountPstOutcome): outcome is PstError {
    return outcome.outcomeType === 'PstError';
}

export function isRequestError(outcome: MountPstOutcome): outcome is RequestError {
    return outcome.outcomeType === 'RequestError';
}
