export const complexTokenPlaceholderPrefix = '__COMPLEXTOKEN_';

// The set of regular expressions used to identify complex tokens which need to be left alone by the javascript built-in Intl.Segmenter
// These are tokens which we know should be a single token, but the Segmenter will segment into multiple different words
// IMPORTANT NOTE: order matters here!
// The first regex will run first, so for example, if the string has "foo-bar", the entire string will be replaced with a complex token due to the quotes regex
// And the hyphenated words regex will not find foo-bar because it will have already been replaced by the complexTokenPlaceholderPrefix
export const complexTokenPatterns = [
    /"[^"]+"/g, // quoted strings
    /\w+([-\\\/]\w+)+/g, // hyphenated words and slash-delimited words
];
