import type { Table } from 'dexie';
import type { DexieKey } from 'owa-database-schema';
import { compositeDexieKey } from 'owa-database-schema';
import type { PersonaRecord } from './PersonaRecord';

export const CONTACTS_TABLE_NAME = 'contacts';
export type ContactsTable = Table<PersonaRecord, string>;

export const CONTACTS_CONTACTID_KEY: DexieKey<PersonaRecord> = 'contactId';
export const CONTACTS_OLS_PERSONAID_KEY: DexieKey<PersonaRecord> = 'outlookServicesPersonaId';
export const CONTACTS_EMAILS_KEY: DexieKey<PersonaRecord> = 'rawEmails';
export const CONTACTS_FIRST_NAME_SORT_KEY = compositeDexieKey<PersonaRecord>([
    'indexableIsContact',
    'defaultName.first',
]);
export const CONTACTS_LAST_NAME_SORT_KEY = compositeDexieKey<PersonaRecord>([
    'indexableIsContact',
    'defaultName.last',
]);
export const CONTACTS_CREATED_DATE_SORT_KEY = compositeDexieKey<PersonaRecord>([
    'indexableIsContact',
    'createdDateTime',
]);
export const CONTACTS_IS_CONTACT_KEY: DexieKey<PersonaRecord> = 'indexableIsContact';

export const contactsSchema = {
    [CONTACTS_TABLE_NAME]: [
        CONTACTS_CONTACTID_KEY,
        CONTACTS_OLS_PERSONAID_KEY,
        '*' + CONTACTS_EMAILS_KEY,
        CONTACTS_FIRST_NAME_SORT_KEY,
        CONTACTS_LAST_NAME_SORT_KEY,
        CONTACTS_CREATED_DATE_SORT_KEY,
        CONTACTS_IS_CONTACT_KEY,
    ].join(','),
};
