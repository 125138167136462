import type { ConversationType } from 'owa-graph-schema';
import type { SyncLieSource } from 'owa-offline-messages-schema';

export enum ConversationNodesDirtyState {
    UpToDate,
    NeedsSync, // We should sync this conversation to see if it has changed
    Dirty,
}

type MetaData = {
    sortTime: string; // Calculated based on folder type so we can use a single index to sort by date
    dirtyState: ConversationNodesDirtyState;
    isUnread?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (unread) so undefined does not make to the index
    isFlagged?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (flagged) so undefined does not make to the index
    toOrCCMe?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (toOrCCMe) so undefined does not make to the index
    hasFiles?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (hasFiles) so undefined does not make to the index
    mentioned?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (mentioned) so undefined does not make to the index
    hasCalendarInvite?: 1; // undefined or 1 is used to create an IndexedDB index based on this value. We only consume this index for 1 value (hasCalendarInvites) so undefined does not make to the index
    creationSource?: SyncLieSource; // If present, indicates the item was created via (not yet confirmed) sync lie.
};

export type ConversationTableType = ConversationType & {
    MetaData: MetaData;
};
