import { lazyPrecompile } from 'owa-apollo-execute';
import { getResolvers } from '../util/getResolvers';
import { doesSystemDatabaseExist } from 'owa-offline-system-database';
import { type WindowBootstrapSettings } from 'owa-worker-types';
import { bootstrap } from './bootstrap';

export async function precompileSchema(rawData: WindowBootstrapSettings) {
    // need to call bootstrap, otherwise lazy loads will fail
    bootstrap(rawData);

    // decide which set of resolvers to precompile based on whether there's a system db
    // this can't take a dependency on feature flags or host app features because precompile is
    // an early optimization that happens before those things are guaranteed to be available
    const offlineCapable = await doesSystemDatabaseExist();
    const resolvers = await getResolvers(offlineCapable);
    await lazyPrecompile.importAndExecute(resolvers.offline || resolvers.web);
}
