import type { CustomDataMap, DataPointEventType } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace/lib/TraceErrorObject';

import { Md5 } from 'ts-md5/dist/md5';
import { isOneOf } from 'owa-errors/lib/isOneOf';
import { isExternalError } from 'owa-errors/lib/isExternalError';

const errorsToIgnore = ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed'];

export function ignoreError(message: string) {
    return isOneOf(errorsToIgnore, message);
}

export function addFinalErrorFieldsToCustomData(
    message: string,
    errorDetails: TraceErrorObject | undefined,
    customData: CustomDataMap,
    logInDevMode?: boolean
) {
    if (errorDetails?.response) {
        customData.status = errorDetails.response.status;
    }
    if (errorDetails?.fetchErrorType) {
        customData.fet = errorDetails.fetchErrorType;
    }

    const table: DataPointEventType = logInDevMode
        ? 'client_event_dev_only'
        : isExternalError(message, errorDetails)
        ? 'client_error_external'
        : 'client_error';

    return {
        customData,
        table,
    };
}

export function getErrorHash(message: string, errorDetails: TraceErrorObject | undefined) {
    const hashValue = errorDetails?.stack || message;
    return (hashValue ? Md5.hashStr(hashValue) : -1)?.toString?.();
}
