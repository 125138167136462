import { type GetSubmitKeyActions } from '../queue/dependencyMap';
import {
    type ProcessSubmitResult,
    type QueuedActionSubmitProcessor,
    type RestrictedQueuedAction,
} from '../types/QueuedActionSubmitProcessor';

export const cancelProcessor: QueuedActionSubmitProcessor = (
    action: RestrictedQueuedAction,
    _queue: ReadonlyArray<RestrictedQueuedAction>,
    getDeps: GetSubmitKeyActions,
    isRunning: (id: number) => boolean
) => {
    // canceling actions are exactly two actions that share a cancel key where neither is running.
    // if at least one of them is running, return a retry result because they might be cancelable later
    let rv: ProcessSubmitResult = [];

    const cancelingIds = getDeps(action);
    if (cancelingIds?.length === 2) {
        const [id1, id2] = cancelingIds;
        if (!isRunning(id1) && !isRunning(id2)) {
            rv = cancelingIds.map(id => ({ kind: 'Cancel', id }));
        } else {
            rv = 'Retry';
        }
    }

    return rv;
};
