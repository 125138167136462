import type { AppDatabase } from 'owa-offline-database';
import { getFolderById } from './getFolderById';

export function getFolderTypeById(
    database: AppDatabase,
    folderId: string
): Promise<string | undefined> {
    return getFolderById(database, folderId).then(folder => {
        if (!folder) {
            return undefined;
        }
        return folder.distinguishedFolderType || 'none';
    });
}
