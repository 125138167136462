import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import type { OutlookFavorites } from 'owa-graph-schema';
import { type QueuedAction } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';
import { createFavoriteId } from '../util/folderUtils';
import { loadSetting, updateSetting } from 'owa-offline-settings';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import type { MailboxInfo } from 'owa-client-types';

const FAVORITES_SETTING_NAME = 'favorites';

async function updateIDBItemId(
    oldFavoriteId: string,
    newFavoriteId: string,
    mailboxInfo: MailboxInfo | undefined
) {
    const database = await getDatabase(mailboxInfo);
    await database.transaction('rw', database.settings, async transaction => {
        // Update Indexed DB with the service id
        setTransactionSource(transaction, 'localLie');

        const currentFavorites = await loadSetting<OutlookFavorites>(
            database,
            FAVORITES_SETTING_NAME
        );

        if (!currentFavorites || !currentFavorites.value) {
            return;
        } else {
            const updatedFavorites = currentFavorites.value.map(favorite => {
                if (favorite.Id == oldFavoriteId) {
                    return { ...favorite, Id: newFavoriteId };
                } else {
                    return favorite;
                }
            });

            await updateSetting(database, FAVORITES_SETTING_NAME, { value: updatedFavorites });
        }
    });
}

export async function addFavoriteResultProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        // Used by the action queue to remap our temporary local folder id to the server folder id
        const idChanges: Map<string, string> = new Map();

        const type = action.operation.context.queuedAction.localResult?.data?.addFavorite?.Type;
        const displayName =
            action.operation.context.queuedAction.localResult?.data?.addFavorite?.DisplayName;

        const localFavoriteId = createFavoriteId(type, displayName);
        const serverFavoriteId = result.fetchResult?.data?.addFavorite?.Id;

        if (localFavoriteId && serverFavoriteId) {
            const mailboxInfo = action.operation.variables?.mailboxInfo;
            updateIDBItemId(localFavoriteId, serverFavoriteId, mailboxInfo);
            idChanges.set(localFavoriteId, serverFavoriteId);
        }

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges,
        };
    }

    return Promise.resolve(rv);
}
