import type { RequestOptions } from 'owa-service/lib/RequestOptions';

/**
 * Sync requests are rarely immeditely visible to the user, so we set a long timeout and avoid retries.
 * If the service requests is slow because the server is busy, we prefer to wait and try again later rather
 * than send another request that will make things worse.
 */
export const requestOptionsForSync: RequestOptions = {
    timeoutMS: 300000,
    retryCount: 1,
};
