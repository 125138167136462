import { errorThatWillCauseAlertAndThrow } from 'owa-trace';

export type AppRebootCb = (reason: string, noDelay: boolean, metaData: AppRebootMetaData) => void;
export type AppRebootMetaData = {
    [key: string]: string | boolean | number;
};

let appRebootCallback: AppRebootCb | undefined;

// Store reboot callbacks from main thread in memory on Web Worker thread for future use
export function setRebootWorkerCallback(appRebootCb: AppRebootCb) {
    appRebootCallback = appRebootCb;
}

export function appAppReboot(reason: string, noDelay: boolean, metaData: AppRebootMetaData) {
    if (!appRebootCallback) {
        errorThatWillCauseAlertAndThrow('NoAppRebootCallbackDefinedInWorker');
    }

    appRebootCallback(reason, noDelay, metaData);
}
