import type { FolderHierarchyResult } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { getDatabase } from 'owa-offline-database';
import type { FolderTableType } from 'owa-offline-folders-schema';

export async function saveOfflineFolderHierarchy(
    mailboxInfo: MailboxInfo,
    folderHierarchy: FolderHierarchyResult,
    startingIndex: number = 0
) {
    const database = await getDatabase(mailboxInfo);

    // Write folders to the offline database
    return database.transaction('rw', database.folders, async () => {
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        const allFolders = folderHierarchy.Folders?.concat(folderHierarchy.RootFolder!);
        if (!allFolders) {
            return;
        }

        // We want to save the outbox folder so that we get the real id, etc. from the service.
        // But preserve the local counts since we don't sync the outbox.
        const outbox = allFolders.find(folder => folder?.distinguishedFolderType === 'outbox');
        if (outbox) {
            const localOutbox = await database.folders.get(outbox.id);
            if (localOutbox) {
                outbox.totalMessageCount = localOutbox.totalMessageCount;
                outbox.UnreadCount = localOutbox.UnreadCount;
            }
        }

        let index = startingIndex;
        const allFoldersFolderType = allFolders
            .filter(folder => !!folder)
            .map(folder => {
                return { ...folder, MetaData: { sortIndex: (index += 10) } };
            });
        await database.folders.bulkPut(allFoldersFolderType as FolderTableType[]);
    });
}
