import type { TraceErrorObject } from 'owa-trace';
import { logGreyError } from 'owa-analytics';
import { isNetworkError, isThrottlingError } from 'owa-errors';
import { isPermanentDbError, isQuotaExceededError } from 'owa-offline-errors';
import { toggleOffline } from 'owa-offline-sync-settings';
import { type HttpStatusCode, isSuccessStatusCode } from 'owa-http-status-codes';
import { checkAndHandleDatabaseCorruption } from 'owa-offline-corruption';

import type { SyncModule } from '../SyncModule';

export function handleSyncError(error: TraceErrorObject, syncModule: SyncModule<any, any>) {
    checkAndHandleDatabaseCorruption(syncModule.database, error);

    // Log client errors and service errors but not network, auth, etc.
    if (
        (!error.fetchErrorType || error.fetchErrorType === 'ServerFailure') &&
        !isPermanentDbError(error) &&
        !isNetworkError(error.message) &&
        !expectedHttpErrors.includes(error.httpStatus || 0)
    ) {
        logGreyError(
            'sync_error',
            error,
            {
                classification: getSyncErrorClassification(error),
                type: syncModule.type,
                info: error.diagnosticInfo || '',
                code: error.responseCode || '',
                errorCode: error.errorCode || '',
            },
            {
                mailbox: syncModule.mailboxInfo,
            }
        );
    } else if (isQuotaExceededError(error)) {
        // Disable sync when we hit quota exceeded so we will stop using local data that we can't keep up to date.
        toggleOffline(false, 'QuotaExceeded');
    }
}

// Bucket sync errors to make it easier to track trends via telemetry
function getSyncErrorClassification(error: TraceErrorObject): string {
    if (isThrottlingError(error.message) || error.httpStatus === 429) {
        return 'ThrottlingError';
    } else if (
        error.fetchErrorType === 'ServerFailure' ||
        (error.httpStatus && !isSuccessStatusCode(error.httpStatus))
    ) {
        return 'ServerFailure';
    } else if (error.message?.includes('TimeoutError')) {
        return 'TimeoutError';
    } else if (error.message?.includes('AbortError')) {
        return 'AbortError';
    } else {
        return 'Unknown';
    }
}

// Network and auth errors we expect to see
const expectedHttpErrors = [401, 502, 504];
