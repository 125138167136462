import type { Operation } from '@apollo/client';
import type { AcceptedQueuedResult } from './QueuedActionResult';
import type { OwsOptionsStore } from 'owa-outlook-service-option-store';
import type { MailboxInfo } from 'owa-client-types';
import type {
    onManualSyncCalendarActionSource,
    onManualSyncCalendarAction,
} from 'owa-offline-sync-diagnostics';
import type { TraceErrorObject } from 'owa-trace';
import type { Sanitized } from './sanitize';

export type DataWorkerBroadcast = {
    unloading: (id: string) => Promise<void>;
    actionQueueChanged: () => Promise<void>;
    queuedActionAccepted: (
        op: Pick<Operation, 'extensions' | 'operationName' | 'query' | 'variables'>,
        uuid: string,
        cachePolicy: 'cache' | 'no-cache',
        result: AcceptedQueuedResult
    ) => Promise<void>;
    accountsChanged: (accounts: any) => Promise<void>;
    optionsChanged: (options: { [key: string]: OwsOptionsStore }) => void;
    onManualSyncMail: (mailboxInfo: Sanitized<MailboxInfo>, folderId?: string) => void;
    userConfigurationChanged: (mailboxInfo: Sanitized<MailboxInfo>) => Promise<void>;
    setDiagnosticPanelLoggingEnabled: (enable: boolean) => Promise<void>;
    scheduleHierarchySync: (mailboxInfoString: string) => void;
    onManualSyncPGAL: (mailboxInfo: Sanitized<MailboxInfo>) => void;
    onManualSyncCalendar: (
        actionSource: onManualSyncCalendarActionSource,
        action: onManualSyncCalendarAction,
        mailboxInfo: Sanitized<MailboxInfo>,
        folderId?: string
    ) => void;
    setAccountTimeZone: (
        newTimeZone: string | undefined,
        mailboxInfo: Sanitized<MailboxInfo>
    ) => void;
    onWorkerError: (message: string, details?: TraceErrorObject) => void;
};

export const DataWorkerBroadcastChannelName = 'owa-dataworker-broadcast';
