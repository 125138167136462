import type { ConversationTableType } from 'owa-offline-conversations-schema';
import type ItemId from 'owa-service/lib/contract/ItemId';
import type { ConversationType } from 'owa-graph-schema';

import { getHashedLogString } from './getHashedLogString';
import type { LogData } from './types/LogData';

export function getConversationLogData(conv: ConversationTableType | ConversationType): LogData {
    // We try to send properties which are likely to help investigate bugs and are relative small
    // We hash the subject here as a 1-way function. If the user provides the subject of an item,
    // we can search the logs for the hash of that subject.

    let sortTime;
    let dirtyState;
    let creationSource;
    if (isConversationTableType(conv)) {
        sortTime = conv.MetaData.sortTime;
        dirtyState = conv.MetaData.dirtyState;
        creationSource = conv.MetaData.creationSource;
    }

    return {
        id: conv.id || conv.ConversationId?.Id,
        instanceKey: conv.InstanceKey,
        lastDeliveryOrRenewTime: conv.LastDeliveryOrRenewTime,
        lastDeliveryTime: conv.LastDeliveryTime,
        unread: conv.UnreadCount,
        msgCount: conv.MessageCount,
        flag: conv.FlagStatus,
        importance: conv.Importance,
        likes: 0,
        hasAttachments: conv.HasAttachments,
        inference: conv.InferenceClassification,
        drafts: conv.DraftItemIds?.map(draft => (<ItemId>draft).Id).join(';'),
        mentioned: conv.mentionedMe,
        lastModified: conv.LastModifiedTime,
        topic: getHashedLogString(conv.ConversationTopic),
        folder: getHashedLogString(conv.ParentFolderId?.Id),
        sortTime,
        dirtyState,
        creationSource,
    };
}

function isConversationTableType(conv: any): conv is ConversationTableType {
    return 'MetaData' in conv;
}
