import { setTransactionSource, type AppDatabase } from 'owa-offline-database';
import type { TombstoneTableType } from 'owa-offline-tombstone-schema';
import { type TombstoneReasonType, TOMBSTONE_ACTION_ID_KEY } from 'owa-offline-tombstone-schema';
import { isFeatureEnabled } from 'owa-feature-flags';
import { loadTombstones, loadTombstonesCached } from './tombstoneCache';
import { logGreyError } from 'owa-analytics';

export function saveTombstones(
    database: AppDatabase,
    tombstones: TombstoneTableType[]
): Promise<any> {
    return database.transaction('rw', database.offlineTombstones, () => {
        return database.offlineTombstones.bulkPut(tombstones);
    });
}

const isTombStoneDisabled = (tombstoneReason: TombstoneReasonType): boolean => {
    const isComposeTombStone: boolean = tombstoneReason == 5;
    return (
        (!isComposeTombStone && !isFeatureEnabled('mon-offline-tombstones')) ||
        (isComposeTombStone && !isFeatureEnabled('cmp-offline-compose-tombstones'))
    );
};

export async function addTombstone(
    database: AppDatabase,
    folderId: string,
    rowKeys: string[],
    tombstoneReason: TombstoneReasonType,
    queuedActionId: string
): Promise<unknown> {
    if (isTombStoneDisabled(tombstoneReason)) {
        return Promise.resolve();
    }

    return database.transaction('rw', database.offlineTombstones, async transaction => {
        setTransactionSource(transaction, 'localLie');
        const tombstones = await loadTombstones(database);
        const newTombstones: TombstoneTableType[] = [];
        const tombstonesToBeRemoved: [string, string, string][] = [];
        for (let i = 0; i < rowKeys.length; i++) {
            const rowKey = rowKeys[i];
            const existingTombstones = tombstones.filter(tombstone => {
                return tombstone.FolderId === folderId && tombstone.RowKey === rowKey;
            });
            const existingTombstonedReasons = new Set<TombstoneReasonType>(
                existingTombstones.map(tombstone => tombstone.TombstoneReason)
            );

            /**
             * Do not add the entry to the list if
             * there exists a RowRemove reason type entry for this row as it supercedes all other reasons
             * or if the tombstoneReason is already present
             */
            if (
                tombstoneReason != 5 &&
                (existingTombstonedReasons.has(3) || existingTombstonedReasons.has(tombstoneReason))
            ) {
                break;
            }

            // RowRemove or Send tombstone should delete all the other tombstones for this row
            if (tombstoneReason == 3 || tombstoneReason == 5) {
                const tombstoneKeys: [string, string, string][] = existingTombstones.map(
                    tombstone => [tombstone.ActionQueueId, tombstone.FolderId, tombstone.RowKey]
                );
                tombstonesToBeRemoved.push(...tombstoneKeys);
            }

            newTombstones.push({
                FolderId: folderId,
                RowKey: rowKey,
                ActionQueueId: queuedActionId,
                TombstoneReason: tombstoneReason,
            });
        }

        return Promise.all([
            database.offlineTombstones.bulkDelete(tombstonesToBeRemoved),
            saveTombstones(database, newTombstones),
        ]);
    });
}

export async function removeTombstones(
    database: AppDatabase,
    actionQueueId: string,
    isActionSucceeded: boolean
) {
    if (
        !isFeatureEnabled('mon-offline-tombstones') &&
        !isFeatureEnabled('cmp-offline-compose-tombstones')
    ) {
        return Promise.resolve();
    }

    try {
        await database.transaction('rw', database.offlineTombstones, async transaction => {
            setTransactionSource(transaction, 'localLie');
            return database.offlineTombstones
                .where(TOMBSTONE_ACTION_ID_KEY)
                .equals(actionQueueId)
                .filter(tombstone => tombstone.TombstoneReason != 5 || isActionSucceeded)
                .delete();
        });
        return Promise.resolve();
    } catch (error) {
        logGreyError('RemoveTombstones ', error, {
            actionQueueId,
        });
        return Promise.resolve();
    }
}

export async function hasTombstoneReason(
    database: AppDatabase,
    folderId: string,
    rowKey: string,
    tombstoneReason: TombstoneReasonType
) {
    if (isTombStoneDisabled(tombstoneReason)) {
        return Promise.resolve(false);
    }

    const tombstones = await loadTombstonesCached(database);
    const tombstoneReasons = new Set<TombstoneReasonType>();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    tombstones.forEach(tombstone => {
        if (tombstone.FolderId === folderId && tombstone.RowKey === rowKey) {
            tombstoneReasons.add(tombstone.TombstoneReason);
        }
    });

    return !!tombstoneReasons.has(tombstoneReason);
}

export async function clearTombstones(database: AppDatabase) {
    return database.offlineTombstones.clear();
}

export async function getTombstoneReasons(
    database: AppDatabase,
    folderId: string,
    rowKey: string
): Promise<TombstoneReasonType[]> {
    if (!isFeatureEnabled('mon-offline-tombstones')) {
        return Promise.resolve([]);
    }

    const tombstones = await loadTombstonesCached(database);
    const tombstoneReasons = new Set<TombstoneReasonType>();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    tombstones.forEach(tombstone => {
        if (tombstone.FolderId === folderId && tombstone.RowKey === rowKey) {
            tombstoneReasons.add(tombstone.TombstoneReason);
        }
    });

    return Array.from(tombstoneReasons);
}
