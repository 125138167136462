import EventEmitter from 'owa-event-emitter';
import NotificationEventType from '../schema/NotificationEventType';
import { type ChannelDataEvent } from '../schema/ChannelDataEvent';

let emitter: EventEmitter;

export default function getNotificationEmitter(): EventEmitter {
    return (emitter ??= new EventEmitter());
}

export function setNotificationEmitter(value: EventEmitter) {
    emitter = value;
}

export function emitChannelDataEvent(message: string, eventData: ChannelDataEvent): void {
    getNotificationEmitter().emit(
        NotificationEventType.ChannelData,
        message,
        JSON.parse(JSON.stringify(eventData))
    );
}
