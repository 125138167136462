import type { BaseRequestOptions } from 'owa-analytics-types/lib/types/BaseRequestOptions';
import { registerCreateServiceResponseCallback as registerOwsCreateServiceResponseCallback } from 'owa-service/lib/fetchWithRetry';
import { registerCreateServiceResponseCallback as registerOwsPrimeCreateServiceResponseCallback } from 'owa-ows-gateway/lib/registerCreateServiceResponseCallback';
import { isRunningOnWorker } from 'owa-config';
import { trace } from 'owa-trace';
import captureServiceActionOptics from './captureServiceActionOptics';

export function registerOwsCallbacks() {
    trace.info(` Registering OWS Callbacks. Is a Web Worker? ${isRunningOnWorker()}`, 'analytics');
    registerOwsCreateServiceResponseCallback(captureServiceActionOptics);
    registerOwsPrimeCreateServiceResponseCallback(captureServiceActionOptics);
}

export function registerCustomOwsCallbacks(
    customCallback: (
        responsePromise: Promise<Response>,
        actionName: string,
        url: string,
        attemptCount: number,
        optionsPromise: Promise<BaseRequestOptions>
    ) => Promise<void>
) {
    trace.info(
        ` Registering Custom OWS Callbacks. Is a Web Worker? ${isRunningOnWorker()}`,
        'analytics'
    );
    registerOwsCreateServiceResponseCallback(customCallback);
    registerOwsPrimeCreateServiceResponseCallback(customCallback);
}
