import type { CalendarEventTableType } from 'owa-offline-events-schema';
import type { ClientCalendarEvent } from 'owa-graph-schema';

import { getHashedLogString } from './getHashedLogString';
import type { LogData } from './types/LogData';

export function getCalendarEventLogData(
    event: CalendarEventTableType | ClientCalendarEvent
): LogData {
    // We try to send properties which are likely to help investigate bugs and are relative small
    // We hash the subject here as a 1-way function. If the user provides the subject of an item,
    // we can search the logs for the hash of that subject.
    const isPartial = isCalendarEventTableType(event) ? event.MetaData.isPartialEvent : undefined;

    return {
        id: event.id,
        start: event.Start,
        end: event.End,
        allDay: event.IsAllDayEvent,
        isCancelled: event.IsCancelled,
        type: event.CalendarItemType,
        isMeeting: event.IsMeeting,
        isOnline: event.IsOnlineMeeting,
        isOrganizer: event.IsOrganizer,
        response: event.ResponseType,
        freeBusy: event.FreeBusyType,
        hasAttachments: event.HasAttachments,
        reminder: event.ReminderIsSet,
        master: event.SeriesMasterItemId?.Id,
        charmId: event.CharmId,
        convId: event.ConversationId?.Id,
        body: !!event.Body,
        instanceKey: event.InstanceKey,
        subject: getHashedLogString(event.Subject),
        location: getHashedLogString(event.Location),
        folder: getHashedLogString(event.ParentFolderId?.Id),
        isPartial,
    };
}

function isCalendarEventTableType(event: any): event is CalendarEventTableType {
    return 'MetaData' in event;
}
