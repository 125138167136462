import { type ApolloError } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import type {
    QueuedActionResult,
    AcceptedQueuedResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { type QueuedAction } from '../types/QueuedAction';
import { type TraceErrorObject } from 'owa-trace';
import { isTransientExceptionName } from '../util/isTransientExceptionName';
import { isOverBudgetResponse } from '../util/isOverBudgetResponse';
import { isTransientResponseCode } from '../util/isTransientResponseCode';

export const NO_ID_CHANGES: ReadonlyMap<string, string> = new Map();

// The default response processor accepts all responses except for those indicating a connectivity problem
// It doesn't identify any id remappings
export async function defaultResponseProcessor(
    _action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges: NO_ID_CHANGES,
        };
    }

    return Promise.resolve(rv);
}

export async function findRejectedResult(result: QueuedActionResult) {
    let rv: RejectedQueuedResult | undefined;

    // collapse all the errors into a single array
    const errors: Error[] = [];
    if (result.fetchError) {
        errors.push(result.fetchError);
    }

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    result.fetchResult?.errors?.forEach(e => errors.push(e));

    for (let i = 0; i < errors.length; i++) {
        const rejectError = errors[i];
        if (isNetworkUnavailableError(rejectError)) {
            rv = { rejectError, rejectCode: 'Offline' };
        } else if (isServiceDownError(rejectError)) {
            rv = { rejectError, rejectCode: 'ServiceDown' };
        } else if (isCanaryError(rejectError)) {
            rv = { rejectError, rejectCode: 'Canary' };
        } else if (isAuthError(rejectError)) {
            rv = { rejectError, rejectCode: 'Auth' };
        } else if (isServerTransientError(rejectError)) {
            rv = { rejectError, rejectCode: 'ServerTransient' };
        } else if (await isBudgetError(rejectError)) {
            rv = { rejectError, rejectCode: 'OverBudget' };
        } else if (isAbortError(rejectError)) {
            rv = { rejectError, rejectCode: 'Abort' };
        }

        if (rv) {
            break;
        }
    }

    return rv;
}

function isServiceDownError(error: Error) {
    return (
        isResponseCodeError(error, 503) ||
        isResponseCodeError(error, 504) ||
        isResponseCodeError(error, 404) // service.svc will return a 404 if the app pool is down
    );
}

function isCanaryError(error: Error) {
    return isResponseCodeError(error, 449);
}

function isAuthError(error: Error) {
    return isResponseCodeError(error, 440) || isResponseCodeError(error, 401);
}

function isNetworkUnavailableError(error: Error) {
    return isGatewayNetworkUnavailableError(error) || isOwsNetworkUnavailableError(error);
}

export function isResponseCodeError(
    error: Error,
    responseCode: number | string
): Error | undefined {
    const gqlError = error as GraphQLError;
    const apolloError = error as ApolloError;
    const originalError = getOriginalError(gqlError);

    let responseCodeError: Error | undefined;

    // OWS sets the response code on httpStatus while the gateway sets it on responseCode
    if (
        gqlError?.extensions?.responseCode === responseCode ||
        gqlError?.extensions?.httpStatus === responseCode
    ) {
        responseCodeError = gqlError;
    }

    if (
        !responseCodeError &&
        (originalError?.responseCode === responseCode || originalError?.httpStatus === responseCode)
    ) {
        responseCodeError = originalError;
    }

    if (!responseCodeError) {
        responseCodeError = apolloError?.graphQLErrors?.find(e =>
            isResponseCodeError(e, responseCode)
        );
    }

    return responseCodeError;
}

// gatewayLink.ts reports an ApolloError with this 'code' GraphqlNetworkError if it got a result indicating a network issue
// the Apollo HttpLink will report a networkError with message 'Failed to fetch'
function isGatewayNetworkUnavailableError(error: Error): Error | undefined {
    const resultError = error as Error;
    const gqlError = error as GraphQLError;
    const apolloError = error as ApolloError;

    let networkUnavailable: Error | undefined =
        gqlError?.extensions?.code === 'GraphqlNetworkError' ? gqlError : undefined;

    if (!networkUnavailable) {
        networkUnavailable = apolloError?.graphQLErrors?.find(isGatewayNetworkUnavailableError);
    }

    if (!networkUnavailable) {
        networkUnavailable = resultError?.message === 'Failed to fetch' ? resultError : undefined;
    }

    return networkUnavailable;
}

// resolvers will return a GraphQLError with
// extensions: fetchErrorType: 'RequestNotComplete';
// networkError: true;
// retriable: true;
function isOwsNetworkUnavailableError(error: Error): Error | undefined {
    const gqlError = error as GraphQLError;
    const apolloError = error as ApolloError;
    const originalError = getOriginalError(gqlError);

    let networkUnavailable: Error | undefined =
        gqlError?.extensions?.fetchErrorType === 'RequestNotComplete' ? gqlError : undefined;

    if (!networkUnavailable && originalError?.fetchErrorType === 'RequestNotComplete') {
        networkUnavailable = originalError;
    }

    if (!networkUnavailable) {
        networkUnavailable = apolloError?.graphQLErrors?.find(isOwsNetworkUnavailableError);
    }

    return networkUnavailable;
}

function isServerTransientError(error: Error): Error | undefined {
    let serverTransientError: Error | undefined = undefined;
    const resultError = error as TraceErrorObject;
    const originalError = getOriginalError(error as GraphQLError);
    const gqlError = (error as GraphQLError)?.extensions as TraceErrorObject;

    if (
        isTransientExceptionName(resultError?.xowaerror) ||
        isTransientExceptionName(resultError?.xinnerexception) ||
        isTransientExceptionName(gqlError?.xowaerror) ||
        isTransientExceptionName(gqlError?.xinnerexception)
    ) {
        serverTransientError = error;
    }

    if (
        !serverTransientError &&
        (isTransientExceptionName(originalError?.xowaerror) ||
            isTransientExceptionName(originalError?.xinnerexception))
    ) {
        serverTransientError = error;
    }

    if (
        !serverTransientError &&
        (isTransientResponseCode(resultError) || isTransientResponseCode(originalError))
    ) {
        serverTransientError = error;
    }

    return serverTransientError;
}

async function isBudgetError(error: TraceErrorObject): Promise<Error | undefined> {
    let budgetError: Error | undefined = undefined;

    const resultError = error as TraceErrorObject;
    const originalError = getOriginalError(error as GraphQLError);
    const gqlError = (error as GraphQLError)?.extensions as TraceErrorObject;

    if ((await isOverBudgetResponse(resultError)) || (await isOverBudgetResponse(gqlError))) {
        budgetError = error;
    }

    if (!budgetError && (await isOverBudgetResponse(originalError))) {
        budgetError = originalError;
    }

    return budgetError;
}

function isAbortError(error: Error): Error | undefined {
    //developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
    return isErrorNamed(error, 'AbortError');
}

function isErrorNamed(error: Error, name: string): Error | undefined {
    const gqlError = error as GraphQLError;
    const apolloError = error as ApolloError;
    const originalError = getOriginalError(gqlError);

    let namedError: Error | undefined;
    if (error?.name === name) {
        namedError = error;
    } else if (originalError?.name === name) {
        namedError = originalError;
    } else {
        namedError = apolloError?.graphQLErrors?.find(isAbortError);
    }

    return namedError;
}

function getOriginalError(gqlError: GraphQLError | undefined): TraceErrorObject | undefined {
    return gqlError?.originalError as TraceErrorObject | undefined;
}
