import { isVersionError } from './isVersionError';
import { isDatabaseClosedError } from './isDatabaseClosedError';
import { isQuotaExceededError } from './isQuotaExceededError';
import { isRemovedAccountError } from './removedAccountError';

// We never intentionally close the database. It closes underneath us if the version changes, it is deleted, etc.
// Since those are not things we recover from, DatabaseClosedError is permanent.
export function isPermanentDbError(e: Error): boolean {
    return (
        isVersionError(e) ||
        isDatabaseClosedError(e) ||
        isQuotaExceededError(e) ||
        isRemovedAccountError(e)
    );
}
