import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import type {
    InternalRequestOptions,
    RequestOptions,
    ShouldRetry,
} from 'owa-service/lib/RequestOptions';
import type { BasicHeader } from './BasicHeader';
import { fromBasicHeaders, toBasicHeaders } from './BasicHeader';
import { sanitize } from './sanitize';

type AllRequestOptions = RequestOptions & InternalRequestOptions & MailboxRequestOptions;

type BasicKeys = Required<
    Pick<
        AllRequestOptions,
        | 'method'
        | 'returnFullResponseOnSuccess'
        | 'isUserActivity'
        | 'noEmptyPost'
        | 'authNeededOnUnAuthorized'
        | 'customBaseUrl'
        | 'customBaseUrlSubPath'
        | 'timeoutMS'
        | 'endpoint'
        | 'retryCount'
        | 'returnResponseHeaders'
        | 'mailboxInfo'
        | 'credentials'
        | 'body'
        | 'retryAuthForExplicitLogon'
    >
>;

export type BasicRequestOptions = Partial<Omit<AllRequestOptions, 'headers'>> & {
    headers: BasicHeader[];
};

const basicKeys: {
    [T in keyof BasicKeys]: 1;
} = {
    method: 1,
    returnFullResponseOnSuccess: 1,
    isUserActivity: 1,
    noEmptyPost: 1,
    authNeededOnUnAuthorized: 1,
    customBaseUrl: 1,
    customBaseUrlSubPath: 1,
    timeoutMS: 1,
    endpoint: 1,
    retryCount: 1,
    returnResponseHeaders: 1,
    mailboxInfo: 1,
    credentials: 1,
    body: 1,
    retryAuthForExplicitLogon: 1,
};

export function toBasicOptions(complex: RequestOptions): BasicRequestOptions {
    // older browsers don't support full headers, but monarch will and we're gating the worker link on whether it is supported
    complex = complex || {};
    const headers = toBasicHeaders(complex.headers as Headers);
    const basic: BasicRequestOptions = { headers };
    Object.keys(complex).forEach(key => {
        if (basicKeys[key as keyof BasicKeys]) {
            (basic as any)[key as keyof BasicKeys] = sanitize<any>(
                complex[key as keyof RequestOptions]
            );
        }
    });

    return basic;
}

export function fromBasicOptions(
    basic: Partial<BasicRequestOptions>,
    maybeShouldRetry?: ShouldRetry
): RequestOptions {
    basic = basic || {};

    const headers = fromBasicHeaders(basic.headers);
    const complex: RequestOptions = {
        ...basic,
        headers,
    };

    if (maybeShouldRetry) {
        complex.shouldRetry = maybeShouldRetry;
    }

    return complex;
}
