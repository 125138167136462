import type { Change } from './database';
import { type ChangeType } from './database';

export function isMetaDataOnlyChange(change: Change<any, any>) {
    if (change.type != 1) {
        return false;
    }

    for (const propPath of Object.keys(change.mods)) {
        if (!propPath.startsWith('MetaData')) {
            return false;
        }
    }

    return true;
}
