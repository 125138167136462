import type { MailboxInfo } from 'owa-client-types';
import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import { getFoldersToShowFirst } from 'owa-folders-data-utils';
import { requestOptionsForSync } from 'owa-offline-request-utils/lib/requestOptionsForSync';
import handleServerResponseSuccessAndError from 'owa-service-utils/lib/handleServerResponseSuccessAndError';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import type FindFolderRequest from 'owa-service/lib/contract/FindFolderRequest';
import type FindFolderResponseMessage from 'owa-service/lib/contract/FindFolderResponseMessage';
import type PropertyUri from 'owa-service/lib/contract/PropertyUri';
import distinguishedFolderId from 'owa-service/lib/factory/distinguishedFolderId';
import findFolderRequest from 'owa-service/lib/factory/findFolderRequest';
import folderResponseShape from 'owa-service/lib/factory/folderResponseShape';
import indexedPageView from 'owa-service/lib/factory/indexedPageView';
import propertyUri from 'owa-service/lib/factory/propertyUri';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import findFolderOperation from 'owa-service/lib/operation/findFolderOperation';
import { getIsCustomSortEnabledOffline } from '../store/getIsCustomSortEnabledOffline';

function configureRequestBody(
    mailboxInfo: MailboxInfo,
    indexedPageOffset: number,
    customSort: boolean | undefined
): FindFolderRequest {
    const additionalProperties: PropertyUri[] = [
        propertyUri({ FieldURI: 'DistinguishedFolderId' }),
        propertyUri({ FieldURI: 'FolderClass' }),
        propertyUri({ FieldURI: 'FolderDisplayName' }),
        propertyUri({ FieldURI: 'ParentFolderId' }),
        propertyUri({ FieldURI: 'TotalCount' }),
        propertyUri({ FieldURI: 'UnreadCount' }),
    ];

    return findFolderRequest({
        FolderShape: folderResponseShape({
            BaseShape: 'IdOnly',
            AdditionalProperties: additionalProperties,
        }),
        ParentFolderIds: [distinguishedFolderId({ Id: PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID })],
        ShapeName: 'Folder',
        Paging: indexedPageView({
            BasePoint: 'Beginning',
            Offset: indexedPageOffset,
        }),
        ReturnParentFolder: true,
        Traversal: 'Deep',
        FoldersToMoveToTop: getFoldersToShowFirst(mailboxInfo),
        DoCustomSort: customSort,
    });
}

export default async function syncFolderHierarchy(
    mailboxInfo: MailboxInfo,
    indexedPageOffset: number
): Promise<FindFolderResponseMessage> {
    const customSort = await getIsCustomSortEnabledOffline(mailboxInfo);
    const requestBody = configureRequestBody(mailboxInfo, indexedPageOffset, customSort);

    const response = await findFolderOperation(
        {
            Header: getJsonRequestHeader(),
            Body: requestBody,
        },
        getMailboxRequestOptions(mailboxInfo, requestOptionsForSync)
    );
    const responseMessages = response.Body.ResponseMessages?.Items;
    await handleServerResponseSuccessAndError(responseMessages);
    return responseMessages?.[0] as FindFolderResponseMessage;
}
