import type {
    Handlers,
    WorkerConfigSyncBCName,
    WorkerConfigSyncEvent,
} from 'owa-worker-config-sync-types';
import type { TraceErrorObject } from 'owa-trace';
import type { ThreadName } from 'owa-thread-config';
import { trace } from 'owa-trace';

const bc = new BroadcastChannel('worker-config-sync' as WorkerConfigSyncBCName);

/**
 * We need to use a BroadcastChannel to send messages to the workers because Monarch windows
 * don't share the
 */

export async function sendConfigToWorkers(
    workers: 'all' | ThreadName[],
    handler: Handlers,
    value: any
) {
    try {
        trace.info(
            `[config-sync] Sending "${handler}" config to "${
                Array.isArray(workers) ? workers.join(', ') : workers
            }" worker(s)`,
            'analytics'
        );

        const message: WorkerConfigSyncEvent = {
            executeOn: workers,
            handler,
            value,
        };

        bc.postMessage(message);
    } catch (error) {
        const err: TraceErrorObject = error;
        err.additionalInfo = {
            executeOn: workers,
            handler,
            value,
        };
        throw err;
    }
}
