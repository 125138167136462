import type { MailFolder } from 'owa-graph-schema';
import type { AppDatabase } from 'owa-offline-database';

export function getFolderById(
    database: AppDatabase,
    folderId: string
): Promise<MailFolder | undefined> {
    return database.folders.get(folderId);
}

export function getFoldersById(
    database: AppDatabase,
    folderIds: string[]
): Promise<(MailFolder | undefined)[]> {
    return database.folders.bulkGet(folderIds);
}
