import type { UnsampleDataToggleAddon } from 'owa-analytics-types';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { getItem, setItem, removeItem } from 'owa-local-storage';
import { getThreadName } from 'owa-thread-config';
import { sendConfigToWorkers } from 'owa-worker-config-sync-send';
import { trace } from 'owa-trace';

interface UnsampledDataToggleData {
    isEnabled: boolean;
    endTimeInMs: number;
}

const localStorageKey = 'UnsampledDataToggle';
const oneDayInMs = 1000 * 60 * 60 * 24;

let timeout: NodeJS.Timeout | null = null;

/**
 * On workers allow to override the unsampled data logging setting
 */
let isEnabledOverride: boolean | undefined;

export function toggleUnsampledLoggingAddon(): UnsampleDataToggleAddon {
    return {
        isUnsampledLoggingEnabled,
        toggleUnsampledLogging,
        overrideUnsampledLogging,
        syncWorkerValue,
    };
}

function isUnsampledLoggingEnabled(): boolean {
    if (getThreadName() !== 'MAIN_THREAD') {
        return !!isEnabledOverride;
    }

    return !!getUnsampledDataToggleData()?.isEnabled;
}

function toggleUnsampledLogging(enable: boolean): boolean {
    if (enable) {
        enableUnsampledLogging();
    } else {
        disableUnsampledLogging();
    }

    return isUnsampledLoggingEnabled();
}

function overrideUnsampledLogging(enable: boolean) {
    trace.info(
        `[config-sync] Overriding the unsampled logging value. Value: ${enable}`,
        'analytics'
    );
    isEnabledOverride = enable;
}

function init() {
    const data = getUnsampledDataToggleData();
    if (!timeout && data && data.endTimeInMs > Date.now()) {
        timeout = setTimeout(disableUnsampledLogging, data.endTimeInMs - Date.now());
    }
}

function getUnsampledDataToggleData(): UnsampledDataToggleData | undefined {
    try {
        const data = getItem(self, localStorageKey);
        if (data) {
            return JSON.parse(data);
        }
    } catch {
        // no-op in workers because localStorage is not available
    }

    return undefined;
}

function disableUnsampledLogging() {
    try {
        removeItem(self, localStorageKey);
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        sendConfigToWorkers('all', 'unsampledLogging', false);
    } catch {
        // no-op in workers because localStorage is not available
    }
}

function enableUnsampledLogging() {
    try {
        const endTime = Date.now() + oneDayInMs;
        setItem(self, localStorageKey, JSON.stringify({ isEnabled: true, endTimeInMs: endTime }));
        timeout = setTimeout(disableUnsampledLogging, oneDayInMs);
        sendConfigToWorkers('all', 'unsampledLogging', true);
    } catch {
        // no-op in workers because localStorage is not available
    }
}

function syncWorkerValue() {
    sendConfigToWorkers('all', 'unsampledLogging', isUnsampledLoggingEnabled());
}

// Initialize on load
if (getThreadName() === 'MAIN_THREAD') {
    init();
}
