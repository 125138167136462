import type { TraceErrorObject, AdditionalDiagnostics } from 'owa-trace';

export default function createServiceFetchError(
    responseCode: string | undefined,
    stackTrace: string | undefined,
    messageText: string | undefined,
    additionalInfo?: AdditionalDiagnostics
): Error {
    let errorMessage = 'ResponseCode=' + responseCode;
    if (stackTrace) {
        errorMessage += ', Stacktrace=' + stackTrace;
    }
    /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
     * Error constructor names can only be a string literals.
     *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
    const error: TraceErrorObject = new Error(errorMessage);
    error.responseCode = responseCode;
    error.fetchErrorType = 'ServerFailure';
    if (messageText) {
        error.additionalInfo = {
            ...additionalInfo,
            diagnosticMessage: 'MessageText= ' + messageText,
        };
    }

    return error;
}
