import type { Table } from 'dexie';
import type { DexieKey } from 'owa-database-schema';

export const SYNC_STATE_TABLE_NAME = 'syncState';

export interface SyncModuleState<TSyncState> {
    name: string;
    syncState: TSyncState;
    lastSuccessfulSyncTime: number;
}

export type SyncStateTable = Table<SyncModuleState<any>, string>;

const SYNC_STATE_NAME_KEY: DexieKey<SyncModuleState<any>> = 'name';

export const syncStateSchema = {
    [SYNC_STATE_TABLE_NAME]: `${SYNC_STATE_NAME_KEY}`,
};
