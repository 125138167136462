import type { Table } from 'dexie';
import type { TableHooks } from './TableHooks';

/**
 * Register hooks for the given table. If multiple hooks are subscribed for the same table, they are applied
 * in the order they subscribed and compose in the obvious way. So for example, the second updating hook will
 * see any additional modifications made by the first updating hook.
 */
export function registerTableHooks<TObj, TKey>(
    table: Table<TObj, TKey> | undefined,
    hooks: TableHooks<TObj, TKey>
) {
    if (hooks.creatingHook) {
        table?.hook('creating').subscribe(hooks.creatingHook);
    }

    if (hooks.readingHook) {
        table?.hook('reading').subscribe(hooks.readingHook);
    }

    if (hooks.updatingHook) {
        table?.hook('updating').subscribe(hooks.updatingHook);
    }

    if (hooks.deletingHook) {
        table?.hook('deleting').subscribe(hooks.deletingHook);
    }
}
