import type { TraceLevel } from 'owa-trace';
import { isTracingEnabled } from '../../utils/isTracingEnabled';
import { logDatapoint } from '../logDatapoint';
import TraceDatapoint from '../../datapoints/TraceDatapoint';

export function logTraceDatapoint(message: string, level: TraceLevel) {
    if (isTracingEnabled(level)) {
        logDatapoint(new TraceDatapoint(message, level), 'client_trace');
    }
}
