import type { DataWorkerClient } from 'owa-data-worker-utils';
import { expose } from 'comlink';
import { initialize, precompileSchema, getOfflineSessionData } from 'owa-data-worker';
import { execute } from './actions/execute';
import { initializeOfflineSync } from './actions/initializeOfflineSync';
import { unsubscribe } from './actions/unsubscribe';
import { setDisableAllRequests } from './actions/setDisableAllRequests';
import { notifySubscription } from './actions/notifySubscription';
import { flushSyncLogs } from './actions/flushSyncLogs';
import { initializeDateTime } from './actions/initializeDateTime';
import { initializeApplicationSettings } from './actions/initializeApplicationSettings';
import { initializeFeatureFlags } from './actions/initializeFeatureFlags';
import {
    initializeAnalyticsInWorker,
    updateAnalyticsCachesInWorker,
} from 'owa-worker-analytics-initialization';
import { onTableReloaded } from './actions/onTableReloaded';
import { invokeWin32GqlRequestCallback } from './actions/invokeWin32GqlRequestCallback';
import { saveOfflineSessionData } from './actions/saveOfflineSessionData';
import { getOfflineTimeZones, getOfflineTimeZoneOffsets } from './actions/getOfflineTimeZones';
import { applyCalendarEventsSyncLieForRange } from './actions/applyCalendarEventsSyncLieForRange';
import { loadOfflineSettings } from './actions/loadOfflineSettings';
import { saveOfflineSettings } from './actions/saveOfflineSettings';
import { invalidateOfflineConversationNodes } from './actions/invalidateOfflineConversationNodes';

const client: DataWorkerClient = {
    initialize,
    initializeOfflineSync,
    execute,
    unsubscribe,
    setDisableAllRequests,
    notifySubscription,
    flushSyncLogs,
    initializeDateTime,
    initializeAnalyticsInWorker,
    updateAnalyticsCachesInWorker,
    onTableReloaded,
    initializeApplicationSettings,
    initializeFeatureFlags,
    precompileSchema,
    invokeWin32GqlRequestCallback,
    getOfflineSessionData,
    saveOfflineSessionData,
    getOfflineTimeZones,
    getOfflineTimeZoneOffsets,
    applyCalendarEventsSyncLieForRange,
    loadOfflineSettings,
    saveOfflineSettings,
    invalidateOfflineConversationNodes,
};

expose(client);
