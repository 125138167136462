import type { Dexie, Version } from 'dexie';

const nullVersion: Version = {
    stores: () => nullVersion,
    upgrade: () => nullVersion,
};

/**
 * Filter versions of a database to only include versions up to a target version.
 * Used to enable testability of version upgrade logic.
 */
export class DatabaseVersionManager {
    db: Dexie;
    targetVersion: number;
    onUpgradeCallback: () => void;

    constructor(db: Dexie, targetVersion: number = Infinity, onUpgradeCallback: () => void) {
        this.db = db;
        this.targetVersion = targetVersion;
        this.onUpgradeCallback = onUpgradeCallback;
    }

    version: (ver: number) => Version = ver =>
        ver > this.targetVersion
            ? nullVersion
            : this.db.version(ver).upgrade(this.onUpgradeCallback);
}
