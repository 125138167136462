/*
Classic Web Workers use `importScripts` to load chunks. Unfortunately, due to internal
browser implementation, `importScripts` is synchronous and will block the worker thread.
This patch will prefetch the chunk script before calling `importScripts` to have better cache
hit rate and avoid blocking the worker thread for network load time.

We can remove this when we switch to using ES modules in the worker.
*/
declare var __webpack_get_script_filename__: (chunkId: number) => string;
export function patchChunkLoad() {
    if (process.env.IS_WEBPACK) {
        const webpackChunkLoad = __webpack_chunk_load__;
        const fetchedChunks = new Set();
        __webpack_chunk_load__ = async function (chunkId, callback) {
            if (!fetchedChunks.has(chunkId)) {
                const scriptName =
                    __webpack_public_path__ + __webpack_get_script_filename__(chunkId);
                // importScripts loads in no-cors mode
                await fetch(scriptName, {
                    mode: 'no-cors',
                }).catch(() => {});
                fetchedChunks.add(chunkId);
            }
            return webpackChunkLoad(chunkId, callback);
        };
    }
}
