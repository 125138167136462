import type { DexieKey } from 'owa-database-schema';

import type { Table } from 'dexie';
import type ConversationNodesTableType from './ConversationNodesTableType';

export const CONVERSATION_NODES_TABLE_NAME = 'conversationNodes';
export type ConversationNodesTable = Table<ConversationNodesTableType, string>;

export const CONVERSATION_ID_KEY: DexieKey<ConversationNodesTableType> = 'id';
export const MESSAGES_TO_SYNC_KEY: DexieKey<ConversationNodesTableType> =
    'MetaData.hasMessagesToSync';
export const ITEM_IDS_KEY: DexieKey<ConversationNodesTableType> = 'MetaData.itemIds';

export const conversationNodesSchema = {
    conversationNodes: `${CONVERSATION_ID_KEY},${MESSAGES_TO_SYNC_KEY},*${ITEM_IDS_KEY}`,
};
