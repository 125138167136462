import { SyncModule } from 'owa-offline-sync-engine';
import syncFolderHierarchy from './services/syncFolderHierarchy';
import type FindFolderResponseMessage from 'owa-service/lib/contract/FindFolderResponseMessage';
import type { MailboxInfo } from 'owa-client-types';
import type { FoldersSyncState } from './FoldersSyncState';
import { FOLDERS_SYNC_STATE_NAME } from './FoldersSyncState';
import { saveOfflineFolderHierarchy } from './store/saveOfflineFolderHierarchy';
import { mapFindFolderResponseToGql } from 'owa-folder-gql-mappers';
import { removeDeletedFoldersFromDb } from './store/removeDeletedFoldersFromDb';

// Sync module responsible for syncing the folder hierarchy
class SyncFoldersModule extends SyncModule<FoldersSyncState, FindFolderResponseMessage> {
    public type = 'FolderHierarchy';
    public syncStateName = FOLDERS_SYNC_STATE_NAME;

    private offset = 0;
    private initialFolderIds: string[] = [];
    private incomingFolderIds: string[] = [];

    public async initialize() {
        await super.initialize();
        // store the existing folder ids so we can run a comparison after sync is finished
        const currentIds = await this.database.folders.toCollection().primaryKeys();
        this.initialFolderIds.push(...currentIds);
    }

    protected getTables = () => [this.database.folders];

    protected getEmptySyncState = (): FoldersSyncState => {
        return { includesLastFolderInRange: false, customSorted: false };
    };

    protected syncChangesFromServer = (_syncState: FoldersSyncState) =>
        syncFolderHierarchy(this.mailboxInfo, this.offset);

    protected getSyncStateFromResponse = (
        _previousSyncState: FoldersSyncState,
        response: FindFolderResponseMessage
    ) => {
        return {
            includesLastFolderInRange: response.RootFolder?.IncludesLastItemInRange ?? false,
            customSorted: response.RootFolder?.CustomSorted ?? false,
        };
    };

    protected saveChangesToStore = (response: FindFolderResponseMessage) => {
        const folderHierarchy = mapFindFolderResponseToGql(
            response,
            this.mailboxInfo,
            true,
            true,
            true
        );
        const incomingIds = folderHierarchy.Folders?.filter(folder => !!folder).map(
            folder => folder?.id ?? ''
        );
        if (incomingIds) {
            this.incomingFolderIds.push(...incomingIds);
            // If the root folder id is not ye tin the incoming ids list, add it
            const rootFolderId = folderHierarchy.RootFolder?.id;
            if (rootFolderId && this.incomingFolderIds.indexOf(rootFolderId) == -1) {
                this.incomingFolderIds.push(rootFolderId);
            }
        }
        // The sort index is the offset * 10, so the first item in the response should be added above the previous offset * 10
        const sortIndex = this.offset * 10;
        // if the response includes the last item in range, reset the offset to 0 so it's ready for the next sync, otherwise store the offset from this response to use in the next one
        this.offset = folderHierarchy.IncludesLastItemInRange ? 0 : folderHierarchy.offset ?? 0;
        return saveOfflineFolderHierarchy(this.mailboxInfo, folderHierarchy, sortIndex);
    };

    public isSyncComplete = () => {
        const isComplete = this.lastSyncResponse?.RootFolder?.IncludesLastItemInRange == true;
        // When sync is complete, need to delete any folders that are no longer in the database
        if (isComplete && this.initialFolderIds.length > 0 && this.incomingFolderIds.length > 0) {
            this.emitSyncEvent('saving hierarchy', {
                oldCount: this.initialFolderIds.length,
                newCount: this.incomingFolderIds.length,
            });
            removeDeletedFoldersFromDb(
                this.database,
                this.initialFolderIds,
                this.incomingFolderIds
            );
        }
        return isComplete;
    };
}

export function getSyncFoldersModule(mailboxInfo: MailboxInfo) {
    return new SyncFoldersModule(mailboxInfo);
}
