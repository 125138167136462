let diagnosticPanelLoggingEnabled = false;

export function setDiagnosticPanelLoggingEnabled(enable: boolean): Promise<void> {
    diagnosticPanelLoggingEnabled = enable;
    return Promise.resolve();
}

export function getDiagnosticPanelLoggingEnabled() {
    return diagnosticPanelLoggingEnabled;
}
