export function flattenObject(obj: Record<string, unknown>, prefix = '') {
    return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];
        const pre = prefix ? prefix + '.' : '';
        if (value && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            Object.assign(acc, flattenObject(obj[key] as Record<string, unknown>, pre + key));
        } else if (value !== null) {
            acc[pre + key] = obj[key];
        }
        return acc;
    }, {} as Record<string, unknown>);
}
