import type { Table } from 'dexie';
import type { FolderTableType } from './FolderTableType';
import type { DexieKey } from 'owa-database-schema';

export const FOLDERS_TABLE_NAME = 'folders';
export type FoldersTable = Table<FolderTableType, string>;

export const FOLDERS_ID_KEY: DexieKey<FolderTableType> = 'id';
export const FOLDERS_DISTINGUISHED_ID_KEY: DexieKey<FolderTableType> = 'distinguishedFolderType';
export const FOLDERS_SORT_INDEX_KEY: DexieKey<FolderTableType> = 'MetaData.sortIndex';

export const foldersSchema = {
    folders: `${FOLDERS_ID_KEY},${FOLDERS_DISTINGUISHED_ID_KEY},${FOLDERS_SORT_INDEX_KEY}`,
};
