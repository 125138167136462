const FOLDER_TYPES_WITHOUT_RENEW = [
    'sentitems',
    'deleteditems',
    'drafts',
    'junkemail',
    'archive',
    'outbox',
];

export function shouldFolderSortByRenewTime(distinguishedFolderType: string | null | undefined) {
    // If distinguishedFolderType is null or undefined, we assume it is a user created folder
    if (!distinguishedFolderType) {
        return true;
    }

    return FOLDER_TYPES_WITHOUT_RENEW.indexOf(distinguishedFolderType) === -1;
}
