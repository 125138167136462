// Type T but with optional, null, and undefined removed
type StripNullable<T> = {
    [P in keyof T]-?: NonNullable<T[P]>;
};

type JoinDot<K, P> = K extends string
    ? P extends string
        ? `${K}${'' extends P ? '' : '.'}${P}`
        : never
    : never;

type PathTree<T> = {
    [P in keyof T]-?: T[P] extends object ? P | JoinDot<P, Path<T[P]>> : P;
};

// String union type of all the paths - either shallow or deep - of keys in type T
type Path<T> = PathTree<StripNullable<T>>[keyof PathTree<StripNullable<T>>];

// String union of the set of possible (non-compound) keys for a Dexie table with the given RowType
export type DexieKey<RowType> = Path<RowType>;

// Construct a composite key from the given array of typed keys
export function compositeDexieKey<RowType>(keys: DexieKey<RowType>[]) {
    return `[${keys.join('+')}]`;
}
