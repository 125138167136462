import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "DataWorker" */ './lazyIndex'));

// Initialize and precompile should be the only synchronously exported method so as not to
// bloat the initial worker load
export { initialize } from './actions/initialize';
export { precompileSchema } from './actions/precompileSchema';
export { getOfflineSessionData } from './actions/getOfflineSessionData';

export const lazyExecute = new LazyAction(lazyModule, m => m.execute);
export const lazyUnsubscribe = new LazyAction(lazyModule, m => m.unsubscribe);
export const lazyNotifySubscription = new LazyAction(lazyModule, m => m.notifySubscription);
export const lazyinitializeOfflineSync = new LazyAction(lazyModule, m => m.initializeOfflineSync);
export const lazyFlushSyncLogs = new LazyAction(lazyModule, m => m.flushSyncLogs);
export const lazyInitializeApplicationSettings = new LazyAction(
    lazyModule,
    m => m.initializeApplicationSettings
);
export const lazyInitializeDateTime = new LazyAction(lazyModule, m => m.initializeDateTime);
export const lazyInitializeFeatureFlags = new LazyAction(lazyModule, m => m.initializeFeatureFlags);
export const lazyInvokeWin32GqlRequestCallback = new LazyAction(
    lazyModule,
    m => m.invokeWin32GqlRequestCallback
);

export const lazyGetOfflineTimeZoneOffsets = new LazyAction(
    lazyModule,
    m => m.getOfflineTimeZoneOffsets
);

export const lazyGetOfflineTimeZones = new LazyAction(lazyModule, m => m.getOfflineTimeZones);
export { getWorkerContextClient } from './util/getWorkerContextClient';
