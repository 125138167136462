import setStoreData from 'owa-account-source-list-store/lib/actions/setStoreData';
import getStoreData from 'owa-account-source-list-store/lib/selectors/getStoreData';
import type { AccountSourceListData } from 'owa-account-source-list-store';
import { trace } from 'owa-trace';

import checkForAccountChanges from './checkForAccountChanges';

/**
 * Syncs the account source list store data onto the worker thread
 * @param storeData The account source list store
 */
export function syncAccountSourceListStore(storeData: AccountSourceListData): Promise<void> {
    trace.info(
        `[account-sync] Syncing accounts to worker thread. ${storeData.sources.length} account source(s) in data`
    );

    const before = getStoreData().sources;
    setStoreData(storeData);
    const after = getStoreData().sources;

    checkForAccountChanges(before, after);
    return Promise.resolve();
}
