import { getAccountScopeUserSettings } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export function getSessionRoutingHint(mailboxInfo: MailboxInfo) {
    const sessionSetting = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    let sessionRoutingHint = '';
    if (sessionSetting) {
        sessionRoutingHint = `OID:${sessionSetting.ExternalDirectoryUserGuid}@${sessionSetting.ExternalDirectoryTenantGuid}`;
    } else {
        logUsage('SignalR sessionRoutingHint not set');
    }
    return sessionRoutingHint;
}
