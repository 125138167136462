import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OpeningAttachmentsCleanup"*/ './lazyIndex')
);

// Delay loaded Imports
export const lazyPurgeAttachmentsFromDatabase = new LazyAction(
    lazyModule,
    m => m.purgeAttachmentsFromDatabase
);
