import type { Table } from 'dexie';
import type { ConversationTableType } from './ConversationTableType';
import { compositeDexieKey } from 'owa-database-schema';

export const CONVERSATIONS_TABLE_NAME = 'conversations';
export type ConversationsTable = Table<ConversationTableType, [string, string]>;

export const CONVERSATIONS_ID_KEY = 'id';

export const CONVERSATIONS_FOLDER_ID_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'id',
]);

export const CONVERSATIONS_DATE_SORT_KEY = 'MetaData.sortTime';

export const CONVERSATIONS_PARENT_FOLDER_DATE_SORT_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'MetaData.sortTime',
]);

export const CONVERSATIONS_DATE_SORT_UNREAD_FILTER_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'MetaData.isUnread',
    'MetaData.sortTime',
]);

export const CONVERSATIONS_DATE_SORT_FLAGGED_FILTER_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'MetaData.isFlagged',
    'MetaData.sortTime',
]);

export const CONVERSATIONS_DATE_SORT_TO_ME_FILTER_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'MetaData.toOrCCMe',
    'MetaData.sortTime',
]);

export const CONVERSATIONS_DATE_SORT_HAS_FILES_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'MetaData.hasFiles',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_DATE_SORT_MENTIONED_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'MetaData.mentioned',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'MetaData.hasCalendarInvite',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'InferenceClassification',
    'MetaData.sortTime',
]);

export const CONVERSATIONS_INBOX_DATE_SORT_UNREAD_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.isUnread',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_FLAGGED_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.isFlagged',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_TO_ME_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.toOrCCMe',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_HAS_FILES_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.hasFiles',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_MENTIONED_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.mentioned',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INBOX_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY =
    compositeDexieKey<ConversationTableType>([
        'ParentFolderId.Id',
        'InferenceClassification',
        'MetaData.hasCalendarInvite',
        'MetaData.sortTime',
    ]);

export const CONVERSATIONS_INSTANCE_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'InstanceKey',
]);

export const CONVERSATIONS_TO_SYNC_KEY = compositeDexieKey<ConversationTableType>([
    'MetaData.dirtyState',
    'ParentFolderId.Id',
]);

export const CONVERSATIONS_CREATION_LIE_KEY = compositeDexieKey<ConversationTableType>([
    'ParentFolderId.Id',
    'MetaData.creationSource',
]);

export const conversationsSchema = {
    conversations: `${CONVERSATIONS_FOLDER_ID_KEY},${CONVERSATIONS_FOLDER_ID_KEY},${CONVERSATIONS_DATE_SORT_KEY},${CONVERSATIONS_PARENT_FOLDER_DATE_SORT_KEY},${CONVERSATIONS_DATE_SORT_UNREAD_FILTER_KEY},${CONVERSATIONS_DATE_SORT_FLAGGED_FILTER_KEY},${CONVERSATIONS_DATE_SORT_TO_ME_FILTER_KEY},${CONVERSATIONS_DATE_SORT_HAS_FILES_FILTER_KEY},${CONVERSATIONS_DATE_SORT_MENTIONED_FILTER_KEY},${CONVERSATIONS_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_KEY},${CONVERSATIONS_INBOX_DATE_SORT_UNREAD_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_FLAGGED_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_TO_ME_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_HAS_FILES_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_MENTIONED_FILTER_KEY},${CONVERSATIONS_INBOX_DATE_SORT_HAS_CALENDAR_INVITE_FILTER_KEY},${CONVERSATIONS_TO_SYNC_KEY},${CONVERSATIONS_ID_KEY},${CONVERSATIONS_INSTANCE_KEY},${CONVERSATIONS_CREATION_LIE_KEY}`,
};
