import type EventEmitter from 'owa-event-emitter';
import { addPstFileError } from '../data/addPstFileError';
import type { PstFileError } from '../data/PstFileLoggingData';
import { PstFileLoggingEventType } from './PstFileLoggingEventType';

export function registerEventListener(emitter: EventEmitter) {
    for (const eventName of Object.keys(PstFileLoggingEventType)) {
        emitter.on(eventName, (data: PstFileError) => {
            addPstFileError(data);
        });
    }
}
