// It's hard to avoid races where resolvers, logging, sync, etc.
// try to access the database after an account is removed.
// We use this error to help us treat these cases as expected.
export function removedAccountError() {
    return new Error('Cannot access database for removed account');
}

export function isRemovedAccountError(error: Error) {
    const result = error.message === 'Cannot access database for removed account';
    return result;
}
