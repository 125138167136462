import type { Transaction } from 'dexie';
import { getNewObjFromMods, type Modifications, type TableHooks } from 'owa-database-hooks';
import type { MessageBodiesTableType } from './messageBodiesSchema';
import { type FlagStatus, type Importance } from './messageBodiesSchema';
import { getTokensFromString } from 'owa-search-tokenizer';
import { parseHtml } from 'owa-search-tokenizer/lib/parseBodyHtml';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logGreyError } from 'owa-analytics';

export function onMessageBodyCreating(
    _id: string,
    message: MessageBodiesTableType,
    _trans: Transaction
) {
    if (isFeatureEnabled('sea-backgroundOfflineSearchIndexing-v2')) {
        const subjectKeywords = getSubjectKeywords(message);
        const bodyKeywords = getBodyKeywords(message);
        let keywords: string[] | undefined = undefined;

        if (subjectKeywords && bodyKeywords) {
            keywords = subjectKeywords.concat(bodyKeywords);
        } else if (subjectKeywords) {
            keywords = subjectKeywords;
        } else if (bodyKeywords) {
            keywords = bodyKeywords;
        }

        message.MetaData = {
            ...message.MetaData,
            subjectKeywords,
            keywords,
            // sortTime is set in saveMessageBodies
            dateTimeSent: message.DateTimeSent ? new Date(message.DateTimeSent) : undefined,
            from: getFromMailboxes(message),
            to: getToMailboxes(message),
            hasAttachments: message.HasAttachments ? 1 : 0,
            isRead: getIsReadValue(message),
            flagStatus: getFlagStatus(message),
            mentionedMe: message.MentionedMe ? 1 : 0,
            importance: getImportance(message),
            cc: getCcMailboxes(message),
            parentFolderId: message.ParentFolderId?.Id,
        };
    }
}

export function onMessageBodyUpdating(
    mods: Modifications,
    _id: string,
    oldMessageBody: MessageBodiesTableType,
    _trans: Transaction
) {
    const updates: Modifications = {};
    if (isFeatureEnabled('sea-backgroundOfflineSearchIndexing-v2')) {
        const messageBody = getNewObjFromMods(oldMessageBody, mods);
        let keywords: string[] | undefined = undefined;

        if (mods.hasOwnProperty('Subject')) {
            const subjectKeywords = getSubjectKeywords(messageBody);
            if (subjectKeywords) {
                keywords = subjectKeywords;
            }
            if (!areArrayEqual(messageBody.MetaData?.subjectKeywords, subjectKeywords)) {
                updates['MetaData.subjectKeywords'] = subjectKeywords;
            }
        }

        if (
            mods.hasOwnProperty('NormalizedBody') ||
            mods.hasOwnProperty('UniqueBody') ||
            mods.hasOwnProperty('NormalizedBody.Value') ||
            mods.hasOwnProperty('UniqueBody.Value')
        ) {
            const bodyKeywords = getBodyKeywords(messageBody);
            if (bodyKeywords) {
                keywords = keywords ? keywords.concat(bodyKeywords) : bodyKeywords;
            }
            if (!areArrayEqual(messageBody.MetaData?.keywords, keywords)) {
                updates['MetaData.keywords'] = keywords;
            }
        }

        const hasAttachments = messageBody.HasAttachments ? 1 : 0;
        if (messageBody.MetaData?.hasAttachments !== hasAttachments) {
            updates['MetaData.hasAttachments'] = hasAttachments;
        }

        const isRead = getIsReadValue(messageBody);
        if (messageBody.MetaData?.isRead !== isRead) {
            updates['MetaData.isRead'] = isRead;
        }

        const importance = getImportance(messageBody);
        if (messageBody.MetaData?.importance !== importance) {
            updates['MetaData.importance'] = importance;
        }

        const mentionedMe = messageBody.MentionedMe ? 1 : 0;
        if (messageBody.MetaData?.mentionedMe !== mentionedMe) {
            updates['MetaData.mentionedMe'] = mentionedMe;
        }

        const from = getFromMailboxes(messageBody);
        if (!areArrayEqual(oldMessageBody.MetaData?.from, from)) {
            updates['MetaData.from'] = from;
        }

        const to = getToMailboxes(messageBody);
        if (!areArrayEqual(oldMessageBody.MetaData?.to, to)) {
            updates['MetaData.to'] = to;
        }

        const cc = getCcMailboxes(messageBody);
        if (!areArrayEqual(oldMessageBody.MetaData?.cc, cc)) {
            updates['MetaData.cc'] = cc;
        }

        const parentFolderId = messageBody.ParentFolderId?.Id;
        if (oldMessageBody.MetaData?.parentFolderId !== parentFolderId) {
            updates['MetaData.parentFolderId'] = parentFolderId;
        }

        const dateTimeSent = messageBody.DateTimeSent
            ? new Date(messageBody.DateTimeSent)
            : undefined;
        if (oldMessageBody.MetaData?.dateTimeSent?.getTime() !== dateTimeSent?.getTime()) {
            updates['MetaData.dateTimeSent'] = dateTimeSent;
        }

        const flagStatus = getFlagStatus(messageBody);
        if (messageBody.MetaData?.flagStatus !== flagStatus) {
            updates['MetaData.flagStatus'] = flagStatus;
        }
    }

    return updates;
}

export const messageBodiesHooks: TableHooks<MessageBodiesTableType, string> = {
    creatingHook: onMessageBodyCreating,
    updatingHook: onMessageBodyUpdating,
};

function getSubjectKeywords(message: MessageBodiesTableType): string[] | undefined {
    return message.Subject ? getTokensFromString(message.Subject) : undefined;
}

function getBodyKeywords(message: MessageBodiesTableType): string[] | undefined {
    const body = message?.NormalizedBody?.Value ?? message?.UniqueBody?.Value;
    if (!body) {
        return;
    }
    try {
        const parsedBody = parseHtml(body);
        return getTokensFromString(parsedBody);
    } catch (e) {
        logGreyError('parseHtml failed', e);
        return;
    }
}

function getFromMailboxes(message: MessageBodiesTableType): string[] | undefined {
    if (!('From' in message)) {
        return;
    }
    const from = [];
    if (message.From?.Mailbox?.EmailAddress) {
        from.push(message.From.Mailbox.EmailAddress.toLocaleLowerCase());
    }
    if (message.From?.Mailbox?.Name) {
        from.push(message.From.Mailbox.Name.toLocaleLowerCase());
    }
    return from;
}

function getToMailboxes(message: MessageBodiesTableType): string[] | undefined {
    if (!('ToRecipients' in message)) {
        return;
    }
    const to: string[] = [];
    message.ToRecipients?.forEach(toRecipient => {
        if (toRecipient?.EmailAddress) {
            to.push(toRecipient.EmailAddress);
        }
        if (toRecipient?.Name) {
            to.push(toRecipient.Name);
        }
    });
    return to;
}

function getCcMailboxes(message: MessageBodiesTableType): string[] | undefined {
    if (!('CcRecipients' in message) || !message.CcRecipients) {
        return;
    }
    const cc: string[] = [];
    message.CcRecipients.forEach(ccRecipient => {
        if (ccRecipient?.EmailAddress) {
            cc.push(ccRecipient.EmailAddress);
        }
        if (ccRecipient?.Name) {
            cc.push(ccRecipient.Name);
        }
    });
    return cc;
}

function getIsReadValue(message: MessageBodiesTableType): number | undefined {
    if ('IsRead' in message) {
        return message.IsRead ? 1 : 0;
    }
    return;
}

function getFlagStatus(message: MessageBodiesTableType): FlagStatus | undefined {
    if (message.Flag?.FlagStatus) {
        switch (message.Flag.FlagStatus) {
            case 'Complete':
                return 2;
            case 'Flagged':
                return 1;
            case 'NotFlagged':
                return 0;
        }
    }
    return 0;
}

function getImportance(message: MessageBodiesTableType): Importance {
    if (message.Importance) {
        switch (message.Importance) {
            case 'Low':
                return 0;
            case 'Normal':
                return 1;
            case 'High':
                return 2;
        }
    }
    return 1;
}

function areArrayEqual(array1: string[] | undefined, array2: string[] | undefined): boolean {
    if (array1 && array2) {
        return (
            array1.length === array2.length &&
            array1.every((value, index) => value === array2[index])
        );
    }
    return array1 === array2;
}
