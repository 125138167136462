import { getSessionRoutingHint } from './SessionHelpers';
import { ConnectionType } from '../../schema/ConnectionType';
import { getAnchorMailboxMailboxInfo } from 'owa-anchormailbox';
import type { MailboxInfo } from 'owa-client-types';

export function getRoutingHeaders(connectionType: ConnectionType, mailboxInfo: MailboxInfo) {
    return {
        'X-AnchorMailbox': getAnchorMailboxMailboxInfo(mailboxInfo),
        'X-RoutingParameter-SessionKey':
            connectionType == ConnectionType.ONOSSignalR ? getSessionRoutingHint(mailboxInfo) : '',
    };
}
