import { owaDate, getTimestamp, userDate, getISOString } from 'owa-datetime';

// September 1st 4500 (Month starts from 0)
const utcPinnedDate = owaDate('UTC', 4500, 8, 1);

// For sending out the service request, we must use the UTC time when marking the item as pinned.
// Drop the 'Z' at the end of the timestamp
// This time is used when sending request to the server to mark items as pinned and we have to send the
// time in EWS format
export const utcPinnedEwsTimeStamp = getISOString(utcPinnedDate).slice(0, -1);

export function getAdjustedRenewTime(
    renewTimestamp?: string | null,
    secondaryTimestamp?: string | null
) {
    // Since renewTimestamp of a pinned items are all the same we have to create a key that is a combination of renewTime + secondaryTimestamp
    if (renewTimestamp && getIsPinnedTimestamp(renewTimestamp)) {
        return renewTimestamp + '+' + secondaryTimestamp;
    }

    return renewTimestamp;
}

/**
 * Get if a time stamp is a pinned time stamp
 * @param renewTimestamp
 * @returns a flag indicating whether the given timestamp is a pinned timestamp
 */
export function getIsPinnedTimestamp(renewTimestamp: string): boolean {
    // This is a workaround for the issue where the renewTimeStamp is not synced yet when the item is pinned
    if (renewTimestamp === getLocalPinnedTimeStamp()) {
        return true;
    }

    const renewDate = new Date(renewTimestamp);
    return renewDate.getTime() == getTimestamp(utcPinnedDate);
}

// For updating client upon pinning,
// we need to convert the utc pinned time to local time to compare,
// as server always returns the local time.
export function getLocalPinnedTimeStamp(): string | null {
    try {
        // If browser does not have the timezone set, toLocaleString will throw error
        // in which case we shall not do instant local update and have it update through notification
        new Date(getTimestamp(utcPinnedDate)).toLocaleString();

        return userDate(utcPinnedDate).toString();
    } catch (error) {
        return null;
    }
}
